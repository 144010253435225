<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { ProjectService } from "../../services/ProjectService";

const Props = Vue.extend({
    props: {
        projects: {
            type: Array,
            default: () => [],
        },
    },
});

@Component
export default class ProjectsSelection extends Props {
    @inject(ProjectService) projectService;

    selectedProjects = [];

    async onSelectProject(name) {
        const selectedProjects = (await this.projectService.searchProjects(name)) || [];
        const projects = this.getSelectedProjects();
        this.selectedProjects = selectedProjects.filter(
            (p) => !projects.find((o) => o.id === p.id)
        );
    }

    getSelectedProjects() {
        return this.$refs.projects.tags;
    }

    getSelectedProjectIds() {
        return this.getSelectedProjects().map((p) => p.id);
    }

    emitInput() {
        this.$emit("input", this.getSelectedProjectIds());
    }

    render() {
        return (
            <b-taginput
                rounded
                ref="projects"
                ellipsis
                icon="folder"
                autocomplete
                allow-new={false}
                placeholder={this.$t("ProjectsSelection.placeholder")}
                data={this.selectedProjects}
                ontyping={this.onSelectProject}
                oninput={() => this.emitInput()}
                field="name"
                value={this.projects}
            >
                <template slot="empty">{this.$t("ProjectsSelection.empty")}</template>
            </b-taginput>
        );
    }
}
</script>
