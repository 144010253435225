<script>
import Component from "vue-class-component";
import Vue from "vue";

import {
    MIN_TIMELINE_SCALE_BOUND,
    BASE_ZOOM,
    MAX_TIMELINE_SCALE_BOUND,
} from "../../services/TimelineService";
import { getBaseLog } from "../../base/Utils";

const MIN_DISPLAYED_SCALE_BOUND = 0;
const MAX_DISPLAYED_SCALE_BOUND = getBaseLog(BASE_ZOOM, MAX_TIMELINE_SCALE_BOUND);

function getPercentage(scale) {
    return 50 + 5 * scale.toFixed(0);
}

const MIN_PERCENTAGE = getPercentage(0);
const MAX_PERCENTAGE = getPercentage(MAX_DISPLAYED_SCALE_BOUND);

const Props = Vue.extend({
    props: {
        fixedTimeline: {
            type: String,
            required: false,
        },
    },
});

@Component
export default class ZoomTimeline extends Props {
    scale = MIN_DISPLAYED_SCALE_BOUND;

    zoomIn() {
        if (getPercentage(this.scale) === MAX_PERCENTAGE) {
            return;
        }

        this.scale = Math.min(MAX_DISPLAYED_SCALE_BOUND, this.scale + 1);
        this.$emit("zoomTimeline", this.getScaleValueForTimeline());
    }

    zoomOut() {
        if (getPercentage(this.scale) === MIN_PERCENTAGE) {
            return;
        }

        this.scale = Math.max(MIN_DISPLAYED_SCALE_BOUND, this.scale - 1);
        this.$emit("zoomTimeline", this.getScaleValueForTimeline());
    }

    getScaleValueForTimeline() {
        return Math.pow(BASE_ZOOM, this.scale);
    }

    updateScaleValue(scaleFromTimeline) {
        this.scale = getBaseLog(BASE_ZOOM, scaleFromTimeline);
    }

    getDisplayedValueFromTimeline(scaleFromTimeline) {
        return MIN_DISPLAYED_SCALE_BOUND + (scaleFromTimeline - MIN_TIMELINE_SCALE_BOUND) * 2;
    }

    render() {
        const percentage = getPercentage(this.scale);
        if (this.fixedTimeline) {
            return (
                <div class="zoomTimeline">
                    <i class="fa fa-lock zoomTimeline-lock" />
                    {this.fixedTimeline}
                </div>
            );
        }

        return (
            <div class="zoomTimeline">
                <img
                    src="/img/timelinePage/zoom_out_sign.svg"
                    onclick={this.zoomOut}
                    class={`level-item zoomTimeline-sign ${
                        percentage === MIN_PERCENTAGE ? "deactivated" : ""
                    }`}
                />
                <div class="level-item zoomTimeline-digits">{`${percentage}%`}</div>
                <img
                    src="/img/timelinePage/zoom_in_sign.svg"
                    onclick={this.zoomIn}
                    class={`level-item zoomTimeline-sign ${
                        percentage === MAX_PERCENTAGE ? "deactivated" : ""
                    }`}
                />
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.zoomTimeline {
    flex-wrap: nowrap;
    display: flex;
    align-items: center;

    > img {
        margin-right: 0;
    }
}

.zoomTimeline-lock {
    cursor: default;
    margin-right: 10px;
}

.zoomTimeline-sign {
    width: 12px;
    height: 12px;
    cursor: pointer;

    &.deactivated {
        opacity: 0.6;
    }
}

.zoomTimeline-digits {
    width: 35px;
}
</style>
