import { Service } from "../base/Service";
import { inject } from "../base/Injection";
import { Fetcher, CREDENTIALS_LOST_EVENT } from "../base/Fetcher";

const AUTOZOOM = "autozoom";
const TIMELINE_DISPLAY_EVENTS = "timelineDisplayEvents";
const LANG = "lang";
const TASK_SECONDS_GRANULARITY = "tasksSecondsGranularity";
const ALWAYS4H20 = "always4h20";
const FIRST_DAY_OF_WEEK = "firstDayOfWeek";
const DEFAULT_COLOR = "defaultColor";
const STARTING_HOUR_FIXED = "startingHourForFixedTimeline";
const ENDING_HOUR_FIXED = "endingHourForFixedTimeline";

export const UserStatuses = {
    CONNECTED: "connected",
    DISCONNECTED: "disconnected",
};

export const UserStatusEvents = {
    LOGGED_IN: "loggedIn",
    LOGGED_OUT: "loggedOut",
};

const USER_STATUS_KEY = "user.status";

export class UserService extends Service {
    @inject(Fetcher) fetcher;

    constructor(context) {
        super(context);

        this.fetcher.on(CREDENTIALS_LOST_EVENT, () => {
            localStorage.removeItem(USER_STATUS_KEY);
            this.emit(UserStatusEvents.LOGGED_OUT);
        });
    }

    getUserStatus() {
        return localStorage.getItem(USER_STATUS_KEY) || UserStatuses.DISCONNECTED;
    }

    isConnected() {
        return this.getUserStatus() === UserStatuses.CONNECTED;
    }

    getUser() {
        return this.fetcher.get("/user");
    }

    saveUser({ name, email }) {
        return this.fetcher.post("/user", { name, email });
    }

    async requestToUpdatePwd() {
        const origin = window.location.origin;
        return this.fetcher.get("/user/passwd", {
            lang: await this.getLanguage(),
            origin,
        });
    }

    async verifyEmail(email) {
        try {
            await this.fetcher.get(`/verify/email?email=${email}`);
            return true;
        } catch (e) {
            if (e.status === 406) {
                return false;
            }
            throw e;
        }
    }

    async verifyName(name) {
        try {
            await this.fetcher.get(`/verify/name?name=${name}`);
            return true;
        } catch (e) {
            if (e.status === 406) {
                return false;
            }
            throw e;
        }
    }

    hasConfirmEmail() {
        return this.signUpUser && this.signUpCode;
    }

    async confirmEmail(user) {
        this.signUpUser = user;
        this.signUpCode = await this.fetcher.get(`/confirm?email=${this.signUpUser.email}`);
    }

    async resendConfirmEmail() {
        this.signUpCode = await this.fetcher.get(`/confirm?email=${this.signUpUser.email}`);
    }

    cancelConfirmEmail() {
        this.signUpUser = null;
        this.signUpCode = null;
    }

    async signUp(code) {
        await this.fetcher.post(
            `/signup?code=${code}&hashcode=${this.signUpCode}`,
            this.signUpUser,
        );
        this.signUpUser = null;
        this.signUpCode = null;
    }

    async signIn(email, password) {
        let result;
        try {
            result = await this.fetcher.post("/signin", {
                email,
                password,
            });

            localStorage.setItem(USER_STATUS_KEY, UserStatuses.CONNECTED);
            this.emit(UserStatusEvents.LOGGED_IN);
        } catch (e) {
            // If the user was connected before signin (tried another login) and it failed,
            // let's sign him out
            this.signOut();
            throw e;
        }

        return result;
    }

    async signOut() {
        await this.fetcher.get("/signout");
        localStorage.removeItem(USER_STATUS_KEY);
        this.emit(UserStatusEvents.LOGGED_OUT);
    }

    async resetPwd(email) {
        const origin = window.location.origin;
        return this.fetcher.get("/resetpwd", {
            lang: await this.getLanguage(),
            email,
            origin,
        });
    }

    updatePwd(pwd, recoveryToken) {
        return this.fetcher.get("/updatepwd", {
            recoveryToken,
            pwd,
        });
    }

    async getRequestsCount() {
        const result = await this.fetcher.get("/user/requests");
        return result;
    }

    async getRepositories(selector) {
        const result = await this.fetcher.get("/user/repos", selector);
        return result || { items: [], count: 0 };
    }

    async getRepository(id) {
        const result = await this.fetcher.get(`/repo/${id}`);
        return result;
    }

    async getDisplayActivities() {
        const repositories = await this.getRepositories({
            limit: 1,
            order: "credential_desc",
        });
        if (!repositories.items) {
            return false;
        }
        return repositories.items.some((r) => r.credential && r.notification);
    }

    async setNotificationRepository(id, value) {
        await this.fetcher.post(`/notification/repo`, {
            id,
            notification: value,
        });
        this.emit("change");
    }

    async saveAuthRepo({ id, category }, username, token) {
        await this.fetcher.post(`/${category}/auth?id=${id}&username=${username}&token=${token}`);
        this.emit("change");
    }

    async removeAuthRepo({ id, category }) {
        await this.fetcher.delete(`/${category}/auth?id=${id}`);
        this.emit("change");
    }

    searchRepoProjects({ id, category }, query) {
        return this.fetcher.get(`/${category}/search`, {
            id,
            q: query,
        });
    }

    async setLanguage(lang) {
        localStorage.setItem(LANG, lang);
    }

    async getLanguage(defaultLang = true) {
        return localStorage.getItem(LANG) || (defaultLang && navigator.language.substring(0, 2));
    }

    async setTasksMinutesGranularity(minutesGranularity) {
        this.setTasksSecondsGranularity(minutesGranularity * 60);
    }

    async setTasksSecondsGranularity(secondsGranularity) {
        localStorage.setItem(TASK_SECONDS_GRANULARITY, secondsGranularity);
    }

    async getTasksMinutesGranularity() {
        const seconds = await this.getTasksSecondsGranularity();
        const minutes = Math.floor(seconds / 60);
        return minutes;
    }

    async getTasksSecondsGranularity(defaultSecondsGranularity = 1) {
        return (
            parseInt(localStorage.getItem(TASK_SECONDS_GRANULARITY)) || defaultSecondsGranularity
        );
    }

    async setAutoZoom(optionValue) {
        localStorage.setItem(AUTOZOOM, optionValue);
    }

    async getAutoZoom(defaultValue) {
        let value = localStorage.getItem(AUTOZOOM);
        return value ? JSON.parse(value) : defaultValue;
    }

    async setTimelineDisplayEventsEnabled(optionValue) {
        localStorage.setItem(TIMELINE_DISPLAY_EVENTS, optionValue);
    }

    async getTimelineDisplayEventsEnabled(defaultValue) {
        let value = localStorage.getItem(TIMELINE_DISPLAY_EVENTS);
        return value ? JSON.parse(value) : defaultValue;
    }

    async setAlways4h20(optionValue) {
        localStorage.setItem(ALWAYS4H20, optionValue);
    }

    async getAlways4h20(defaultValue) {
        let value = localStorage.getItem(ALWAYS4H20);
        return value ? JSON.parse(value) : defaultValue;
    }

    async setFirstDayOfWeek(optionValue) {
        localStorage.setItem(FIRST_DAY_OF_WEEK, optionValue);
    }

    async getFirstDayOfWeek(defaultValue) {
        let value = localStorage.getItem(FIRST_DAY_OF_WEEK);
        return value ? JSON.parse(value) : defaultValue;
    }

    async setStartingHourFixedTimeline(optionValue) {
        localStorage.setItem(STARTING_HOUR_FIXED, optionValue);
    }

    async getStartingHourFixedTimeline(defaultValue) {
        let value = localStorage.getItem(STARTING_HOUR_FIXED);
        return value ? JSON.parse(value) : defaultValue;
    }

    async setEndingHourFixedTimeline(optionValue) {
        localStorage.setItem(ENDING_HOUR_FIXED, optionValue);
    }

    async getEndingHourFixedTimeline(defaultValue) {
        let value = localStorage.getItem(ENDING_HOUR_FIXED);
        return value ? JSON.parse(value) : defaultValue;
    }

    async setDefaultColor(optionValue) {
        localStorage.setItem(DEFAULT_COLOR, optionValue);
    }

    async getDefaultColor(defaultValue) {
        let value = localStorage.getItem(DEFAULT_COLOR);
        return value ? JSON.parse(value) : defaultValue;
    }

    async enableEventApi() {
        const enableEventApiResult = await this.fetcher.post("/user/event-api-token");
        return this.eventApiTokenToFullUrl(enableEventApiResult.newToken);
    }

    async disableEventApi() {
        return this.fetcher.delete("/user/event-api-token");
    }

    async isEventApiEnabled() {
        const eventApiToken = await this.getEventApiToken();
        return eventApiToken != null;
    }

    async getEventApiToken() {
        const user = await this.getUser();
        return user.eventApiToken;
    }

    async getEventApiEndpointUrl() {
        const eventApiToken = await this.getEventApiToken();
        return this.eventApiTokenToFullUrl(eventApiToken);
    }

    eventApiTokenToFullUrl(eventApiToken) {
        return this.fetcher.toFullUrl(`/webhook/${eventApiToken}`);
    }
}
