<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { UserService } from "../../services/UserService";
import ColorsPicker from "../common/ColorsPicker.vue";
import { TimelineService } from "../../services/TimelineService";

@Component
export default class Options extends Vue {
    @inject(UserService) userService;
    @inject(TimelineService) timelineService;

    currentLang = "";
    timelineAutoZoomEnabled = true;
    timelineDisplayEventsEnabled = true;
    always4h20Enabled = false;
    firstDayOfWeek = 1;
    defaultColor = "1";
    tasksSecondsGranularity = 1;
    eventApiEnabled = null;
    eventApiToken = null;
    eventApiEndpointUrl = null;
    startingHourFixedTimeline = 0;
    endingHourFixedTimeline = 24;

    async created() {
        this.currentLang = await this.userService.getLanguage(false);
        this.timelineAutoZoomEnabled = await this.userService.getAutoZoom(true);
        this.timelineDisplayEventsEnabled = await this.userService.getTimelineDisplayEventsEnabled(
            true
        );
        this.always4h20Enabled = await this.userService.getAlways4h20(false);
        this.firstDayOfWeek = await this.userService.getFirstDayOfWeek(1);
        this.defaultColor = `${await this.userService.getDefaultColor("1")}`;
        this.tasksSecondsGranularity = await this.userService.getTasksSecondsGranularity(1);
        this.eventApiEnabled = await this.userService.isEventApiEnabled();
        if (this.eventApiEnabled) {
            this.eventApiToken = await this.userService.getEventApiToken();
            this.eventApiEndpointUrl = await this.userService.getEventApiEndpointUrl();
        }
        this.startingHourFixedTimeline = await this.userService.getStartingHourFixedTimeline(0);
        this.endingHourFixedTimeline = await this.userService.getEndingHourFixedTimeline(24);
    }

    async updateLang() {
        const selectedIndex = this.$refs.langs.selectedIndex;
        this.currentLang = this.$refs.langs[selectedIndex].value;
        window.__localeId__ = this.currentLang;
        await this.userService.setLanguage(this.currentLang);

        this.$root.$i18n.locale = await this.userService.getLanguage();
    }

    async updateTimelineAutoZoom(value) {
        await this.userService.setAutoZoom(value);
        this.timelineAutoZoomEnabled = value;
    }

    async updateTimelineDisplayEventsEnabled(value) {
        await this.userService.setTimelineDisplayEventsEnabled(value);
        this.timelineDisplayEventsEnabled = value;
    }

    async updateAlways4h20(value) {
        await this.userService.setAlways4h20(value);
        this.always4h20Enabled = value;
    }

    async updateFirstDayOfWeek(value) {
        await this.userService.setFirstDayOfWeek(value);
        this.firstDayOfWeek = value;
    }

    async updateStartingHourFixedTimeline(value) {
        await this.userService.setStartingHourFixedTimeline(value);
        this.startingHourFixedTimeline = value;
    }

    async updateEndingHourFixedTimeline(value) {
        await this.userService.setEndingHourFixedTimeline(value);
        this.endingHourFixedTimeline = value;
    }

    async updateDefaultColor(value) {
        await this.userService.setDefaultColor(value);
        await this.timelineService.cleanCache();
        this.defaultColor = value;
    }

    async updateTasksSecondsGranularity(value) {
        await this.userService.setTasksSecondsGranularity(value);
        this.tasksMinutesGranularity = value;
    }

    async toggleEventApiEnabling(enabling) {
        this.eventApiEnabled = enabling;
        if (enabling) {
            this.eventApiEndpointUrl = await this.userService.enableEventApi();
        } else {
            this.eventApiEndpointUrl = await this.userService.disableEventApi();
            this.eventApiToken = null;
            this.eventApiEndpointUrl = null;
        }
    }

    async copyEventApiEndpointUrlToClipboard() {
        navigator.clipboard.writeText(this.eventApiEndpointUrl);
        this.$buefy.toast.open({
            message: this.$t("Utils.copied"),
            type: "is-success",
        });
    }

    render() {
        return (
            <div class="options">
                <article class="message">
                    <div class="message-header">
                        <p>{this.$t("Options.lang.header")}</p>
                    </div>
                    <div class="message-body is-half">
                        <div class="field">
                            <div class="label mr-2">{this.$t("Options.lang.input")}</div>
                            <div class="control has-icons-left">
                                <div class="select fix-size">
                                    <select
                                        key={this.currentLang}
                                        ref="langs"
                                        onChange={this.updateLang}
                                    >
                                        <option value="" selected>
                                            {this.$t("Options.lang.default")}
                                        </option>
                                        <option value="en" selected={this.currentLang === "en"}>
                                            {this.$t("Options.lang.en")}
                                        </option>
                                        <option value="fr" selected={this.currentLang === "fr"}>
                                            {this.$t("Options.lang.fr")}
                                        </option>
                                    </select>
                                </div>
                                <span class="icon is-left">
                                    <span class="fa fa-globe fa-w-12 fa-lg" />
                                </span>
                            </div>
                        </div>
                    </div>
                </article>
                <article>
                    <div class="message-header">
                        <p>{this.$t("Options.displayOptions.title")}</p>
                    </div>
                    <div class="message-body is-half">
                        <div class="field">
                            <div class="label mr-2">
                                {this.$t("Options.displayOptions.firstDayOfWeek")}
                            </div>
                            <b-select
                                value={this.firstDayOfWeek}
                                oninput={this.updateFirstDayOfWeek}
                                class="fix-size"
                            >
                                <option value="0">{this.$t("Options.sunday")}</option>
                                <option value="1">{this.$t("Options.monday")}</option>
                            </b-select>
                        </div>
                        <div class="field">
                            <div class="label mr-2">
                                {this.$t("Options.displayOptions.tasksSecondsGranularity")}
                            </div>
                            <b-select
                                value={this.tasksSecondsGranularity}
                                oninput={this.updateTasksSecondsGranularity}
                                class="fix-size"
                            >
                                <option value="1">
                                    {this.$tc("Options.displayOptions.xSeconds", 1, { x: 1 })}
                                </option>
                                <option value="5">
                                    {this.$tc("Options.displayOptions.xSeconds", 5, { x: 5 })}
                                </option>
                                <option value="10">
                                    {this.$tc("Options.displayOptions.xSeconds", 10, { x: 10 })}
                                </option>
                                <option value="15">
                                    {this.$tc("Options.displayOptions.xSeconds", 15, { x: 15 })}
                                </option>
                                <option value="20">
                                    {this.$tc("Options.displayOptions.xSeconds", 20, { x: 20 })}
                                </option>
                                <option value="30">
                                    {this.$tc("Options.displayOptions.xSeconds", 30, { x: 30 })}
                                </option>
                                <option value="60">
                                    {this.$tc("Options.displayOptions.xMinutes", 1, { x: 1 })}
                                </option>
                                <option value="300">
                                    {this.$tc("Options.displayOptions.xMinutes", 5, { x: 5 })}
                                </option>
                                <option value="600">
                                    {this.$tc("Options.displayOptions.xMinutes", 10, { x: 10 })}
                                </option>
                                <option value="900">
                                    {this.$tc("Options.displayOptions.xMinutes", 15, { x: 15 })}
                                </option>
                                <option value="1200">
                                    {this.$tc("Options.displayOptions.xMinutes", 20, { x: 20 })}
                                </option>
                                <option value="1800">
                                    {this.$tc("Options.displayOptions.xMinutes", 30, { x: 30 })}
                                </option>
                            </b-select>
                        </div>
                        <div class="field">
                            <div class="label mr-2">
                                {this.$t("Options.displayOptions.defaultColor")}
                            </div>
                            <ColorsPicker
                                value={this.defaultColor}
                                onChange={this.updateDefaultColor}
                            />
                        </div>
                        <div class="field">
                            <div class="label mr-2">
                                {this.$t("Options.displayOptions.autoZoom")}
                            </div>
                            <b-switch
                                class="option-switch"
                                value={this.timelineAutoZoomEnabled}
                                oninput={this.updateTimelineAutoZoom}
                            />
                        </div>
                        <div class="field">
                            <div class="label mr-2">
                                {this.$t("Options.displayOptions.always4h20")}
                            </div>
                            <b-switch
                                class="option-switch"
                                value={this.always4h20Enabled}
                                oninput={this.updateAlways4h20}
                            />
                        </div>
                        <div class="field">
                            <div class="label mr-2">
                                {this.$t("Options.displayOptions.startingHourFixedTimeline")}
                            </div>
                            <b-input
                                type="number"
                                value={this.startingHourFixedTimeline}
                                oninput={this.updateStartingHourFixedTimeline}
                            />
                        </div>
                        <div class="field">
                            <div class="label mr-2">
                                {this.$t("Options.displayOptions.endingHourFixedTimeline")}
                            </div>
                            <b-input
                                type="number"
                                value={this.endingHourFixedTimeline}
                                oninput={this.updateEndingHourFixedTimeline}
                            />
                        </div>
                    </div>
                </article>
                <article>
                    <div class="message-header">
                        <p>{this.$t("Options.eventApiOptions.title")}</p>
                    </div>
                    <div class="message-body is-half">
                        <div class="field">
                            <div class="label mr-2">
                                {this.$t("Options.eventApiOptions.enable")}
                            </div>
                            {this.eventApiEnabled && (
                                <div class="url">
                                    <b-input
                                        type="text"
                                        value={this.eventApiEndpointUrl}
                                        readonly
                                    />
                                    <b-button
                                        icon-right="copy"
                                        onclick={this.copyEventApiEndpointUrlToClipboard}
                                    />
                                </div>
                            )}
                            <b-switch
                                class="option-switch"
                                value={this.eventApiEnabled}
                                oninput={this.toggleEventApiEnabling}
                            />
                        </div>
                        <div class="field">
                            <div class="label mr-2">
                                {this.$t("Options.eventApiOptions.timelineDisplayEventsEnabled")}
                            </div>
                            <b-switch
                                class="option-switch"
                                value={this.timelineDisplayEventsEnabled}
                                oninput={this.updateTimelineDisplayEventsEnabled}
                            />
                        </div>
                    </div>
                </article>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.field {
    display: flex;
    align-items: center;
    height: 40px;
}

.label {
    flex-grow: 1;
}

.option-switch {
    display: flex;
    margin: 0;
}

.url {
    display: flex;
    align-items: center;
    margin-right: 1rem;
}
</style>

<style lang="scss">
.options {
    .fix-size {
        select {
            width: 150px;
        }
    }
}
</style>
