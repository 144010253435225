import { startOfDay, format as fnsFormat } from "date-fns";
import { fr, enGB } from "date-fns/locale";
import emoji from "./emoji.json";

export const EMPTY_UUID = "00000000-0000-0000-0000-000000000000";

export function from2Json(refs) {
    return Object.entries(refs).reduce((acc, [key, node]) => {
        if (node.name) {
            if (!node.checked) {
                return acc;
            }

            return {
                ...acc,
                [node.name]: node.value,
            };
        }

        let value = null;
        if (node.tags !== undefined) {
            value = node.tags;
        } else if (
            node.selected !== null &&
            node.selected !== undefined &&
            node.selected.id !== null &&
            node.selected.id !== undefined
        ) {
            value = node.selected.id;
        } else if (node.newValue !== undefined) {
            value = node.newValue;
        } else if (node.value !== undefined) {
            value = node.value;
        } else if (node.getValue !== undefined) {
            value = node.getValue();
        }

        return {
            ...acc,
            [key]: value,
        };
    }, {});
}

export function diffValue(diff) {
    return diffValueWithSeconds(diff, true);
}

function diffValueWithSeconds(diff, showSeconds) {
    let hours = ~~(diff / (1000 * 60 * 60));
    diff -= hours * 1000 * 60 * 60;

    let minutes = ~~(diff / (1000 * 60));
    diff -= minutes * 1000 * 60;

    let seconds = ~~(diff / 1000);
    diff -= seconds * 1000;

    let result =
        (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes);
    if (showSeconds) {
        result += ":" + (seconds < 10 ? "0" + seconds : seconds);
    }
    return result;
}

/**
 * Shows the given duration :
 * - As diffValue (in hours / minutes / seconds) if hours in hoursInDay == 0 (e.g. "2:25")
 * - And/or as a wte using hours in day > 0 (e.g. "0.25 days")
 * - And/or as % of given total > 0 (e.g. "52%")
 */
export function durationStringAccordingToDisplayModes(i18n, report, duration, total) {
    if (!report) {
        console.warn("Missing report to display time, use default rendering");
        return diffValueWithSeconds(duration * 1000, false);
    }

    let unitString = i18n.t("Utils.etp");
    let durationString = "";
    const separator = " / ";

    if (report.durationDisplayModeExactDuration) {
        durationString += diffValueWithSeconds(duration * 1000, false) + separator;
    }

    if (report.durationDisplayModePortionOfTotal && total) {
        durationString += Math.round((duration / total) * 100) + "%" + separator;
    }

    if (report.durationDisplayModeHoursPerDay) {
        const hoursPerDayInSeconds = timeStringToSeconds(report.durationDisplayModeHoursPerDay);
        if (hoursPerDayInSeconds) {
            let percentageOfTotalDay = duration / hoursPerDayInSeconds;
            durationString += Math.round(percentageOfTotalDay * 100) / 100 + unitString + separator;
        }
    }

    let lastSeparatorPos = durationString.lastIndexOf(separator);
    if (lastSeparatorPos !== -1) {
        durationString = durationString.substring(0, lastSeparatorPos);
    }

    return durationString;
}

/**
 * Returns number of seconds corresponding to the given string (e.g '00:01' => 60).
 */
export function timeStringToSeconds(timeString) {
    if (timeString && timeString.split) {
        const elements = timeString.split(":");
        if (elements.length == 2 && elements[1].length == 2 && elements[0].length >= 2) {
            let seconds = 0;
            for (let index = 0, j = 2; index < elements.length; index++) {
                const timeUnit = parseInt(elements[index]);
                if (isNaN(timeUnit)) {
                    return false;
                }
                seconds = seconds + timeUnit * Math.pow(60, j - index);
            }
            return seconds;
        }
    }
    return false;
}

export function getBaseLog(x, y) {
    return Math.log(y) / Math.log(x);
}

export function nonce() {
    return `${Date.now()}`;
}

export function getProgressInDay(date, base) {
    const startDate = startOfDay(base || date);

    const progress = (100 * (date - startDate)) / 1000 / 60 / 60 / 24;
    return progress;
}

export function getDateWithLeft(date, progress) {
    const startDate = startOfDay(date);

    const result = new Date((1000 * 60 * 60 * 24 * progress) / 100 + startDate.getTime());
    return result;
}

export function addDateWidth(date, width) {
    const result = new Date((1000 * 60 * 60 * 24 * width) / 100 + date.getTime());
    return result;
}

export function subDateWidth(date, width) {
    const result = new Date(date.getTime() - (1000 * 60 * 60 * 24 * width) / 100);
    return result;
}

export function fromPercentToPx(distance, reference) {
    return (distance / 100) * reference;
}

export function fromPxToPercent(distance, reference) {
    return (distance * 100) / reference;
}

const locales = { en: enGB, fr };

export function format(date, formatStr = "PP", options) {
    return fnsFormat(date, formatStr, {
        locale: locales[window.__localeId__],
        ...options,
    });
}

export function formatDuration(duration, withSeconds = true) {
    if (!duration) {
        return `00:00:00`;
    }
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration - hours * 3600) / 60);
    const seconds = Math.floor(duration - hours * 3600 - minutes * 60);
    return `${renderUnitOfTime(hours)}:${renderUnitOfTime(minutes)}${
        withSeconds ? `:${renderUnitOfTime(seconds)}` : ""
    }`;
}

export function renderUnitOfTime(unit) {
    return `${unit < 10 ? `0${unit}` : unit}`;
}

export function searchEmoji(code) {
    return emoji.find((e) => e.aliases.includes(code) || e.tags.includes(code));
}

export function replaceEmoji(text) {
    return text?.replace(/:([\w]+):/g, (all, code) => searchEmoji(code)?.emoji || all);
}

export function isEquals(object, other) {
    return JSON.stringify(object) == JSON.stringify(other);
}
