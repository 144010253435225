<script>
import Component from "vue-class-component";
import Vue from "vue";

@Component
export default class App extends Vue {
    created() {
        // eslint-disable-next-line no-undef
        if (SERVICE_WORKER_ENABLE) {
            this.registerSw();
        }
    }

    async registerSw() {
        const registration = await navigator.serviceWorker.register("/ServiceWorker.js", {
            scope: "/",
        });
        const isUpdate = registration.active; // Is there already an active service worker ?

        registration.addEventListener("updatefound", () => {
            if (registration.installing) {
                registration.installing.addEventListener("statechange", (event) => {
                    if (isUpdate && event.target.state === "installed") {
                        this.promptUser(registration);
                    }
                });
            }
        });

        if (isUpdate && registration.waiting) {
            // The new service worker is already installed but waits for activation
            this.promptUser(registration);
        }
    }

    promptUser(registration) {
        const updateSw = () => {
            if (!registration.waiting) {
                return;
            }

            // Reload the page when the new service  worker takes control
            navigator.serviceWorker.addEventListener("controllerchange", () => {
                window.location.reload();
            });

            // Ask the new service worker to take control
            registration.waiting.postMessage({ type: "SKIP_WAITING" });
        };

        // Ask the user what he wants to do
        this.$buefy.notification.open({
            message: [
                <div class="update-notification">
                    {this.$t("App.updatefound")}{" "}
                    <a href="#" onclick={updateSw}>
                        {this.$t("App.install")}
                    </a>
                </div>,
            ],
            type: "is-info",
            position: "is-bottom-right",
            indefinite: true,
        });
    }

    render() {
        return <router-view />;
    }
}
</script>

<style lang="scss">
.update-notification {
    pointer-events: all;
}
</style>
