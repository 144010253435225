<script>
import Component from "vue-class-component";
import Vue from "vue";

import { inject } from "../../base/Injection";
import { TimelineService } from "../../services/TimelineService";
import { ProjectService } from "../../services/ProjectService";
import { from2Json, EMPTY_UUID } from "../../base/Utils";
import { TimerService } from "../../services/TimerService";
import { UserService } from "../../services/UserService";
import ConnectModal from "../../pages/ConnectModal.vue";
import DatePickerInput from "../common/DatePickerInput.vue";
import TagsSelection from "../common/TagsSelection.vue";
import History from "./History.vue";
import ActivitiesDropDownMenu from "./ActivitiesDropDownMenu.vue";

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"]);

@Component
export default class TaskForm extends Vue {
    @inject(TimelineService) timelineService;
    @inject(ProjectService) projectService;
    @inject(TimerService) timerService;
    @inject(UserService) userService;

    task = {};
    project = null;
    repo = null;
    selectedProjects = [];
    selectedTasks = [];
    defaultColor = "1";
    displayActivities = false;

    created() {
        this.initRepo = async () => {
            if (this.project.repoId && this.project.repoId != EMPTY_UUID) {
                this.repo = await this.userService.getRepository(this.project.repoId);
            } else {
                this.repo = null;
            }
        };
        this.userService.on("change", this.initRepo);
    }

    destroyed() {
        this.userService.off("change", this.initRepo);
    }

    mounted() {
        this.init();
    }

    beforeRouteLeave(to, from, next) {
        next();
    }

    beforeRouteUpdate(to, from, next) {
        next();
        this.init();
    }

    async init() {
        // Option
        this.defaultColor = `${await this.userService.getDefaultColor("1")}`;
        this.displayActivities = await this.userService.getDisplayActivities();

        // Fetch current data
        this.id = this.$route.params.id;
        this.task = (await this.timelineService.getTask(this.id)) || {};

        this.selectedProjects = (await this.projectService.searchProjects(this.task.project)) || [];
        this.selectedTasks =
            (await this.projectService.searchTasks(this.task.projectId, this.task.title)) || [];

        if (this.task.repoId && this.task.repoId != EMPTY_UUID) {
            this.repo = await this.userService.getRepository(this.task.repoId);
        } else {
            this.repo = null;
        }

        this.project = {
            fake: true,
            id: this.task.projectId,
            color: this.task.color,
            name: this.task.project,
            repoId: this.task.repoId,
            repoCategory: this.task.repoCategory,
            repoProjectId: this.task.repoProjectId,
        };
        this.$refs.projectId.setSelected(this.project);
    }

    async update() {
        this.task = await this.timelineService.getTask(this.id);
    }

    back() {
        this.$router.push("/timeline").catch(() => {});
    }

    async onInputProjectName(name) {
        this.selectedProjects = (await this.projectService.searchProjects(name)) || [];
        this.onChange();
    }

    async onSelectProjectName() {
        if (this.$refs.projectId.selected && this.$refs.projectId.selected.fake) {
            return;
        }

        this.project = this.$refs.projectId.newValue && this.$refs.projectId.selected;
        if (this.project) {
            await this.addDefaultTags();
            await this.initRepo();
        }
        this.onChange();
    }

    async onSelectTitle(title) {
        if (this.project) {
            const projectId = this.project.id;

            const promises = [this.projectService.searchTasks(projectId, title)];

            if (this.project.repoCategory) {
                promises.push(this.projectService.searchIssues(projectId, title));
            }

            const values = await Promise.allSettled(promises);
            this.selectedTasks = values.reduce(
                (array, promise) => [...array, ...(promise.value || [])],
                []
            );

            await this.addDefaultTags();
        }

        this.onChange();
    }

    async addDefaultTags() {
        const defaultTags = await this.projectService.getDefaultTags(this.project.id);
        let currentTags = this.$refs.tags.getSelectedTags();

        // The title can contains some tags when it is selected
        if (this.$refs.title.selected && this.$refs.title.selected.tags) {
            currentTags = currentTags.filter((t) => !t.startsWith("#"));
            defaultTags.push(...this.$refs.title.selected.tags);
        }

        defaultTags.push(...currentTags);

        this.$refs.tags.setSelectedTags([...new Set(defaultTags)]);
    }

    onChange() {
        const taskForm = from2Json(this.$refs);
        const task = { ...this.task, ...taskForm };

        if (this.project && this.project.id !== EMPTY_UUID) {
            task.color = this.project.color;
            task.project = this.project.name;
            task.repoId = this.project.repoId;
            task.repoCategory = this.project.repoCategory;
            task.repoProjectId = this.project.repoProjectId;
        } else {
            task.color = this.defaultColor;
            task.project = this.$refs.projectId.newValue;
            task.projectId = EMPTY_UUID;
            task.repoId = "";
            task.repoCategory = "";
            task.repoProjectId = "";
        }
        task.title = this.$refs.title.newValue;
        task.tags = this.$refs.tags.getSelectedTags();

        this.timelineService.updateTask(task);
    }

    deleteTask() {
        this.$buefy.dialog.confirm({
            title: this.$t("TaskForm.confirm.title"),
            message: this.$t("TaskForm.confirm.message"),
            confirmText: this.$t("TaskForm.confirm.confirmText"),
            type: "is-danger",
            hasIcon: true,
            onConfirm: async () => {
                if (this.task.running) {
                    this.timerService.stop();
                }
                try {
                    history.back();
                    await this.timelineService.deleteTask(this.id);
                    this.$buefy.toast.open({
                        message: this.$t("TaskForm.confirm.success"),
                        type: "is-success",
                    });
                } catch {
                    this.$buefy.toast.open({
                        message: this.$t("error.server"),
                        type: "is-danger",
                    });
                }
            },
        });
    }

    get hasIssue() {
        return (
            this.project &&
            this.project.repoProjectId &&
            this.task &&
            this.task.tags &&
            this.task.tags.some((t) => t.startsWith("#"))
        );
    }

    async seeIssue() {
        try {
            const issueId = this.task.tags.find((t) => t.startsWith("#"));
            const opened = await this.projectService.openIssue(this.project.id, issueId);
            if (!opened) {
                this.$buefy.toast.open({
                    message: this.$t("TaskForm.issue.error"),
                    type: "is-danger",
                });
            }
        } catch {
            this.$buefy.toast.open({
                message: this.$t("TaskForm.issue.error"),
                type: "is-danger",
            });
        }
    }

    connectRepo() {
        if (!this.repo) {
            return;
        }
        this.$buefy.modal.open({
            parent: this,
            component: ConnectModal,
            hasModalCard: true,
            customClass: "custom-class custom-class-2",
            trapFocus: true,
            props: { repo: this.repo },
        });
    }

    get hasRepoAuth() {
        return !this.project || !this.repo || (this.repo && this.repo.credential);
    }

    get hasRepo() {
        return this.project && this.project.repoProjectId && this.task && this.task.title;
    }

    async createIssue() {
        try {
            const issue = await this.projectService.createIssue(this.project.id, this.task.title);
            this.$refs.title.setSelected(issue);
            await this.addDefaultTags();
            this.onChange();
            this.$buefy.toast.open({
                message: this.$t("TaskForm.issue.created"),
                type: "is-success",
            });
        } catch {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
        }
    }

    async replaceHistory(history) {
        this.task = this.timelineService._replaceTask({
            ...history,
            id: this.task.id,
            startDate: this.task.startDate,
            endDate: this.task.endDate,
            running: this.task.running,
        });

        this.project = {
            fake: true,
            id: this.task.projectId,
            color: this.task.color,
            name: this.task.project,
            repoId: this.task.repoId,
            repoCategory: this.task.repoCategory,
            repoProjectId: this.task.repoProjectId,
        };
        this.$refs.projectId.setSelected(this.project);
    }

    async replaceActivity(activity) {
        this.task = this.timelineService._replaceTask({
            ...activity,
            projectId: activity.projectId,
            project: activity.projectName,
            color: activity.projectColor,
            id: this.task.id,
            startDate: this.task.startDate,
            endDate: this.task.endDate,
            running: this.task.running,
        });

        this.project = {
            fake: true,
            id: this.task.projectId,
            color: this.task.color,
            name: this.task.project,
            repoId: this.task.repoId,
            repoCategory: this.task.repoCategory,
            repoProjectId: this.task.repoProjectId,
        };
        this.$refs.projectId.setSelected(this.project);
    }

    render() {
        return (
            <div class="timeline-container">
                <div class="page-center">
                    <div class="page-form">
                        <nav class="navbar">
                            <div class="navbar-start">
                                <a
                                    class="button is-primary is-outlined is-rounded"
                                    onclick={this.back}
                                >
                                    {this.$t("TaskForm.back")}
                                </a>
                            </div>

                            <div class="navbar-end">
                                <a
                                    class={
                                        this.hasRepoAuth && this.hasIssue
                                            ? "button is-primary is-outlined is-rounded mr-2"
                                            : "is-hidden"
                                    }
                                    onclick={this.seeIssue}
                                >
                                    <div class="icon mr-1">
                                        <i class="fa fa-link"></i>
                                    </div>
                                    {this.$t("TaskForm.issue.see")}
                                </a>
                                <a
                                    class={
                                        this.hasRepoAuth && this.hasRepo && !this.hasIssue
                                            ? "button is-primary is-outlined is-rounded mr-2"
                                            : "is-hidden"
                                    }
                                    onclick={this.createIssue}
                                >
                                    <div class="icon mr-1">
                                        <i class="fa fa-plus"></i>
                                    </div>
                                    {this.$t("TaskForm.issue.create")}
                                </a>

                                {this.displayActivities && (
                                    <ActivitiesDropDownMenu onchange={this.replaceActivity} />
                                )}

                                <History onchange={this.replaceHistory} />

                                <a
                                    class="button is-danger is-outlined is-rounded mr-2"
                                    onclick={this.deleteTask}
                                >
                                    <div class="icon mr-1">
                                        <i class="fa fa-trash"></i>
                                    </div>
                                    {this.$t("TaskForm.delete")}
                                </a>
                            </div>
                        </nav>

                        <b-field label={this.$t("TaskForm.project")}>
                            <b-autocomplete
                                key={`project-${this.task && this.task.id}`}
                                ref="projectId"
                                placeholder={this.$t("TaskForm.project-placeholder")}
                                keep-first
                                clearable
                                data={this.selectedProjects}
                                oninput={this.onInputProjectName}
                                onselect={this.onSelectProjectName}
                                field="name"
                                value={this.task && this.task.project}
                            >
                                <template slot="empty">
                                    {this.$t("TaskForm.project-empty")}
                                </template>
                            </b-autocomplete>
                            <template slot="message">
                                <p class={!this.hasRepoAuth ? "has-text-danger" : "is-hidden"}>
                                    {this.$t("TaskForm.repo.warning")}{" "}
                                    <a
                                        class="has-text-danger"
                                        onclick={() => this.connectRepo(this.repo)}
                                    >
                                        {this.$t("TaskForm.repo.connect")}
                                    </a>
                                </p>
                            </template>
                        </b-field>
                        <b-field label={this.$t("TaskForm.title")}>
                            <b-autocomplete
                                key={`title-${this.task && this.task.id}`}
                                ref="title"
                                placeholder={this.$t("TaskForm.title-placeholder")}
                                clearable
                                data={this.selectedTasks}
                                oninput={this.onSelectTitle}
                                field="title"
                                value={this.task && this.task.title}
                                scopedSlots={{
                                    default: (props) => {
                                        return (
                                            <div>
                                                {props.option.url ? `#${props.option.id} ` : ""}
                                                {props.option.title}
                                            </div>
                                        );
                                    },
                                }}
                            >
                                <template slot="empty">{this.$t("TaskForm.title-empty")}</template>
                            </b-autocomplete>
                        </b-field>
                        <b-field label={this.$t("TaskForm.tags")}>
                            <TagsSelection
                                ref="tags"
                                key={`tags-${this.task && this.task.id}`}
                                projectIds={
                                    this.project && this.project.id != EMPTY_UUID
                                        ? [this.project.id]
                                        : []
                                }
                                tags={this.task && this.task.tags}
                                oninput={this.onChange}
                                allowNew
                            />
                        </b-field>
                        <b-field label={this.$t("TaskForm.startDate")}>
                            <DatePickerInput
                                type="datetime"
                                ref="startDate"
                                key={this.task && this.task.id}
                                placeholder={this.$t("TaskForm.startDate-placeholder")}
                                icon="calendar"
                                horizontal-time-picker
                                position="is-top-right"
                                oninput={this.onChange}
                                value={this.task && this.task.startDate}
                                max-datetime={this.task && this.task.endDate}
                                editable
                            />
                        </b-field>
                        <b-field
                            label={
                                this.task && this.task.running
                                    ? this.$t("TaskForm.endDate-running")
                                    : this.$t("TaskForm.endDate")
                            }
                        >
                            <DatePickerInput
                                type="datetime"
                                ref="endDate"
                                key={this.task && this.task.id}
                                placeholder={this.$t("TaskForm.endDate-placeholder")}
                                icon="calendar"
                                horizontal-time-picker
                                position="is-top-right"
                                oninput={this.onChange}
                                value={this.task && this.task.endDate}
                                min-datetime={this.task && this.task.startDate}
                                editable
                                disabled={this.task && this.task.running}
                            />
                        </b-field>
                    </div>
                </div>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.activity-item {
    width: 400px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.is-active {
        background-color: transparent;
        color: #4a4a4a;
    }
}
</style>
