<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { TeamService } from "../../services/TeamService";

@Component
export default class UserSelection extends Vue {
    @inject(TeamService) teamService;

    searchMembers = [];

    async onInputMembers(name) {
        this.searchMembers = (await this.teamService.searchMembers(name)) || [];
    }

    getSelectedUserId() {
        return this.$refs.user.selected && this.$refs.user.selected.id;
    }

    clearSelectedUser() {
        this.$refs.user.newValue = "";
    }

    render() {
        return (
            <b-autocomplete
                ref="user"
                icon="user"
                placeholder={this.$t("UserSelection.placeholder")}
                keep-first
                data={this.searchMembers}
                oninput={this.onInputMembers}
                field="name"
            >
                <template slot="empty">{this.$t("UserSelection.empty")}</template>
            </b-autocomplete>
        );
    }
}
</script>
