<script>
import Component from "vue-class-component";
import Vue from "vue";

import { inject } from "../../base/Injection";

import { MoveTypes, TimelineService } from "../../services/TimelineService";
import Task, { Actions } from "./Task.vue";
import { isSameDay } from "date-fns";
import { fromPercentToPx, fromPxToPercent, getProgressInDay } from "../../base/Utils";
import { TimerService } from "../../services/TimerService";

@Component
export default class Tasks extends Vue {
    @inject(TimelineService) timelineService;
    @inject(TimerService) timerService;

    tasks = [];

    created() {
        this.update();
    }

    mounted() {
        this._routerHook = this.$router.beforeEach((to, from, next) => {
            this._toggleEditMode(to);
            next();
        });

        this._onkeydown = (e) => {
            if (e.key === "Delete") {
                const tasks = this.getSelectedTasks();
                this._deleteTasks(tasks);
            } else if (e.key === "Control") {
                this._controlKeyPressed = true;
            }
        };
        this._onkeyup = (e) => {
            if (e.key === "Control") {
                this._controlKeyPressed = false;
            }
        };
        document.addEventListener("keydown", this._onkeydown);
        document.addEventListener("keyup", this._onkeyup);
    }

    updated() {
        this._toggleEditMode(this.$route);
    }

    _deleteTasks(tasks) {
        if (!tasks.length || this.isEditing()) {
            return;
        }

        this.$buefy.dialog.confirm({
            title: this.$t("Tasks.confirm.title"),
            message: this.$t("Tasks.confirm.message"),
            confirmText: this.$t("Tasks.confirm.confirmText"),
            type: "is-danger",
            hasIcon: true,
            onConfirm: async () => {
                try {
                    for (const t of tasks) {
                        if (t.running) {
                            this.timerService.stop();
                        }
                        await this.timelineService.deleteTask(t.id);
                    }

                    this.$buefy.toast.open({
                        message: this.$t("Tasks.confirm.success"),
                        type: "is-success",
                    });

                    this.unselectTasks();
                } catch {
                    this.$buefy.toast.open({
                        message: this.$t("error.server"),
                        type: "is-danger",
                    });
                }
            },
        });
    }

    _toggleEditMode(route) {
        const editedTaskId = route.path.includes("timeline/edit") ? route.params.id : null;
        const containerComp = this.$refs.tasks;
        if (!containerComp || this._editedTaskId === editedTaskId) {
            return;
        }

        containerComp.classList.remove("is-editing");
        this.tasks.forEach((t) => this.$refs[t.id].setState({ isEditing: false }));

        this._editedTaskId = editedTaskId;
        if (this._editedTaskId) {
            this.unselectTasks();

            containerComp.classList.add("is-editing");
            if (this.$refs[this._editedTaskId]) {
                this.$refs[this._editedTaskId].setState({ isEditing: true });
            } else {
                this._editedTaskId = null; // the task component is not yet created, we'll wait for the next update cycle
            }
        }
    }

    isEditing() {
        return Boolean(this._editedTaskId);
    }

    beforeDestroy() {
        this._routerHook && this._routerHook();
        document.removeEventListener("keydown", this._onkeydown);
        document.removeEventListener("keyup", this._onkeyup);
    }

    async update() {
        try {
            if (!isSameDay(this.currentDate, this.timelineService.currentDate)) {
                this.unselectTasks();
                this.tasks = [];
                this.currentDate = this.timelineService.currentDate;
            }
            const tasks = await this.timelineService.getTasks();
            this.tasks = [...tasks];
            this.hasError = false;
        } catch (e) {
            // Avoid multiple display of toast
            if (!this.hasError) {
                this.hasError = true;
                this.tasks = [];

                this.$buefy.toast.open({
                    message: this.$t("error.server"),
                    type: "is-danger",
                });
            }
        }
    }

    selectTasksInRect(rectangle) {
        if (this.isEditing()) {
            return [];
        }

        const selectedTasks = this.tasks.filter((t) => {
            const rect = this.$refs[t.id].$el.getBoundingClientRect();
            return (
                rectangle.left < rect.right &&
                rectangle.right > rect.left &&
                rectangle.top < rect.bottom &&
                rectangle.bottom > rect.top
            );
        });

        this.tasks.forEach((t) =>
            this.$refs[t.id].setState({ isSelected: selectedTasks.includes(t) })
        );
        this.$refs.tasks.classList.toggle("hasSelection", selectedTasks.length);
        this.$emit("hasSelection", Boolean(selectedTasks.length));

        return selectedTasks;
    }

    selectTasks(tasks) {
        if (this.isEditing()) {
            return;
        }

        tasks.forEach((t) => this.$refs[t.id].setState({ isSelected: true }));
        const hadSelection = this.$refs.tasks.classList.contains("hasSelection");
        this.$refs.tasks.classList.add("hasSelection");
        !hadSelection && this.$emit("hasSelection", true);
    }

    unselectTasks(tasks = this.getSelectedTasks()) {
        tasks.forEach((t) => this.$refs[t.id] && this.$refs[t.id].setState({ isSelected: false }));
        if (!this.$refs.tasks) {
            return;
        }
        const hasSelection = this.getSelectedTasks().length;
        this.$refs.tasks.classList.toggle("hasSelection", hasSelection);
        !hasSelection && this.$emit("hasSelection", false);
    }

    isSelected(task) {
        return this.$refs[task.id].getState().isSelected;
    }

    getSelectedTasks() {
        return this.tasks.filter((t) => this.isSelected(t));
    }

    move(event, tasks = this._tasks2Update, moveType) {
        const running = tasks.find((t) => t.running);
        if (running) {
            return 0;
        }

        this.$refs.tasks.classList.add("is-updating");

        const diff = event.pageX - this._initialPageX;
        const distance = fromPxToPercent(diff, this._containerWidth);
        const initBounds = tasks.map((t) => this._tasksBoundsBeforeUpdate.get(t.id));
        const updatedBounds = this.timelineService.moveTasks(initBounds, distance, moveType);

        tasks.forEach((t) => {
            const newInterval = updatedBounds.get(t.id);
            t.startDate = newInterval.startDate;
            t.endDate = newInterval.endDate;
        });

        return fromPercentToPx(updatedBounds.move, this._containerWidth);
    }

    resizeLeft(taskId, event) {
        const task = this._tasks2Update.find((t) => t.id === taskId);

        let mt = MoveTypes.MAGNETISED_BLOCK;
        let distance = event.pageX - this._initialPageX;
        if (this._tasks2Update.length > 1) {
            const prevTasks = this._tasks2Update.filter((t) => t.startDate < task.startDate);
            distance = this.move(event, prevTasks, MoveTypes.LEFT_MAGNETISED_BLOCK);
            mt = prevTasks.length ? MoveTypes.FREE_BLOCK : mt;
        }

        this._resize(task, distance, -1, mt);
    }

    resizeRight(taskId, event) {
        const task = this._tasks2Update.find((t) => t.id === taskId);
        if (task.running) {
            return;
        }

        let mt = MoveTypes.MAGNETISED_BLOCK;
        let distance = event.pageX - this._initialPageX;
        if (this._tasks2Update.length > 1) {
            const nextTasks = this._tasks2Update.filter((t) => t.endDate > task.endDate);
            distance = this.move(event, nextTasks, MoveTypes.RIGHT_MAGNETISED_BLOCK);
            mt = nextTasks.length ? MoveTypes.FREE_BLOCK : mt;
        }

        this._resize(task, distance, 1, mt);
    }

    _resize(task, distance, reduce = 1, moveType) {
        if (!this.isSelected(task)) {
            this.unselectTasks();
        }

        this.$refs.tasks.classList.add("is-updating");

        const bounds = this._tasksBoundsBeforeUpdate.get(task.id);
        const prevWidth =
            getProgressInDay(bounds.endDate, this.currentDate) -
            getProgressInDay(bounds.startDate, this.currentDate);
        const diff = fromPxToPercent(reduce * distance, this._containerWidth);
        const newWidth = Math.max(prevWidth + diff, 0); // width can't be < 0

        if (reduce < 0) {
            this.timelineService.setTaskStartDateDuration(task, newWidth, moveType);
        } else {
            this.timelineService.setTaskEndDateDuration(task, newWidth, moveType);
        }
    }

    onMouseDown(task, event, actionName) {
        this._initialPageX = event.pageX;
        this._containerWidth = this.$refs.tasks.offsetWidth;
        this._tasksBoundsBeforeUpdate = new Map();

        const selectedTasks = this.getSelectedTasks();
        if (selectedTasks.find((t) => t.id === task.id)) {
            // if the user clicked on a task which is part of the selected tasks,
            // we consider applying the changes to come (moving, resizing,...) for all selected tasks
            this._tasks2Update = selectedTasks;

            // Remove the task on the selected one
            if (this._controlKeyPressed) {
                this.$refs[task.id].setState({ isSelected: false });
                this._tasks2Update = this._tasks2Update.filter((t) => t.id !== task.id);

                const hasSelection = this.getSelectedTasks().length;
                this.$refs.tasks.classList.toggle("hasSelection", hasSelection);
            }
        } else if (this._controlKeyPressed) {
            // if the user clicked on a task which is not already selected and ctrl key is pressed,
            // we just add the task to the selected tasks
            selectedTasks.push(task);
            this.selectTasks(selectedTasks);
            this._tasks2Update = selectedTasks;
        } else {
            // if the user clicked on a task which is not already selected,
            // we apply the changes to come (moving, resizing,...) only on that task
            this._tasks2Update = [task];
        }

        this._tasks2Update.forEach((t) => {
            this._tasksBoundsBeforeUpdate.set(t.id, {
                id: t.id,
                startDate: t.startDate,
                endDate: t.endDate,
            });

            this.$refs[t.id].setState({
                isMoving: actionName === Actions.MOVE,
                isSelected: true,
                isUpdating: true,
            });
        });

        this.$emit("hasSelection", Boolean(this._tasks2Update.length));
    }

    onMouseUp(task, event) {
        const hasMoved = event.pageX !== this._initialPageX;
        const isEditing = this.isEditing();
        if (isEditing && !hasMoved) {
            this.$refs[task.id].editTask();
        }

        this.$refs.tasks.classList.remove("is-updating");

        this._tasks2Update.forEach((t) => {
            // Update style
            this.$refs[t.id].setState({
                isMoving: false,
                isUpdating: false,
            });

            this.timelineService.updateTask(t);
        });

        // Update selected tasks
        if (!hasMoved && !this._controlKeyPressed && !isEditing) {
            const selectedTasks = this.getSelectedTasks().filter((t) => t.id !== task.id);
            this.unselectTasks(selectedTasks);
        }

        this._tasksBoundsBeforeUpdate.clear();
        this._tasks2Update = [];
    }

    render() {
        return (
            <div ref="tasks" class="tasks">
                {this.tasks.map((task) => (
                    <Task
                        ref={task.id}
                        key={task.id}
                        task={task}
                        onMouseDown={(e, actionName) => this.onMouseDown(task, e, actionName)}
                        onMouseUp={(e) => this.onMouseUp(task, e)}
                        onMove={this.move}
                        onResizeLeft={(e) => this.resizeLeft(task.id, e)}
                        onResizeRight={(e) => this.resizeRight(task.id, e)}
                    />
                ))}
            </div>
        );
    }
}
</script>

<style lang="scss">
$unselected-task-opacity: 0.6;

.tasks.is-editing .task:not(.is-editing) {
    opacity: $unselected-task-opacity !important;
}

.tasks.hasSelection .task:not(.is-selected),
.tasks.is-updating .task:not(.is-updating) {
    opacity: $unselected-task-opacity !important;
}
</style>
