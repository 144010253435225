<script>
import Component from "vue-class-component";
import Vue from "vue";

import { addDays } from "date-fns";
import { inject } from "../base/Injection";
import { UserService } from "../services/UserService";

import {
    TimelineService,
    MIN_TIMELINE_SCALE_BOUND,
    MAX_TIMELINE_SCALE_BOUND,
} from "../services/TimelineService";

import Timer from "../components/timeline/Timer.vue";
import Timeline from "../components/timeline/Timeline.vue";
import ZoomTimeline from "../components/timeline/ZoomTimeline.vue";
import { format, getProgressInDay } from "../base/Utils";
import { TimerService } from "../services/TimerService";
import BackButton from "../components/common/BackButton.vue";

const Props = Vue.extend({
    props: {
        id: {
            type: String,
        },
    },
});

Component.registerHooks(["beforeRouteLeave"]);

@Component
export default class TimelinePage extends Props {
    @inject(TimelineService) timelineService;
    @inject(TimerService) timerService;
    @inject(UserService) userService;

    currentDate = new Date();
    timelineAutoZoomEnabled = true;
    firstDayOfWeek = 1;
    copied = false;
    tasksHasSelection = false;
    startingHourFixedTimeline = 0;
    endingHourFixedTimeline = 24;

    created() {
        this.backButton =
            this.$route.path.includes("timeline") && this.$route.path.includes("edit");
    }

    mounted() {
        this.init();
        this.initBound = () => this.init(true);
        this.updateBound = () => this.update();

        this.$watch("startDate", this.initBound);
        this.$watch("endDate", this.initBound);
        this.$watch("id", this.initBound);
        this.timelineService.on("change", this.updateBound);
        this.timerService.on("change", this.updateBound);
    }

    destroyed() {
        this.timelineService.off("change", this.updateBound);
        this.timerService.off("change", this.updateBound);
    }

    beforeRouteLeave(to, from, next) {
        const nextPath = to.path;
        this.backButton = nextPath.includes("timeline") && nextPath.includes("edit");
        next();
    }

    async init() {
        let { zoom, scroll, date } = this.timelineService.getUserPreferences();
        this.timelineAutoZoomEnabled = await this.userService.getAutoZoom(true);
        this.firstDayOfWeek = await this.userService.getFirstDayOfWeek(1);
        this.startingHourFixedTimeline = await this.userService.getStartingHourFixedTimeline(0);
        this.endingHourFixedTimeline = await this.userService.getEndingHourFixedTimeline(24);

        zoom = +this.$route.query.zoom || zoom;
        scroll = +this.$route.query.scroll || scroll;
        date = +this.$route.query.date || date || Date.now();

        let startDate;
        let endDate;

        if (this.id) {
            const task = await this.timelineService.getTask(this.id, true);
            if (task) {
                startDate = task.startDate;
                endDate = task.endDate;
                date = startDate;
            }
        }

        // Update zoom
        if (this.timelineAutoZoomEnabled && startDate && endDate) {
            const duration = endDate.getTime() - startDate.getTime();
            zoom = (24 * 60 * 60 * 1000) / duration / 2;
            zoom = Math.min(Math.max(MIN_TIMELINE_SCALE_BOUND, zoom), MAX_TIMELINE_SCALE_BOUND);
        }

        if (this.startingHourFixedTimeline && this.endingHourFixedTimeline) {
            zoom =
                (24 * 60 * 60 * 1000) /
                ((this.endingHourFixedTimeline - this.startingHourFixedTimeline + 1) *
                    60 *
                    60 *
                    1000);
        }

        // Default zoom value
        if (!zoom) {
            zoom = 4.5;
        }

        if (this.$refs.zoomTimeline) {
            this.$refs.zoomTimeline.updateScaleValue(zoom);
        }
        this.$refs.timeline.setZoom(zoom);

        // Update scroll
        if (this.startingHourFixedTimeline && this.endingHourFixedTimeline) {
            const fixedDate = new Date(date);
            fixedDate.setHours(
                (this.startingHourFixedTimeline + this.endingHourFixedTimeline) / 2 -
                    (this.endingHourFixedTimeline - this.startingHourFixedTimeline) / 2 -
                    1 // FIXME Timezone ?
            );

            scroll =
                getProgressInDay(fixedDate, date) * this.$refs.timeline.getInverseLimitScroll();
        } else {
            if (startDate && endDate) {
                const duration = endDate.getTime() - startDate.getTime();

                const dayTime = (24 * 60 * 60 * 1000) / zoom;
                const middle = (dayTime - duration) / 2;

                scroll =
                    getProgressInDay(new Date(startDate.getTime() - middle), date) *
                    this.$refs.timeline.getInverseLimitScroll();
            }
        }

        // Default scoll value
        if (!scroll) {
            const dayTime = (24 * 60 * 60 * 1000) / zoom;
            const middle = dayTime / 2;

            scroll =
                getProgressInDay(new Date(Date.now() - middle), date) *
                this.$refs.timeline.getInverseLimitScroll();
        }
        if (scroll < 0) {
            scroll = 0;
        }

        this.$refs.timeline.setScroll(scroll);

        this.zoom = zoom;
        this.scroll = scroll;
        this.currentDate = date;
        this.timelineService.setUserPreferences({
            zoom,
            scroll,
            date,
        });

        this.update();
    }

    onZoom(value) {
        this.zoom = value;
        this.timelineService.setUserPreferences({ zoom: value });

        this.$refs.zoomTimeline.updateScaleValue(value);
        this.updateUrl();
    }

    onScroll(value, ended) {
        this.scroll = value;
        this.timelineService.setUserPreferences({ scroll: value });

        if (ended) {
            this.updateUrl();
        }
    }

    updateDate(value) {
        this.setDate(addDays(+this.currentDate, value));
    }

    setNowDate() {
        this.setDate(Date.now());
    }

    setDate(date) {
        this.currentDate = date;
        this.timelineService.setUserPreferences({ date: this.currentDate });
        this.update();
        this.updateUrl();
    }

    async addTask() {
        const id = await this.timelineService.createTask();
        this.$router.push(`/timeline/edit/${id}`).catch(() => {});
    }

    updateUrl() {
        const url = `/timeline?date=${+this.currentDate}&scroll=${this.scroll}&zoom=${this.zoom}`;
        if (Object.keys(this.$route.params).length !== 0) {
            this.$router.push(url).catch(() => {});
        } else {
            this.$router.replace(url).catch(() => {});
        }
    }

    duplicateTasks() {
        const copy = !this.copied;
        this.$refs.timeline.duplicateTasks(copy);
    }

    onCopyTasks() {
        this.copied = true;
    }

    onPasteTasks() {
        this.copied = false;
    }

    async update() {
        this.$refs.timeline.update();
        this.$refs.tasksList.update();
    }

    selectDate(date) {
        const currentDay = new Date(this.currentDate).setHours(0, 0, 0, 0);
        const diffInDays = Math.trunc((date - currentDay) / (1000 * 3600 * 24));
        this.updateDate(diffInDays);
    }

    renderTimelineZoomControls() {
        let fixedTimeline =
            this.startingHourFixedTimeline && this.endingHourFixedTimeline
                ? this.startingHourFixedTimeline + "h - " + this.endingHourFixedTimeline + "h"
                : null;
        return (
            <div class="level-right mr-5">
                <ZoomTimeline
                    class="level-item"
                    ref="zoomTimeline"
                    fixedTimeline={fixedTimeline}
                    onZoomTimeline={(scale) => {
                        this.$refs.timeline.setZoom(scale);
                        this.onZoom(scale);
                    }}
                />
            </div>
        );
    }

    render() {
        const date = new Date(+this.currentDate);
        return (
            <div class="page-content">
                <div class="page-title">
                    <BackButton
                        class={`backButton ${this.backButton ? "" : "is-hidden"}`}
                        cb={() => {
                            history.back();
                        }}
                    />
                    {this.$t("TimelinePage.title")}
                    <Timer />
                </div>
                <div class="header level">
                    <div class="level-left ml-5">
                        <div
                            class="level-item"
                            onclick={() => {
                                this.updateDate(-1);
                            }}
                        >
                            <i class="fa fa-angle-left fa-2x"></i>
                        </div>
                        <div class="level-item">
                            <b-field grouped>
                                <b-datepicker
                                    ref="datePicker"
                                    value={new Date(this.currentDate)}
                                    onInput={(date) => this.selectDate(date)}
                                    first-day-of-week={this.firstDayOfWeek}
                                >
                                    <template slot="trigger">
                                        <strong class="date">{format(date, "EEE dd MMMM")}</strong>
                                    </template>
                                </b-datepicker>
                            </b-field>
                        </div>
                        <div
                            class="level-item"
                            onclick={() => {
                                this.updateDate(1);
                            }}
                        >
                            <i class="fa fa-angle-right fa-2x"></i>
                        </div>
                        <div class="level-item">
                            <a
                                class="button is-primary is-outlined is-rounded"
                                onclick={this.setNowDate}
                            >
                                <div class="icon mr-1">
                                    <i class="fa fa-calendar"></i>
                                </div>
                                {this.$t("TimelinePage.now")}
                            </a>
                            <a
                                class="button is-primary is-outlined is-rounded ml-2"
                                onclick={this.addTask}
                            >
                                <div class="icon mr-1">
                                    <i class="fa fa-plus"></i>
                                </div>
                                {this.$t("TimelinePage.task")}
                            </a>
                            <a
                                class="button is-primary is-outlined is-rounded ml-2"
                                onclick={this.duplicateTasks}
                                disabled={!this.tasksHasSelection && !this.copied}
                            >
                                <div class="icon mr-1">
                                    <i class={`fa fa-${this.copied ? "paste" : "copy"}`}></i>
                                </div>
                                {this.copied
                                    ? this.$t("TimelinePage.paste")
                                    : this.$t("TimelinePage.copy")}
                            </a>
                        </div>
                    </div>
                    <div class="level-middle"></div>
                    {this.renderTimelineZoomControls()}
                </div>
                <Timeline
                    ref="timeline"
                    onZoom={this.onZoom}
                    onScroll={this.onScroll}
                    onCopyTasks={this.onCopyTasks}
                    onPasteTasks={this.onPasteTasks}
                    onHasSelection={(hasSelection) => (this.tasksHasSelection = hasSelection)}
                />
                <router-view ref="tasksList" onupdate={this.update}></router-view>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.header {
    border-bottom: solid 1px #d2dae2;
    padding-bottom: 1.5rem;
}

.level-item {
    cursor: pointer;
}

.date {
    width: 9rem;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

strong {
    color: #4a4a4a;
}
</style>
