<script>
import Component from "vue-class-component";
import Vue from "vue";

import { inject } from "../base/Injection";
import { ProjectService } from "../services/ProjectService";

import ColorsPicker from "../components/common/ColorsPicker.vue";
import PaginatedTable from "../components/common/PaginatedTable.vue";

@Component
export default class ProjectsPage extends Vue {
    @inject(ProjectService) projectService;

    mounted() {
        this.projectService.on("change", this.$refs.table.loadData);
    }

    beforeDestroy() {
        this.projectService.off("change", this.$refs.table.loadData);
    }

    async getProjects(selector) {
        const projects = await this.projectService.getProjects(selector);
        return projects;
    }

    editProject({ id }) {
        this.$router
            .push({
                path: `/projects/edit/${id}`,
            })
            .catch(() => {});
    }

    async createProject() {
        if (this.$refs.name.newValue) {
            try {
                this.projectService.createProject({
                    name: this.$refs.name.newValue,
                    color: this.$refs.color.getValue(),
                });
                this.$refs.name.newValue = "";
            } catch {
                this.$buefy.toast.open({
                    message: this.$t("error.server"),
                    type: "is-danger",
                });
            }
        }
    }

    render() {
        return (
            <div class="page-content">
                <div class="page-title">{this.$t("ProjectsPage.title")}</div>
                <div class="page-full page-table">
                    <div class="field-container">
                        <div class="field-inline">
                            <ColorsPicker ref="color" />

                            <div class="fixed">
                                <b-input
                                    ref="name"
                                    ellipsis
                                    icon="folder"
                                    placeholder={this.$t("ProjectsPage.name-placeholder")}
                                ></b-input>
                            </div>
                            <button
                                class="button is-primary is-rounded is-outlined"
                                onclick={this.createProject}
                            >
                                {this.$t("ProjectsPage.submit")}
                            </button>
                        </div>

                        <PaginatedTable
                            ref="table"
                            fetchData={this.getProjects}
                            onOpen={this.editProject}
                            defaultSort="name_asc"
                        >
                            <b-table-column
                                label={this.$t("ProjectsPage.name")}
                                field="name"
                                sortable
                                scopedSlots={{
                                    default: ({ row: project }) => (
                                        <span class={`tag color-${project.color}`}>
                                            {project.name}
                                        </span>
                                    ),
                                }}
                            />

                            <b-table-column
                                label={this.$t("ProjectsPage.permission")}
                                field="permission"
                                centered
                                sortable
                                scopedSlots={{
                                    default: ({ row: project }) => (
                                        <span class="is-tag">{project.permission}</span>
                                    ),
                                }}
                            />
                        </PaginatedTable>
                    </div>
                </div>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
td {
    vertical-align: middle;
}
</style>
