<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { UserService } from "../../services/UserService";

@Component
export default class PasswordConfirmation extends Vue {
    @inject(UserService) userService;

    confirmationType = "";
    confirmationMessage = "";
    passwordType = "";
    passwordMessage = "";

    isValid() {
        let isValid = Object.values(this.$refs).reduce((acc, node) => {
            return (!node.checkHtml5Validity || node.checkHtml5Validity()) && acc;
        }, true);

        isValid = isValid && this.checkPassword();
        return isValid;
    }

    getValue() {
        return this.$refs.password.newValue;
    }

    checkPassword() {
        this.passwordType = "";
        this.passwordMessage = "";

        this.confirmationType = "";
        this.confirmationMessage = "";

        if (!this.$refs.password.newValue.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/)) {
            this.passwordType = "is-danger";
            this.passwordMessage = this.$t("PasswordConfirmation.message");
        } else if (this.$refs.password.newValue !== this.$refs.confirmation.newValue) {
            this.confirmationType = "is-danger";
            this.confirmationMessage = this.$t("PasswordConfirmation.confirm-message");
        }

        return this.confirmationType === "" && this.passwordType === "";
    }

    focus() {
        this.$refs.password.focus();
    }

    render() {
        return (
            <div>
                <b-field
                    label={this.$t("PasswordConfirmation.password")}
                    type={this.passwordType}
                    message={this.passwordMessage}
                >
                    <b-input
                        ref="password"
                        type="password"
                        required
                        placeholder={this.$t("PasswordConfirmation.placeholder")}
                        oninput={this.checkPassword}
                    ></b-input>
                </b-field>
                <b-field
                    label={this.$t("PasswordConfirmation.confirm")}
                    type={this.confirmationType}
                    message={this.confirmationMessage}
                >
                    <b-input
                        ref="confirmation"
                        type="password"
                        required
                        placeholder={this.$t("PasswordConfirmation.confirm-placeholder")}
                        oninput={this.checkPassword}
                    ></b-input>
                </b-field>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.tab-forms {
    box-shadow: 0 0 0 1px #e5e5e5;
    border-bottom-right-radius: 0.25rem;
    border-radius: 0.25rem;
}
</style>
