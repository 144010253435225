<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { ProjectService } from "../../services/ProjectService";

const Props = Vue.extend({
    props: {
        value: {
            type: Object,
        },
    },
});

@Component
export default class ProjectSelector extends Props {
    @inject(ProjectService) projectService;

    selectedProjects = [];

    async onSelectProjectName(name) {
        this.selectedProjects = (await this.projectService.searchProjects(name)) || [];
    }

    emitSelect() {
        this.$emit("select", this.$refs.project.selected);
    }

    getSelectedProject() {
        return this.$refs.project.selected;
    }

    render() {
        return (
            <b-autocomplete
                ref="project"
                placeholder={this.$t("ProjectSelector.placeholder")}
                keep-first
                clearable
                data={this.selectedProjects}
                oninput={this.onSelectProjectName}
                onselect={this.emitSelect}
                field="name"
                required
                value={this.value}
            >
                <template slot="empty">{this.$t("ProjectSelector.empty")}</template>
            </b-autocomplete>
        );
    }
}
</script>
