<script>
import Component from "vue-class-component";
import Vue from "vue";
import { COLLABORATOR_TYPE, ProjectService } from "../../services/ProjectService";
import { inject } from "../../base/Injection";
import CollaboratorProfile from "./CollaboratorProfile.vue";
import { ReportService } from "../../services/ReportService";

const Props = Vue.extend({
    props: {
        reportId: String,
        projectIds: Array,
        selectedCollaborators: Array,
        enabled: Boolean,
    },
});

@Component
export default class CollaboratorList extends Props {
    @inject(ProjectService) projectService;
    @inject(ReportService) reportService;

    collaborators = [];
    newSelectedCollaborators = [];
    fetching = false;

    async created() {
        this.init();

        this.initBound = () => this.init();
        this.projectService.on("change", this.initBound);
    }

    destroyed() {
        this.projectService.off("change", this.initBound);
    }

    async init() {
        try {
            this.collaborators = [];
            this.fetching = true;

            const everyone = this.reportId
                ? await this.reportService.getCollaborators(this.reportId, this.projectIds)
                : await this.projectService.getCollaborators(this.projectIds);
            this.collaborators = [
                ...(everyone?.users?.map((u) => {
                    u.type = COLLABORATOR_TYPE.USER;
                    u.selected =
                        this.selectedCollaborators?.some((c) => c.id === u.id) ||
                        this.selectedCollaborators?.includes(u.id);
                    return u;
                }) || []),
                ...(everyone?.teams?.map((t) => {
                    t.type = COLLABORATOR_TYPE.TEAM;
                    t.selected =
                        this.selectedCollaborators?.some((c) => c.id === t.id) ||
                        this.selectedCollaborators?.includes(t.id);
                    return t;
                }) || []),
            ];

            this.newSelectedCollaborators = this.collaborators.filter((c) => c.selected);
        } catch (e) {
            console.log(e);
            this.collaborators = [];
        } finally {
            this.fetching = false;
        }
    }

    updateSelectedCollaborators(collab) {
        const index = this.newSelectedCollaborators.findIndex((c) => c.id === collab.id);
        if (index === -1) {
            this.newSelectedCollaborators.push(collab);
        } else {
            this.newSelectedCollaborators.splice(index, 1);
        }
    }

    getSelectedCollaborators() {
        return this.newSelectedCollaborators;
    }

    render() {
        if (this.collaborators?.length === 0) {
            return <div class="collaboratorList">{this.$t("CollaboratorList.empty")}</div>;
        }

        return (
            <div class="collaboratorList">
                {this.collaborators.map((collab) => (
                    <CollaboratorProfile
                        enabled={this.enabled}
                        collaborator={collab}
                        onSelect={this.updateSelectedCollaborators}
                    />
                ))}
            </div>
        );
    }
}
</script>
