<script>
import Component from "vue-class-component";
import Vue from "vue";
import BackButton from "../components/common/BackButton.vue";
import {
    DEFAULT_DAY_DURATION,
    Report,
    ReportService,
    REPORT_FREQUENCY,
} from "../services/ReportService";
import ReportPeriod from "../components/report/ReportPeriod.vue";
import EmptyTable from "../components/common/EmptyTable.vue";
import ValidationSheetGauge from "../components/report/ValidationSheetGauge.vue";
import { formatDuration, timeStringToSeconds } from "../base/Utils";
import InlineInput from "../components/common/InlineInput.vue";
import { inject } from "../base/Injection";
import ProfileIcon from "../components/common/ProfileIcon.vue";
import { COLLABORATOR_TYPE } from "../services/ProjectService";
import { differenceInDays } from "date-fns";

const ROUND_0m = 0;
const ROUND_5m = 5 * 60;
const ROUND_15m = 15 * 60;
const ROUND_30m = 30 * 60;
const ROUND_60m = 60 * 60;

@Component
export default class ValidationSheetPage extends Vue {
    @inject(ReportService) reportService;

    reportedTasks = {};
    tasks = [];

    report = new Report();
    startDate = new Date();
    endDate = new Date();

    displayPerDay = false;

    created() {
        this.startDate = this.$route.query.startDate
            ? new Date(+this.$route.query.startDate)
            : new Date();
        this.endDate = this.$route.query.endDate
            ? new Date(+this.$route.query.endDate)
            : new Date();

        this.init();

        this.getTasksBound = () => this.getTasks(this.startDate, this.endDate);
        this.reportService.on("change", this.getTasksBound);
    }

    destroyed() {
        this.reportService.off("change", this.getTasksBound);
    }

    async init() {
        const id = this.$route.params.id;

        try {
            this.fetching = true;
            const request = await this.reportService.getRequest(id);

            this.report = request?.report || new Report();

            if (this.report.frequency === REPORT_FREQUENCY.CUSTOM) {
                this.startDate = new Date(this.report.startDate);
                this.endDate = new Date(this.report.endDate);
            }

            await this.getTasks();
        } catch {
            this.report = new Report();
        } finally {
            this.fetching = false;
        }
    }

    async getTasks() {
        if (!this.startDate && !this.endDate) {
            return;
        }

        try {
            this.reportedTasks = await this.reportService.getReportedTasks(
                this.report,
                this.startDate,
                this.endDate
            );

            this.tasks = [
                ...(this.reportedTasks.validated || this.reportedTasks.reported || []),
            ].map((task) => {
                const haveIssue =
                    this.report.synchronizable &&
                    (!task.tags || task.tags.every((tag) => tag[0] !== "#"));

                return {
                    ...task,
                    haveIssue,
                    duration: haveIssue ? 0 : task.duration,
                };
            });
        } catch (e) {
            console.error(e);
            this.reportedTasks = {};
            this.tasks = [];
        }
    }

    async updateDates(startDate, endDate) {
        if (!this.report.id || (+this.startDate === +startDate && +this.endDate === +endDate)) {
            return;
        }

        this.startDate = startDate;
        this.endDate = endDate;
        await this.getTasks();

        this.$router
            .replace(
                `/requests/${this.$route.params.id}?startDate=${+startDate}&endDate=${+endDate}`
            )
            .catch(() => {});
    }

    back() {
        this.$router.push("/requests");
    }

    validate() {
        const properties = this.reportedTasks.validated?.length
            ? {
                  title: this.$t("ValidationSheetPage.reconfirm.title"),
                  message: this.$t("ValidationSheetPage.reconfirm.message"),
                  confirmText: this.$t("ValidationSheetPage.reconfirm.confirmText"),
              }
            : {
                  title: this.$t("ValidationSheetPage.confirm.title"),
                  message: this.$t("ValidationSheetPage.confirm.message"),
                  confirmText: this.$t("ValidationSheetPage.confirm.confirmText"),
              };

        this.$buefy.dialog.confirm({
            ...properties,
            type: "is-danger",
            hasIcon: true,
            onConfirm: async () => {
                try {
                    this.reportService.saveReportValidation(
                        this.report.id,
                        this.startDate,
                        this.endDate,
                        this.tasks
                    );
                    this.$buefy.toast.open({
                        message: this.$t("ValidationSheetPage.validation-message"),
                        type: "is-success",
                    });
                } catch {
                    this.$buefy.toast.open({
                        message: this.$t("ValidationSheetPage.validation-error"),
                        type: "is-danger",
                    });
                }
            },
        });
    }

    async sync() {
        const properties = this.reportedTasks.validated?.length
            ? {
                  title: this.$t("ValidationSheetPage.resync.title"),
                  message: this.$t("ValidationSheetPage.resync.message"),
                  confirmText: this.$t("ValidationSheetPage.resync.confirmText"),
              }
            : {
                  title: this.$t("ValidationSheetPage.sync.title"),
                  message: this.$t("ValidationSheetPage.sync.message"),
                  confirmText: this.$t("ValidationSheetPage.sync.confirmText"),
              };

        this.$buefy.dialog.confirm({
            ...properties,
            type: "is-danger",
            hasIcon: true,
            onConfirm: async () => {
                const loadingComponent = this.$buefy.loading.open();
                try {
                    await this.reportService.syncReportValidation(
                        this.report.id,
                        this.startDate,
                        this.endDate,
                        this.tasks
                    );
                    this.$buefy.toast.open({
                        message: this.$t("ValidationSheetPage.synchronization-message"),
                        type: "is-success",
                    });
                } catch {
                    this.$buefy.toast.open({
                        message: this.$t("ValidationSheetPage.synchronization-error"),
                        type: "is-danger",
                    });
                } finally {
                    loadingComponent.close();
                }
            },
        });
    }

    updateTaskDuration(task, input) {
        if (!input) {
            return false;
        }
        const elements = input.split(":");
        if (
            elements.length !== 3 ||
            elements[1].length !== 2 ||
            elements[0].length < 2 ||
            elements[2].length !== 2
        ) {
            return false;
        }

        let total = 0;
        for (let position = 0, limit = 2; position < elements.length; position++) {
            const timeUnit = parseInt(elements[position]);
            if (isNaN(timeUnit)) {
                return false;
            }
            total = total + timeUnit * Math.pow(60, limit - position);
        }

        task.initDuration = task.initDuration || task.duration;
        task.duration = total;
        return true;
    }

    resetTaskDuration(task) {
        task.duration = task.initDuration || task.duration;
    }

    // FIXME SMAI : move this optimizing logic into a service
    async optimizeTimes(round) {
        const workedDay = await this.reportService.getReportChart(
            "total_days",
            this.report.id,
            this.$refs.period.getStartDate(),
            this.$refs.period.getEndDate()
        );

        const hoursPerDay = this.report.durationDisplayModeHoursPerDay
            ? timeStringToSeconds(this.report.durationDisplayModeHoursPerDay)
            : DEFAULT_DAY_DURATION;

        const totalHours = workedDay * hoursPerDay;
        const tasksDuration = this.tasks.reduce((duration, task) => {
            const taskDuration = task.initDuration || task.duration;
            return taskDuration + duration;
        }, 0);

        const omittedTasks = [];
        let hoursDurationLeft = totalHours;

        this.tasks.forEach((task) => {
            task.initDuration = task.initDuration || task.duration;

            const duration =
                task.initDuration +
                (task.initDuration / tasksDuration) * (totalHours - tasksDuration);

            // If a rounding step has been defined, we try to round the task duration to the given step.
            // Otherwize, we just use the lastly computed duration
            const roundedDuration = round > 0 ? Math.round(duration / round) * round : duration;
            const finalDuration = Math.min(hoursDurationLeft, roundedDuration); // Avoid overpassing the day budget

            hoursDurationLeft -= finalDuration;
            task.duration = finalDuration;

            // If a meaningful (= initial duration is > 0) rounded task has no duration we keep track of it,
            // in case we don't match the day budget once all tasks have been resized
            if (duration > 0 && task.duration === 0) {
                omittedTasks.push(task);
            }
        });

        // If the day budget is not reached, we try to use omitted task to match it
        if (hoursDurationLeft > 0 && omittedTasks.length) {
            omittedTasks.forEach((task) => {
                const roundedDuration = Math.min(hoursDurationLeft, round);
                task.duration = roundedDuration;
                hoursDurationLeft -= roundedDuration;
            });
        }

        // If the day budget is still not reached, we resize the last meaningful task so that it fits the gap
        if (hoursDurationLeft > 0) {
            const lastTask = this.tasks.filter((t) => t.duration > 0).pop();
            if (lastTask) {
                lastTask.duration += hoursDurationLeft;
            }
        }
    }

    resetTimes() {
        this.tasks = [...(this.reportedTasks.reported || [])].map((task) => {
            const haveIssue =
                this.report.synchronizable &&
                (!task.tags || task.tags.every((tag) => tag[0] !== "#"));

            return {
                ...task,
                haveIssue,
                duration: haveIssue ? 0 : task.duration,
            };
        });
    }

    renderProjectNames() {
        return this.report.projects?.map((p) => {
            return <span class={`tag color-${p.color}`}>{p.name}</span>;
        });
    }

    renderProjectTags() {
        return this.report.tags?.map((t) => {
            return <span class={`tag is-primary is-rounded`}>{t}</span>;
        });
    }

    render() {
        return (
            <div class="page-content ValidationSheetPage">
                <div class="page-title">
                    <BackButton cb={() => this.back()} />
                    {this.$t("ValidationSheetPage.title", { name: this.report.name })}
                    <span class="page-desc">{this.report.description}</span>
                </div>
                <div class="page-full">
                    <div class="field-container">
                        <ReportPeriod
                            class="is-size-4 has-text-primary"
                            key={`period-${this.report.frequency}}`}
                            noFrequency
                            frequency={
                                this.displayPerDay ? REPORT_FREQUENCY.DAY : this.report.frequency
                            }
                            ref="period"
                            onchange={(startDate, endDate) => this.updateDates(startDate, endDate)}
                            start={this.startDate}
                            end={this.endDate}
                        />

                        <div class="ValidationSheetPage-container">
                            <div class="ValidationSheetPage-desc field">
                                <span class="ValidationSheetPage-label">
                                    {this.$t("ValidationSheetPage.asked-by")}
                                </span>
                                <ProfileIcon
                                    name={this.report.owner?.name}
                                    avatar={this.report.owner?.avatar}
                                    height={32}
                                    type={COLLABORATOR_TYPE.USER}
                                    class="mr-2"
                                />
                                {this.report.owner?.name}
                            </div>

                            <div class="ValidationSheetPage-desc field">
                                {differenceInDays(this.startDate, this.endDate) > 1 && (
                                    <b-checkbox
                                        oninput={(value) => (this.displayPerDay = value)}
                                        value={this.displayPerDay}
                                    >
                                        {this.$t("ValidationSheetPage.displayPerDay")}
                                    </b-checkbox>
                                )}
                            </div>
                            <div class="has-text-centered mb-2">
                                <div
                                    class={{
                                        tag: true,
                                        "is-warning": true,
                                        "is-hidden": !this.reportedTasks.status,
                                    }}
                                >
                                    {this.$t(`ValidationSheetPage.${this.reportedTasks.status}`)}
                                </div>
                            </div>

                            <div class="ValidationSheetPage-field field">
                                <span class="ValidationSheetPage-label">
                                    {this.$t("ValidationSheetPage.projects")}
                                </span>
                                <p class="tags">{this.renderProjectNames()}</p>
                            </div>
                            <div class="ValidationSheetPage-field field">
                                <span class="ValidationSheetPage-label">
                                    {this.$t("ValidationSheetPage.tags")}
                                </span>
                                <p class="tags">{this.renderProjectTags()}</p>
                            </div>

                            <ValidationSheetGauge
                                class="ValidationSheetPage-gauge"
                                tasks={this.tasks}
                                startDate={this.startDate}
                                endDate={this.endDate}
                                frequency={
                                    this.displayPerDay
                                        ? REPORT_FREQUENCY.DAY
                                        : this.report.frequency
                                }
                                key={`gauge-${this.report.frequency}-${this.startDate}`}
                                hoursPerDay={this.report.durationDisplayModeHoursPerDay}
                                ref="gauge"
                            />
                        </div>
                        <div class="ValidationSheetPage-table">
                            <b-table
                                data={this.tasks}
                                default-sort={"title"}
                                default-sort-direction={"asc"}
                                sort-icon="chevron-up"
                                sort-icon-size="is-small"
                                hoverable
                                paginated
                                pagination-size="is-small"
                                per-page="6"
                                aria-next-label={this.$t("ValidationSheetPage.pagination.next")}
                                aria-previous-label={this.$t(
                                    "ValidationSheetPage.pagination.previous"
                                )}
                                aria-current-label={this.$t(
                                    "ValidationSheetPage.pagination.current"
                                )}
                            >
                                <EmptyTable
                                    text={this.$t("ValidationSheetPage.empty")}
                                    visible={this.tasks?.length === 0}
                                />

                                <b-table-column
                                    label={this.$t("ValidationSheetPage.project")}
                                    field="projectName"
                                    sortable
                                    searchable
                                    width="250"
                                    scopedSlots={{
                                        default: ({ row: task }) => {
                                            return (
                                                <span
                                                    class={{
                                                        tag: true,
                                                        [`color-${task.projectColor}`]: true,
                                                    }}
                                                >
                                                    {task.projectName}
                                                </span>
                                            );
                                        },
                                    }}
                                />
                                <b-table-column
                                    label={this.$t("ValidationSheetPage.task")}
                                    field="title"
                                    sortable
                                    searchable
                                    scopedSlots={{
                                        default: ({ row: task }) => {
                                            return (
                                                <span class={{ "have-issue": task.haveIssue }}>
                                                    {task.title}
                                                </span>
                                            );
                                        },
                                    }}
                                />
                                <b-table-column
                                    label={this.$t("ValidationSheetPage.tags")}
                                    field="tags"
                                    searchable
                                    scopedSlots={{
                                        default: ({ row: task }) => {
                                            const tags = task.tags?.map((t) => {
                                                return (
                                                    <span class={`tag is-primary is-rounded`}>
                                                        {t}
                                                    </span>
                                                );
                                            });
                                            return <p class="tags">{tags}</p>;
                                        },
                                    }}
                                />
                                <b-table-column
                                    label={this.$t("ValidationSheetPage.duration")}
                                    field="duration"
                                    width="175"
                                    sortable
                                    scopedSlots={{
                                        default: ({ row: task }) => {
                                            return (
                                                <InlineInput
                                                    value={formatDuration(task.duration)}
                                                    updateCb={(value) => {
                                                        return this.updateTaskDuration(task, value);
                                                    }}
                                                />
                                            );
                                        },
                                    }}
                                />
                                <b-table-column
                                    width="10"
                                    sortable
                                    scopedSlots={{
                                        default: ({ row: task }) => {
                                            return task.haveIssue ? (
                                                ""
                                            ) : (
                                                <div class="is-flex">
                                                    <b-button
                                                        type="is-primary"
                                                        size="is-small"
                                                        icon-right="times"
                                                        class="mr-1"
                                                        onclick={() => {
                                                            return this.updateTaskDuration(
                                                                task,
                                                                "00:00:00"
                                                            );
                                                        }}
                                                    />
                                                    <b-button
                                                        type="is-primary"
                                                        size="is-small"
                                                        icon-right="history"
                                                        onclick={() => {
                                                            return this.resetTaskDuration(task);
                                                        }}
                                                    />
                                                </div>
                                            );
                                        },
                                    }}
                                />
                            </b-table>
                        </div>
                        <div class="buttons is-right ValidationSheetPage-buttons mt-2">
                            <button
                                class="button is-danger is-outlined is-rounded mt-05"
                                onclick={() => this.back()}
                            >
                                <span class="icon">
                                    <i class="fa fa-arrow-left"></i>
                                </span>
                                <span>{this.$t("ValidationSheetPage.cancel")}</span>
                            </button>
                            <b-dropdown aria-role="list">
                                <button
                                    class="button is-primary is-outlined is-rounded mt-05"
                                    slot="trigger"
                                >
                                    <span class="icon">
                                        <i class="fa fa-magic"></i>
                                    </span>
                                    <span>{this.$t("ValidationSheetPage.optimize")}</span>
                                </button>

                                <b-dropdown-item
                                    aria-role="listitem"
                                    onclick={() => this.optimizeTimes(ROUND_0m)}
                                >
                                    {this.$t("ValidationSheetPage.round-0m")}
                                </b-dropdown-item>
                                <b-dropdown-item
                                    aria-role="listitem"
                                    onclick={() => this.optimizeTimes(ROUND_5m)}
                                >
                                    {this.$t("ValidationSheetPage.round-5m")}
                                </b-dropdown-item>
                                <b-dropdown-item
                                    aria-role="listitem"
                                    onclick={() => this.optimizeTimes(ROUND_15m)}
                                >
                                    {this.$t("ValidationSheetPage.round-15m")}
                                </b-dropdown-item>
                                <b-dropdown-item
                                    aria-role="listitem"
                                    onclick={() => this.optimizeTimes(ROUND_30m)}
                                >
                                    {this.$t("ValidationSheetPage.round-30m")}
                                </b-dropdown-item>
                                <b-dropdown-item
                                    aria-role="listitem"
                                    onclick={() => this.optimizeTimes(ROUND_60m)}
                                >
                                    {this.$t("ValidationSheetPage.round-60m")}
                                </b-dropdown-item>
                            </b-dropdown>
                            <button
                                class="button is-primary is-outlined is-rounded mt-05"
                                onclick={() => this.resetTimes()}
                            >
                                <span class="icon">
                                    <i class="fa fa-calendar"></i>
                                </span>
                                <span>{this.$t("ValidationSheetPage.reset")}</span>
                            </button>
                            {!this.report.synchronizable && (
                                <button
                                    class="button is-primary is-outlined is-rounded mt-05"
                                    onclick={() => this.validate()}
                                >
                                    <span class="icon">
                                        <i class="fa fa-check"></i>
                                    </span>
                                    <span>
                                        {this.$t(
                                            this.reportedTasks.validated?.length
                                                ? "ValidationSheetPage.revalidate"
                                                : "ValidationSheetPage.validate"
                                        )}
                                    </span>
                                </button>
                            )}
                            {this.report.synchronizable && (
                                <button
                                    class="button is-primary is-outlined is-rounded mt-05"
                                    onclick={() => this.sync()}
                                >
                                    <span class="icon">
                                        <i class="fa fa-check"></i>
                                    </span>
                                    <span>
                                        {this.$t(
                                            this.reportedTasks.validated?.length
                                                ? "ValidationSheetPage.resynchronize"
                                                : "ValidationSheetPage.synchronize"
                                        )}
                                    </span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
@import "../../css/variables";

.ValidationSheetPage {
    .field-container {
        position: relative;
    }
}

.ValidationSheetPage-container {
    margin: 0 1.5rem;
}

.ValidationSheetPage-label {
    font-weight: 700;
    font-size: 18px;
    margin-right: 0.5rem;
}

.ValidationSheetPage-desc {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .ValidationSheetPage-label {
        font-size: 14px;
    }
}

.ValidationSheetPage-field {
    display: flex;
    align-items: center;
}

.ValidationSheetPage-table {
    .b-table {
        height: auto;
    }
}

.ValidationSheetPage-buttons {
    margin: 0 1.5rem;
}

.have-issue {
    text-decoration: line-through;
}
</style>
