var render, staticRenderFns
import script from "./TaskForm.vue?vue&type=script&lang=js&"
export * from "./TaskForm.vue?vue&type=script&lang=js&"
import style0 from "./TaskForm.vue?vue&type=style&index=0&id=55c19b9b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55c19b9b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55c19b9b')) {
      api.createRecord('55c19b9b', component.options)
    } else {
      api.reload('55c19b9b', component.options)
    }
    
  }
}
component.options.__file = "src/js/components/timeline/TaskForm.vue"
export default component.exports