<script>
import Component from "vue-class-component";
import Vue from "vue";

import { inject } from "../base/Injection";
import { ProjectService } from "../services/ProjectService";
import TasksProject from "../components/project/TasksProject";
import TagsProject from "../components/project/TagsProject";
import UsersProject from "../components/project/UsersProject";
import TeamsProject from "../components/project/TeamsProject";
import AdvancedProject from "../components/project/AdvancedProject";
import BackButton from "../components/common/BackButton.vue";
import EditableInput from "../components/common/EditableInput.vue";
import ColorsPicker from "../components/common/ColorsPicker.vue";
import { TimelineService } from "../services/TimelineService";

@Component
export default class EditProjectPage extends Vue {
    @inject(ProjectService) projectService;
    @inject(TimelineService) timelineService;

    project = {};

    async created() {
        const id = this.$route.params.id;
        this.project = await this.projectService.getProjectById(id);
    }

    async save({ name, color }, target) {
        this.project = {
            ...this.project,
            name: name || this.project.name,
            color: color || this.project.color,
        };

        try {
            await this.projectService.saveProject({
                id: this.project.id,
                name: this.project.name,
                color: this.project.color,
            });
            await this.timelineService.cleanCache();
        } catch {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
            target && target.cancel();
        }
    }

    onChangeTab(position) {
        const query = { position };
        this.$router.replace({ query });
    }

    render() {
        if (!this.project) {
            return;
        }
        const isMaintainer = this.project.permission === "maintainer";

        let currentPosition = parseInt(this.$route.query.position) || 0;
        if (this.$route.query.state) {
            currentPosition = 4;
        }

        return (
            <div class="page-content">
                <div class="page-title">
                    <BackButton cb={() => this.$router.push("/projects")} />
                    {this.$t("EditProjectPage.title")}
                    <ColorsPicker
                        class="picker"
                        value={this.project.color}
                        disabled={!isMaintainer}
                        onChange={(value) => this.save({ color: value })}
                    />
                    <EditableInput
                        type="title"
                        value={this.project.name}
                        disabled={!isMaintainer}
                        onChange={(value, target) => this.save({ name: value }, target)}
                    />
                </div>

                <div class="page-full">
                    <b-tabs
                        animated={false}
                        destroy-on-hide={true}
                        expanded={true}
                        value={currentPosition}
                        onInput={this.onChangeTab}
                    >
                        <b-tab-item label={this.$t("EditProjectPage.tasks")} icon="tasks">
                            <TasksProject project={this.project} />
                        </b-tab-item>
                        <b-tab-item label={this.$t("EditProjectPage.tags")} icon="tag">
                            <TagsProject project={this.project} />
                        </b-tab-item>
                        <b-tab-item
                            label={this.$t("EditProjectPage.users")}
                            icon="user"
                            visible={isMaintainer}
                        >
                            <UsersProject project={this.project} />
                        </b-tab-item>
                        <b-tab-item
                            label={this.$t("EditProjectPage.teams")}
                            icon="users"
                            visible={isMaintainer}
                        >
                            <TeamsProject project={this.project} />
                        </b-tab-item>
                        <b-tab-item
                            label={this.$t("EditProjectPage.advanced")}
                            icon="ellipsis-h"
                            visible={isMaintainer}
                        >
                            <AdvancedProject project={this.project} />
                        </b-tab-item>
                    </b-tabs>
                </div>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.picker {
    margin: 0;
}
</style>
