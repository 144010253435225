<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { UserService } from "../../services/UserService";

@Component
export default class ResetPwd extends Vue {
    @inject(UserService) userService;

    mounted() {
        this.$refs.email.focus();
    }

    async sendMail() {
        const isValid = this.$refs.email.checkHtml5Validity();
        if (!isValid) {
            return;
        }

        try {
            await this.userService.resetPwd(this.$refs.email.newValue);

            this.$router.push("/login");
            this.$buefy.toast.open({
                message: this.$t("ResetPwd.message"),
                type: "is-success",
            });
        } catch (error) {
            this.$buefy.toast.open({
                message: this.$t("ResetPwd.error"),
                type: "is-danger",
            });
        }
    }

    submit(e) {
        this.sendMail();

        e.preventDefault();
        e.stopPropagation();
    }

    render() {
        return (
            <form class="tab-forms" onsubmit={this.submit} expanded={true}>
                <b-tabs ref="tabs" animated={false}>
                    <b-tab-item label={this.$t("ResetPwd.title")}>
                        <b-field label={this.$t("ResetPwd.email")}>
                            <b-input
                                ref="email"
                                placeholder={this.$t("ResetPwd.email-placeholder")}
                                type="email"
                                required
                            ></b-input>
                        </b-field>
                        <div class="control has-text-right">
                            <button class="button is-primary is-fullwidth">
                                {this.$t("ResetPwd.submit")}
                            </button>
                        </div>
                    </b-tab-item>
                </b-tabs>
            </form>
        );
    }
}
</script>

<style lang="scss" scoped>
.tab-forms {
    box-shadow: 0 0 0 1px #e5e5e5;
    border-bottom-right-radius: 0.25rem;
    border-radius: 0.25rem;
    width: 460px;
}
</style>
