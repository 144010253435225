<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";

import { format } from "date-fns";
import { getProgressInDay } from "../../base/Utils";

import { TimelineService } from "../../services/TimelineService";

@Component
export default class TimelineUnderCursorTime extends Vue {
    @inject(TimelineService) timelineService;

    date = new Date();
    isHidden = true;

    render() {
        const currentDatePosition = getProgressInDay(this.date, this.timelineService.currentDate);
        const formattedDate = format(this.date, "HH:mm:ss");

        return (
            <div
                ref="cursor"
                class={`hour-under-cursor is-size-6${this.isHidden ? " is-hidden" : ""}`}
                style={{ left: `calc(${currentDatePosition}%)` }}
            >
                <div>{formattedDate}</div>
            </div>
        );
    }

    setDate(newDate) {
        this.date = newDate;
    }

    setHidden(isHidden) {
        this.isHidden = isHidden;
    }

    getCurrentPosition() {
        return this.$refs.cursor.getBoundingClientRect().left;
    }
}
</script>

<style lang="scss">
.hour-under-cursor {
    position: absolute;
    overflow: visible;
    user-select: none;
    top: 20px;
    width: 70px;

    &::before {
        content: "";
        position: absolute;
        height: 6px;
        width: 6px;
        left: -2px;
        top: 21px;
        background: #0a3d62;
        border-radius: 5px;
    }

    &::after {
        content: "";
        position: absolute;
        height: 155px;
        width: 1px;
        border: 1px solid #0a3d62;
        left: 0;
        top: 26px;
    }

    > div {
        width: 100%;
        margin-left: -50%;
        color: #0a3d62;
        font-weight: bold;
    }
}
</style>
