<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { ProjectService } from "../../services/ProjectService";

const Props = Vue.extend({
    props: {
        projectIds: {
            type: Array,
            default: () => [],
        },
        tags: {
            type: Array,
            default: () => [],
        },
        allowNew: {
            type: Boolean,
            default: () => false,
        },
    },
});

@Component
export default class TagsSelection extends Props {
    @inject(ProjectService) projectService;

    selectedTags = [];

    async onSelectTag(name) {
        const selectedTags = (await this.projectService.searchTags(name, this.projectIds)) || [];

        const tags = this.getSelectedTags();
        this.selectedTags = selectedTags.filter((t) => !tags.find((o) => o === t));
    }

    oninput() {
        this.$emit("input");
    }

    getSelectedTags() {
        return this.$refs.tags.tags;
    }

    setSelectedTags(tags) {
        this.$refs.tags.tags = tags;
    }

    render() {
        return (
            <b-taginput
                rounded
                ref="tags"
                ellipsis
                icon="tag"
                autocomplete
                allow-new={this.allowNew}
                placeholder={this.$t("TagsSelection.placeholder")}
                data={this.selectedTags}
                ontyping={this.onSelectTag}
                oninput={this.oninput}
                field="name"
                value={this.tags}
            >
                <template slot="empty">{this.$t("TagsSelection.empty")}</template>
            </b-taginput>
        );
    }
}
</script>
