<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";

import { format, isSameDay, getHours, getMinutes } from "date-fns";
import { getProgressInDay } from "../../base/Utils";

import { TimerService } from "../../services/TimerService";
import { TimelineService } from "../../services/TimelineService";
import { UserService } from "../../services/UserService";

@Component
export default class TimelineCurrentTime extends Vue {
    @inject(TimelineService) timelineService;
    @inject(TimerService) timerService;
    @inject(UserService) userService;

    date = new Date();
    always4h20Enabled = false;

    async created() {
        this.timerId = setInterval(() => {
            if (!this.timerService.value) {
                this.update();
            }
        }, 1000);

        this.always4h20Enabled = await this.userService.getAlways4h20(false);
    }

    destroyed() {
        clearInterval(this.timerId);
    }

    update() {
        this.date = new Date();
    }

    render() {
        const currentDatePosition = getProgressInDay(this.date, this.timelineService.currentDate);
        const now = format(this.date, "HH:mm:ss");

        let display4h20 = false;
        if (this.always4h20Enabled) {
            const is4h = getHours(this.date) === 4 || getHours(this.date) === 16;
            const is20m = getMinutes(this.date) === 20;
            display4h20 = is4h && is20m;
        }

        return (
            <div
                class={`hour-now is-size-6${
                    (display4h20 ? " is-green" : "") +
                    (!isSameDay(this.date, this.timelineService.currentDate) ? " is-hidden" : "")
                }`}
                style={{ left: `calc(${currentDatePosition}%)` }}
            >
                <div>{now}</div>
            </div>
        );
    }
}
</script>

<style lang="scss">
.hour-now {
    position: absolute;
    overflow: visible;
    user-select: none;
    top: 20px;
    width: 70px;

    &::before {
        content: "";
        position: absolute;
        height: 6px;
        width: 6px;
        left: -2px;
        top: 21px;
        background: #ff7262;
        border-radius: 5px;
    }

    &::after {
        content: "";
        position: absolute;
        height: 155px;
        width: 1px;
        border: 1px solid #ff7262;
        left: 0;
        top: 26px;
    }

    > div {
        width: 100%;
        margin-left: -50%;
        color: #ff7262;
        font-weight: bold;
    }

    &.is-green {
        &::before {
            background: #2ed573;
        }

        &::after {
            border: 1px solid #2ed573;
        }

        > div {
            color: #2ed573;
        }
    }
}
</style>
