<script>
import Component from "vue-class-component";
import Vue from "vue";
import { format } from "../../base/Utils";
import {
    startOfDay,
    endOfDay,
    startOfWeek,
    endOfWeek,
    startOfMonth,
    startOfYear,
    endOfYear,
    addDays,
    addWeeks,
    addMonths,
    endOfMonth,
    addYears,
} from "date-fns";
import { REPORT_FREQUENCY } from "../../services/ReportService";
import DatePicker from "../../components/common/DatePicker.vue";
import { inject } from "../../base/Injection";
import { UserService } from "../../services/UserService";

const Props = Vue.extend({
    props: {
        noDate: Boolean,
        noFrequency: Boolean,
        frequency: String,
        start: Date,
        end: Date,
    },
});

@Component
export default class ReportPeriod extends Props {
    @inject(UserService) userService;

    startDate = new Date();
    endDate = new Date();
    label = "";
    value = REPORT_FREQUENCY.DAY;
    firstDayOfWeek = 1;

    async created() {
        this.value = this.frequency || REPORT_FREQUENCY.DAY;
        this.startDate = this.start || new Date();
        this.endDate = this.end || new Date();

        this.firstDayOfWeek = await this.userService.getFirstDayOfWeek(1);
    }

    mounted() {
        this.$watch("frequency", (frequency) => {
            this.value = frequency || REPORT_FREQUENCY.DAY;
            this.updateFrequency(true);
        });

        if (!this.frequency && this.startDate && this.endDate) {
            return;
        }
        this.updateFrequency();
    }

    getStartDate() {
        return this.startDate;
    }

    getEndDate() {
        return this.endDate;
    }

    updateLabel() {
        switch (this.value) {
            case REPORT_FREQUENCY.DAY:
                this.label = format(this.startDate, "EEE dd MMMM yyyy");
                break;

            case REPORT_FREQUENCY.WEEK:
                this.label =
                    "Week " +
                    format(this.startDate, "ww yyyy", { weekStartsOn: this.firstDayOfWeek });
                break;

            case REPORT_FREQUENCY.MONTH:
                this.label = format(this.startDate, "MMMM yyyy");
                break;

            case REPORT_FREQUENCY.YEAR:
                this.label = format(this.startDate, "yyyy");
                break;

            case REPORT_FREQUENCY.CUSTOM:
                this.label =
                    format(this.startDate, "EEE dd MMMM yyyy") +
                    " - " +
                    format(this.endDate, "EEE dd MMMM yyyy");
                break;
        }

        this.$emit("change", this.startDate, this.endDate);
    }

    updateFrequency(force) {
        if (!force) {
            const selectedIndex = this.$refs.frequency.selectedIndex;
            this.value = this.$refs.frequency[selectedIndex].value;
        }

        const now = this.startDate;

        switch (this.value) {
            case REPORT_FREQUENCY.DAY:
                this.startDate = startOfDay(now);
                this.endDate = endOfDay(now);
                break;

            case REPORT_FREQUENCY.WEEK:
                this.startDate = startOfWeek(now, { weekStartsOn: this.firstDayOfWeek });
                this.endDate = endOfWeek(now, { weekStartsOn: this.firstDayOfWeek });
                break;

            case REPORT_FREQUENCY.MONTH:
                this.startDate = startOfMonth(now);
                this.endDate = endOfMonth(now);
                break;

            case REPORT_FREQUENCY.YEAR:
                this.startDate = startOfYear(now);
                this.endDate = endOfYear(now);
                break;
        }

        this.updateLabel();
    }

    updateDates(diff) {
        switch (this.value) {
            case REPORT_FREQUENCY.DAY:
                this.startDate = addDays(this.startDate, diff);
                this.endDate = endOfDay(this.startDate);
                break;

            case REPORT_FREQUENCY.WEEK:
                this.startDate = addWeeks(this.startDate, diff);
                this.endDate = endOfWeek(this.startDate, { weekStartsOn: this.firstDayOfWeek });
                break;

            case REPORT_FREQUENCY.MONTH:
                this.startDate = addMonths(this.startDate, diff);
                this.endDate = endOfMonth(this.startDate);
                break;

            case REPORT_FREQUENCY.YEAR:
                this.startDate = addYears(this.startDate, diff);
                this.endDate = endOfYear(this.startDate);
                break;
        }

        this.updateLabel();
    }

    setNow() {
        this.startDate = new Date();
        this.updateFrequency();
    }

    updateCustomDates(dates) {
        this.startDate = dates[0];
        this.endDate = dates[1];
        this.updateLabel();
    }

    render() {
        return (
            <div class="period">
                <b-field class="period-select">
                    <div class={`${this.noFrequency ? "is-hidden" : "select is-fullwidth"}`}>
                        <select ref="frequency" onchange={() => this.updateFrequency()}>
                            <option
                                value={REPORT_FREQUENCY.DAY}
                                selected={this.value === REPORT_FREQUENCY.DAY}
                            >
                                {this.$t("ReportPeriod.by", {
                                    period: this.$t("ReportPeriod.day"),
                                })}
                            </option>
                            <option
                                value={REPORT_FREQUENCY.WEEK}
                                selected={this.value === REPORT_FREQUENCY.WEEK}
                            >
                                {this.$t("ReportPeriod.by", {
                                    period: this.$t("ReportPeriod.week"),
                                })}
                            </option>
                            <option
                                value={REPORT_FREQUENCY.MONTH}
                                selected={this.value === REPORT_FREQUENCY.MONTH}
                            >
                                {this.$t("ReportPeriod.by", {
                                    period: this.$t("ReportPeriod.month"),
                                })}
                            </option>
                            <option
                                value={REPORT_FREQUENCY.YEAR}
                                selected={this.value === REPORT_FREQUENCY.YEAR}
                            >
                                {this.$t("ReportPeriod.by", {
                                    period: this.$t("ReportPeriod.year"),
                                })}
                            </option>
                            <option
                                value={REPORT_FREQUENCY.CUSTOM}
                                selected={this.value === REPORT_FREQUENCY.CUSTOM}
                            >
                                {this.$t("ReportPeriod.custom")}
                            </option>
                        </select>
                    </div>
                </b-field>

                <div
                    class={`period-date ${
                        this.noDate && this.value !== REPORT_FREQUENCY.CUSTOM ? "is-hidden" : ""
                    }`}
                >
                    <a
                        class={`${this.value === REPORT_FREQUENCY.CUSTOM ? "is-hidden" : "ml-5"}`}
                        onclick={() => this.updateDates(-1)}
                    >
                        <i class="fa fa-angle-left fa-2x"></i>
                    </a>
                    <div>
                        <span class="ml-2">{this.label}</span>
                        <DatePicker
                            class="date ml-2"
                            type={this.value}
                            start={this.startDate}
                            end={this.endDate}
                            isCreation={!this.noDate}
                            firstDayOfWeek={this.firstDayOfWeek}
                            ondateUpdated={this.updateCustomDates}
                        />
                    </div>
                    <a
                        class={`${
                            this.value === REPORT_FREQUENCY.CUSTOM ? "is-hidden" : "period-now ml-2"
                        }`}
                        onclick={this.setNow}
                    >
                        <i class="fa fa-calendar fa-1x"></i>
                    </a>
                    <a
                        class={`${
                            this.value === REPORT_FREQUENCY.CUSTOM ? "is-hidden" : "ml-2 mr-5"
                        }`}
                        onclick={() => this.updateDates(1)}
                    >
                        <i class="fa fa-angle-right fa-2x"></i>
                    </a>
                </div>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.period {
    display: flex;
}

.period-date {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    > div {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
    }
}
.period-now {
    margin-top: -4px;
}

.period-select {
    flex-shrink: 0;
    margin: 0;
}
</style>
