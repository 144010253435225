<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../base/Injection";
import { TeamService } from "../services/TeamService";
import PaginatedTable from "../components/common/PaginatedTable.vue";

@Component
export default class TeamsPage extends Vue {
    @inject(TeamService) teamService;

    mounted() {
        this.teamService.on("change", this.$refs.table.loadData);
    }

    beforeDestroy() {
        this.teamService.off("change", this.$refs.table.loadData);
    }

    async getTeams(selector) {
        const teams = await this.teamService.getTeams(selector);
        return teams;
    }

    async saveTeam() {
        if (this.$refs.name.newValue) {
            try {
                await this.teamService.saveTeam({ name: this.$refs.name.newValue });
                this.$refs.name.newValue = "";
            } catch {
                this.$buefy.toast.open({
                    message: this.$t("error.server"),
                    type: "is-danger",
                });
            }
        }
    }

    editTeam({ id }) {
        this.$router
            .push({
                path: `/teams/edit/${id}`,
            })
            .catch(() => {});
    }

    render() {
        return (
            <div class="page-content">
                <div class="page-title">{this.$t("TeamsPage.title")}</div>
                <div class="page-full page-table">
                    <div class="field-container">
                        <div class="field-inline">
                            <div class="fixed">
                                <b-input
                                    ref="name"
                                    ellipsis
                                    icon="users"
                                    placeholder={this.$t("TeamsPage.name-placeholder")}
                                    required
                                ></b-input>
                            </div>
                            <button
                                class="button is-primary is-rounded is-outlined"
                                onclick={this.saveTeam}
                            >
                                {this.$t("TeamsPage.submit")}
                            </button>
                        </div>

                        <PaginatedTable
                            ref="table"
                            fetchData={this.getTeams}
                            onOpen={this.editTeam}
                            defaultSort="name_asc"
                        >
                            <b-table-column
                                label={this.$t("TeamsPage.name")}
                                field="name"
                                sortable
                                scopedSlots={{
                                    default: ({ row: team }) => (
                                        <span>
                                            {team.name}
                                            {team.admin ? (
                                                <span class="is-tag ml-2">
                                                    {this.$t("TeamsPage.admin")}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </span>
                                    ),
                                }}
                            />

                            <b-table-column
                                label={this.$t("TeamsPage.users")}
                                field="count"
                                numeric
                                sortable
                                scopedSlots={{
                                    default: ({ row: team }) => team.count,
                                }}
                            />
                        </PaginatedTable>
                    </div>
                </div>
            </div>
        );
    }
}
</script>
