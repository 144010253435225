<script>
import Component from "vue-class-component";
import Vue from "vue";
import { COLLABORATOR_TYPE, ProjectService } from "../services/ProjectService";
import { Report, ReportService, REPORT_FREQUENCY } from "../services/ReportService";
import { TagService } from "../services/TagService";
import { inject } from "../base/Injection";
import ReportPeriod from "../components/report/ReportPeriod.vue";
import ProjectsSelection from "../components/common/ProjectsSelection.vue";
import TagsSelection from "../components/common/TagsSelection.vue";
import ReportCharts from "../components/report/ReportCharts.vue";
import BackButton from "../components/common/BackButton.vue";
import CollaboratorList from "../components/common/CollaboratorList.vue";
import InlineInput from "../components/common/InlineInput.vue";
import { durationStringAccordingToDisplayModes, timeStringToSeconds } from "../base/Utils";

@Component
export default class CreateReportPage extends Vue {
    @inject(ProjectService) projectService;
    @inject(ReportService) reportService;
    @inject(TagService) tagService;

    report = new Report();
    projectIds = [];
    askTimes = false;
    sync = false;
    allProjects = true;
    allTags = true;
    displayHoursPerDayInput = false;
    displayModeExample = "";
    invalidDurationDisplayError = "";
    noChartSelected = "";

    created() {
        this.init();
        this.initBound = () => this.init();
    }

    async init() {
        try {
            const id = this.$route.params.id;
            if (!id) {
                this.report = this.createEmptyReport();
            } else {
                this.report = (await this.reportService.getReport(id)) || this.createEmptyReport();
                this.askTimes = this.report.collaborators.length !== 0;
                this.projectIds = this.report.projects.map((p) => p.id);

                this.allProjects = !this.projectIds.length;
                this.allTags = !this.report.tags || !this.report.tags.length;
                this.sync = this.report.synchronizable;
            }
        } catch {
            this.report = this.createEmptyReport();
        }
        this.refreshDisplayModeExample();
    }

    mounted() {
        this.report.projects = this.$refs.projectsSelec.getSelectedProjectIds().map((pId) => {
            return { id: pId };
        });
    }

    onInputReportName(name) {
        this.report.name = name;
    }

    onInputReportDescription(description) {
        this.report.description = description;
    }

    onInputDurationDisplayModeExactDuration(durationDisplayModeExactDuration) {
        this.report.durationDisplayModeExactDuration = durationDisplayModeExactDuration;
        this.isValid();
        this.refreshDisplayModeExample();
    }

    onInputDurationDisplayModePortionOfTotal(durationDisplayModePortionOfTotal) {
        this.report.durationDisplayModePortionOfTotal = durationDisplayModePortionOfTotal;
        this.isValid();
        this.refreshDisplayModeExample();
    }

    onInputDurationDisplayModeHoursPerDayBoolean(displayHoursPerDayInput) {
        this.displayHoursPerDayInput = displayHoursPerDayInput;
        if (this.displayHoursPerDayInput) {
            this.report.durationDisplayModeHoursPerDay = "07:00";
        } else {
            this.report.durationDisplayModeHoursPerDay = "";
        }
        this.isValid();
        this.refreshDisplayModeExample();
    }

    onInputDurationDisplayModeHoursPerDayNumber(hoursPerDay) {
        this.report.durationDisplayModeHoursPerDay = hoursPerDay;
        this.isValid();
        this.refreshDisplayModeExample();
        return timeStringToSeconds(hoursPerDay);
    }

    refreshDisplayModeExample() {
        this.displayHoursPerDayInput =
            this.report.durationDisplayModeHoursPerDay &&
            this.report.durationDisplayModeHoursPerDay.length > 0;

        this.displayModeExample = durationStringAccordingToDisplayModes(
            this._i18n,
            this.report,
            3600,
            7200
        );
    }

    back() {
        this.$router.push("/reports").catch(() => {});
    }

    async saveSeeReport(e) {
        e.preventDefault();
        e.stopPropagation();

        const saved = await this.save();
        if (saved) {
            this.$router.push(`/reports/charts/${this.report.id}`).catch(() => {});
        }
    }

    async saveReport(e) {
        e.preventDefault();
        e.stopPropagation();

        const saved = await this.save();
        if (saved) {
            this.$router.push("/reports").catch(() => {});
        }
    }

    async save() {
        let saved = true;
        if (!this.isValid()) {
            this.$buefy.toast.open({
                message: this.$t("CreateReportPage.invalidContentError"),
                type: "is-danger",
            });
            return false;
        }
        this.report.dataVisualisations = this.$refs.charts.getSelectedCharts();

        const periodElmt = this.$refs.period;
        if (periodElmt.value === REPORT_FREQUENCY.CUSTOM) {
            this.report.startDate = periodElmt.startDate;
            this.report.endDate = periodElmt.endDate;
        }
        this.report.frequency = periodElmt.value;

        this.report.tags = this.$refs.tagsSelec.getSelectedTags();
        this.report.projects = this.$refs.projectsSelec.getSelectedProjects();
        this.report.synchronizable = this.sync;

        try {
            const data = await this.reportService.saveReport(this.report);
            this.report.id = data.id;
        } catch {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
            saved = false;
        }

        try {
            await this.reportService.addProjects(
                this.report.id,
                this.report.projects.map((p) => p.id)
            );
        } catch {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
            saved = false;
        }

        if (this.askForTimes) {
            this.report.collaborators = this.$refs.collaborators.getSelectedCollaborators();
            await this.addTeams();
            await this.addUsers();
        }
        if (saved) {
            this.$buefy.toast.open({
                message: this.$t("CreateReportPage.saveSuccess"),
                type: "is-success",
            });
        }
        return true;
    }

    async addUsers() {
        try {
            await this.reportService.addUsers(
                this.report.id,
                this.report.collaborators.reduce((results, c) => {
                    if (c.type === COLLABORATOR_TYPE.USER) {
                        results.push(c.id);
                    }
                    return results;
                }, [])
            );
        } catch {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
        }
    }

    async addTeams() {
        try {
            await this.reportService.addTeams(
                this.report.id,
                this.report.collaborators.reduce((results, c) => {
                    if (c.type === COLLABORATOR_TYPE.TEAM) {
                        results.push(c.id);
                    }
                    return results;
                }, [])
            );
        } catch {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
        }
    }

    onProjectsSelectionChange(pIds) {
        this.projectIds = pIds;
    }

    isValid() {
        const isValid = Object.values(this.$refs).reduce((acc, node) => {
            return (!node.checkHtml5Validity || node.checkHtml5Validity()) && acc;
        }, true);

        this.noChartSelected = "";
        const chartSelected = this.$refs.charts.getSelectedCharts().length !== 0;
        if (!chartSelected) {
            this.noChartSelected = this.$t("CreateReportPage.error-dataviz");
        }

        this.invalidDurationDisplayError = "";
        const isValidHoursPerDay =
            !this.report.durationDisplayModeHoursPerDay ||
            timeStringToSeconds(this.report.durationDisplayModeHoursPerDay) !== false;
        if (!isValidHoursPerDay) {
            this.invalidDurationDisplayError = this.$t(
                "CreateReportPage.durationDisplayErrorInvalidFormat"
            );
        }

        const atLeastOneDurationDispalyModeSelected =
            this.report.durationDisplayModeExactDuration ||
            this.report.durationDisplayModeHoursPerDay ||
            this.report.durationDisplayModePortionOfTotal;
        if (!atLeastOneDurationDispalyModeSelected) {
            this.invalidDurationDisplayError = this.$t(
                "CreateReportPage.durationDisplayErrorAtLeastOneMode"
            );
        }

        return (
            isValid && chartSelected && isValidHoursPerDay && atLeastOneDurationDispalyModeSelected
        );
    }

    askForTimes(value) {
        this.askTimes = value;
        this.sync = false;
    }

    setAllProjects(value) {
        this.allProjects = value;

        if (this.allProjects) {
            this.projectIds = [];
        } else {
            this.projectIds = this.report.projects.map((p) => p.id);
        }
    }

    setAllTags(value) {
        this.allTags = value;
    }

    setSync(value) {
        this.sync = value;
    }

    createEmptyReport() {
        let newReport = new Report();
        newReport.durationDisplayModeExactDuration = true;
        newReport.durationDisplayModeHoursPerDay = "";
        newReport.durationDisplayModePortionOfTotal = false;
        return newReport;
    }

    render() {
        return (
            <div class="page-content">
                <div class="page-title">
                    <BackButton cb={() => this.back()} />
                    {this.report.id
                        ? this.$t("CreateReportPage.edit-report")
                        : this.$t("CreateReportPage.create-report")}
                    / {this.report.name}
                </div>
                <div class="page-center page-full">
                    <div class="page-form">
                        <b-field label={this.$t("CreateReportPage.name")}>
                            <b-input
                                ref="name"
                                ellipsis
                                icon="folder"
                                placeholder={this.$t("CreateReportPage.report-name")}
                                oninput={(name) => this.onInputReportName(name)}
                                required
                                value={this.report.name}
                            />
                        </b-field>
                        <b-field label={this.$t("CreateReportPage.description")}>
                            <b-input
                                ref="description"
                                ellipsis
                                icon="folder"
                                placeholder={this.$t("CreateReportPage.report-description")}
                                oninput={(desc) => this.onInputReportDescription(desc)}
                                required
                                value={this.report.description}
                            />
                        </b-field>
                        <div class="field">
                            <label class="label">{this.$t("CreateReportPage.frequency")}</label>
                            <ReportPeriod
                                class="report-period--create"
                                noDate={true}
                                ref="period"
                                frequency={this.report.frequency}
                                start={this.report.startDate && new Date(this.report.startDate)}
                                end={this.report.endDate && new Date(this.report.endDate)}
                                key={`period-${this.report.id}`}
                            />
                        </div>
                        <div class="field">
                            <label class="label  createReport-label-with-action">
                                {this.$t("CreateReportPage.durationDisplayMode")}
                            </label>
                            <div class="duration-display-modes-container">
                                <b-checkbox
                                    oninput={(durationDisplayModeExactDuration) =>
                                        this.onInputDurationDisplayModeExactDuration(
                                            durationDisplayModeExactDuration
                                        )
                                    }
                                    value={this.report.durationDisplayModeExactDuration}
                                >
                                    {this.$t("CreateReportPage.durationDisplayModeExactDuration")}
                                </b-checkbox>

                                <b-checkbox
                                    oninput={(durationDisplayModePortionOfTotal) =>
                                        this.onInputDurationDisplayModePortionOfTotal(
                                            durationDisplayModePortionOfTotal
                                        )
                                    }
                                    value={this.report.durationDisplayModePortionOfTotal}
                                >
                                    {this.$t("CreateReportPage.durationDisplayModePortionOfTotal")}
                                </b-checkbox>

                                <b-checkbox
                                    oninput={this.onInputDurationDisplayModeHoursPerDayBoolean}
                                    value={this.displayHoursPerDayInput}
                                >
                                    {this.$t("CreateReportPage.durationDisplayModeHoursPerDay")}
                                </b-checkbox>
                                <div
                                    class={{
                                        "hours-in-day-input": true,
                                        "is-hidden": !this.displayHoursPerDayInput,
                                    }}
                                >
                                    {this.$t(
                                        "CreateReportPage.durationDisplayModeHoursPerDayDetails"
                                    )}
                                    <InlineInput
                                        class="hours-in-day-input-inline"
                                        value={this.report.durationDisplayModeHoursPerDay}
                                        updateCb={this.onInputDurationDisplayModeHoursPerDayNumber}
                                    />
                                </div>
                            </div>
                            <p
                                class={{
                                    "is-hidden": !this.invalidDurationDisplayError,
                                    "is-danger": true,
                                    help: true,
                                }}
                            >
                                {this.invalidDurationDisplayError}
                            </p>
                            <div
                                class={{
                                    "field-message": true,
                                    "is-hidden": this.invalidDurationDisplayError,
                                }}
                            >
                                {this.$t("CreateReportPage.durationDisplayModeExample")}{" "}
                                {this.displayModeExample}
                            </div>
                        </div>
                        <div class="field">
                            <label class="label createReport-label-with-action">
                                {this.$t("CreateReportPage.projects")}
                                <b-switch value={this.allProjects} oninput={this.setAllProjects} />
                            </label>
                            <div class={{ "field-message": true, "is-hidden": !this.allProjects }}>
                                {this.$t("CreateReportPage.allProjects")}
                            </div>
                            <div class={{ "is-hidden": this.allProjects }}>
                                <ProjectsSelection
                                    ref="projectsSelec"
                                    projects={this.allProjects ? [] : this.report.projects}
                                    oninput={this.onProjectsSelectionChange}
                                />
                            </div>
                        </div>
                        <div class="field">
                            <label class="label  createReport-label-with-action">
                                {this.$t("CreateReportPage.tags")}
                                <b-switch value={this.allTags} oninput={this.setAllTags} />
                            </label>
                            <div class={{ "field-message": true, "is-hidden": !this.allTags }}>
                                {this.$t("CreateReportPage.allTags")}
                            </div>
                            <div class={{ "is-hidden": this.allTags }}>
                                <TagsSelection
                                    ref="tagsSelec"
                                    projectIds={this.projectIds}
                                    tags={this.allTags ? [] : this.report.tags}
                                />
                            </div>
                        </div>
                        <div class="field">
                            <label class="label createReport-label-with-action">
                                {this.$t("CreateReportPage.times")}
                                <b-switch
                                    ref="timesSwitch"
                                    value={this.askTimes}
                                    oninput={this.askForTimes}
                                />
                            </label>
                            <div>
                                <CollaboratorList
                                    reportId={this.report.id}
                                    projectIds={this.projectIds}
                                    key={`collaborators-${this.projectIds}`}
                                    selectedCollaborators={this.report.collaborators}
                                    enabled={this.askTimes}
                                    ref="collaborators"
                                />
                            </div>
                        </div>
                        <div class="field">
                            <label class="label  createReport-label-with-action">
                                {this.$t("CreateReportPage.sync")}
                                <b-tooltip
                                    ref="syncSwitch"
                                    label={this.$t("CreateReportPage.noAsks")}
                                    position="is-bottom"
                                    active={this.askTimes}
                                >
                                    <b-switch
                                        value={this.sync && !this.askTimes}
                                        oninput={this.setSync}
                                        disabled={this.askTimes}
                                    />
                                </b-tooltip>
                            </label>
                            <div
                                class={{
                                    "field-message": true,
                                    "is-hidden": !this.sync || this.askTimes,
                                }}
                            >
                                {this.$t("CreateReportPage.sync-enabled")}
                            </div>
                            <div
                                class={{
                                    "field-message": true,
                                    "is-hidden": this.sync && !this.askTimes,
                                }}
                            >
                                {this.$t("CreateReportPage.sync-disabled")}
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">{this.$t("CreateReportPage.dataviz")}</label>
                            <p
                                class={{
                                    "is-hidden": !this.noChartSelected,
                                    "is-danger": true,
                                    help: true,
                                }}
                            >
                                {this.noChartSelected}
                            </p>
                            <ReportCharts
                                ref="charts"
                                selected={this.report.dataVisualisations}
                                key={`dataViz-${this.report.id}`}
                            />
                        </div>
                        <div class="buttonContainer">
                            <button
                                class="button is-danger is-outlined is-rounded mt-05"
                                onclick={this.back}
                            >
                                <span class="icon">
                                    <i class="fa fa-arrow-left"></i>
                                </span>
                                <span>{this.$t("CreateReportPage.back")}</span>
                            </button>
                            <div class="buttons is-right">
                                <button
                                    class="button is-primary is-outlined is-rounded mt-05"
                                    onclick={this.saveReport}
                                >
                                    <span>
                                        {this.report.id
                                            ? this.$t("CreateReportPage.save")
                                            : this.$t("CreateReportPage.create")}
                                    </span>
                                </button>
                                <button
                                    class="button is-primary is-outlined is-rounded mt-05"
                                    onclick={this.saveSeeReport}
                                >
                                    <span>{this.$t("CreateReportPage.visualisation")}</span>
                                    <span class="icon">
                                        <i class="fa fa-arrow-right"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.createReport-taskValue {
    display: flex;
    align-items: center;
}

.createReport-label-with-action {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .switch {
        margin-right: 0;
    }
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
}

.field-message {
    height: 2.5rem;
    display: flex;
    align-items: center;
}

.field-message-collaborators {
    height: 3rem;
}

.expiration-dates {
    display: flex;
    margin-top: 0.5rem;
    align-items: center;
}
</style>

<style lang="scss">
.report-period--create {
    .period-date {
        justify-content: start;

        > div {
            flex-direction: row-reverse;
            justify-content: start;
            width: auto;
        }
    }
}

.duration-display-modes-container {
    display: flex;
    align-items: center;
    height: 35px;
}
.hours-in-day-input {
    display: flex;
    align-items: center;
}
.hours-in-day-input-inline {
    padding-left: 5px;
    width: 90px;
}
</style>
