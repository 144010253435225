<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { ProjectService } from "../../services/ProjectService";
import UserSelection from "../common/UserSelection";
import Permission from "../common/Permission";
import { UserService } from "../../services/UserService";
import PaginatedTable from "../common/PaginatedTable.vue";

const Props = Vue.extend({
    props: {
        project: Object,
    },
});

@Component
export default class UsersProject extends Props {
    @inject(ProjectService) projectService;
    @inject(UserService) userService;

    user = {};

    async mounted() {
        this.user = await this.userService.getUser();

        this.projectService.on("change", this.$refs.table.loadData);
    }

    beforeDestroy() {
        this.projectService.off("change", this.$refs.table.loadData);
    }

    async getUsers(selector) {
        const id = this.$route.params.id;
        const users = await this.projectService.getUsers(id, selector);
        return users;
    }

    async addPermission() {
        const projectId = this.$route.params.id;
        const userId = this.$refs.user.getSelectedUserId();
        const permission = this.$refs.permission.getValue();

        if (userId) {
            try {
                await this.projectService.saveUserPermission(projectId, userId, permission);
                this.$refs.user.clearSelectedUser();
            } catch {
                this.$buefy.toast.open({
                    message: this.$t("error.server"),
                    type: "is-danger",
                });
            }
        }
    }

    async removePermission(userId) {
        const projectId = this.$route.params.id;

        this.$buefy.dialog.confirm({
            title: this.$t("UsersProject.confirm.title"),
            message: this.$t("UsersProject.confirm.message"),
            confirmText: this.$t("UsersProject.confirm.confirmText"),
            type: "is-danger",
            hasIcon: true,
            onConfirm: async () => {
                try {
                    await this.projectService.removeUserPermission(projectId, userId);
                    this.$buefy.toast.open({
                        message: this.$t("UsersProject.confirm.success"),
                        type: "is-success",
                    });
                } catch {
                    this.$buefy.toast.open({
                        message: this.$t("error.server"),
                        type: "is-danger",
                    });
                }
            },
        });
    }

    async setPermission(userId, permission) {
        const projectId = this.$route.params.id;
        try {
            await this.projectService.saveUserPermission(projectId, userId, permission);
        } catch {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
        }
    }

    render() {
        if (!this.project) {
            return;
        }

        const isMaintainer = this.project.permission === "maintainer";

        return (
            <div class="page-table">
                <div class={`field-inline${isMaintainer ? "" : " is-hidden"}`}>
                    <div class="fixed">
                        <UserSelection ref="user" />
                    </div>
                    <div>
                        <Permission ref="permission" value="reporter" />
                    </div>
                    <button
                        class="button is-primary is-rounded is-outlined"
                        onclick={this.addPermission}
                    >
                        {this.$t("UsersProject.submit")}
                    </button>
                </div>
                <PaginatedTable ref="table" fetchData={this.getUsers} defaultSort="name_asc">
                    <b-table-column
                        label={this.$t("UsersProject.user")}
                        field="name"
                        sortable
                        scopedSlots={{
                            default: ({ row: user }) => {
                                return (
                                    <span class="avatar">
                                        <figure class="image is-32x32">
                                            <img class="is-rounded" src={user.avatar} />
                                        </figure>
                                        {user.name}
                                    </span>
                                );
                            },
                        }}
                    />
                    <b-table-column
                        label={this.$t("UsersProject.permission")}
                        field="permission"
                        centered
                        sortable
                        scopedSlots={{
                            default: ({ row: user }) => {
                                return (
                                    <span>
                                        <Permission
                                            key={`permission-${user.permission}`}
                                            value={user.permission}
                                            size="is-small"
                                            oninput={(value) => this.setPermission(user.id, value)}
                                            class={this.user.id === user.id ? "is-hidden" : ""}
                                        />
                                        <span
                                            class={
                                                this.user.id === user.id ? "is-tag" : "is-hidden"
                                            }
                                        >
                                            {user.permission}
                                        </span>
                                    </span>
                                );
                            },
                        }}
                    />

                    <b-table-column
                        label={this.$t("TeamsProject.action")}
                        centered
                        scopedSlots={{
                            default: ({ row: user }) => {
                                return (
                                    <b-button
                                        type="is-danger is-small"
                                        icon-right="trash"
                                        onclick={() => this.removePermission(user.id)}
                                        class={this.user.id === user.id ? "is-hidden" : ""}
                                    />
                                );
                            },
                        }}
                    />
                </PaginatedTable>
            </div>
        );
    }
}
</script>
