<script>
import Component from "vue-class-component";
import Vue from "vue";

const Props = Vue.extend({
    props: {
        chartdata: Number,
    },
});

@Component
export default class ReportChartCounter extends Props {
    render() {
        return <div class="container">{this.chartdata}</div>;
    }
}
</script>
<style lang="scss" scoped>
.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 15rem;
}
</style>
