<script>
import Component from "vue-class-component";
import Vue from "vue";
import CollaboratorList from "../common/CollaboratorList.vue";
import { inject } from "../../base/Injection";
import { ReportService } from "../../services/ReportService";

const Props = Vue.extend({
    props: {
        report: Object,
    },
});

@Component
export default class ShareReportModal extends Props {
    @inject(ReportService) reportService;

    save() {
        const collaborators = this.$refs.collaborators.getSelectedCollaborators();
        try {
            this.reportService.share(
                this.report.id,
                collaborators.map((collaborator) => collaborator.id)
            );
        } catch (e) {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
        } finally {
            this.$emit("close", true);
        }
    }

    render() {
        return (
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        {this.$t("ShareReportModal.title", { name: this.report.name })}
                    </p>
                </header>
                <section class="modal-card-body">
                    <p class="mb-4">{this.$t("ShareReportModal.description")}</p>
                    <CollaboratorList
                        reportId={this.report.id}
                        ref="collaborators"
                        projectIds={this.report.projects.map((project) => project.id)}
                        selectedCollaborators={this.report.sharedUsers}
                        ref="collaborators"
                        enabled
                    />
                </section>
                <footer class="modal-card-foot">
                    <button
                        ref="submit"
                        class="button is-primary"
                        onclick={() => this.$emit("close", false)}
                    >
                        {this.$t("ShareReportModal.close")}
                    </button>
                    <button ref="submit" class="button is-primary" onclick={this.save}>
                        {this.$t("ShareReportModal.submit")}
                    </button>
                </footer>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.modal-card-body {
    overflow: visible;
}
</style>
