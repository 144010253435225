<script>
import Component from "vue-class-component";
import Vue from "vue";

const Props = Vue.extend({
    props: {
        cb: Function,
    },
});

@Component
export default class BackButton extends Props {
    render() {
        return (
            <a class="back-button" role="button" onclick={() => this.cb?.()}>
                <div class="icon">
                    <i class="fa fa-arrow-left"></i>
                </div>
            </a>
        );
    }
}
</script>

<style lang="scss" scoped>
.back-button {
    display: flex;
    align-items: center;
}
</style>
