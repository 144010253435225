<script>
import Component from "vue-class-component";
import Vue from "vue";

const Props = Vue.extend({
    props: {
        text: String,
        visible: Boolean,
        icon: {
            type: String,
            default: () => "fa-search",
        },
    },
});

@Component
export default class EmptyTable extends Props {
    render() {
        if (!this.visible) {
            return;
        }
        return (
            <div class="center">
                <div class={`fa fa-3x ${this.icon}`}></div>
                <div>{this.text}</div>
            </div>
        );
    }
}
</script>
<style lang="scss" scoped>
.center {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 1.2em;
}
</style>
