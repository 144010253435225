<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../base/Injection";
import { UserService } from "../services/UserService";
import EditableInput from "../components/common/EditableInput.vue";
import About from "../components/profile/About.vue";
import Repositories from "../components/profile/Repositories.vue";
import Options from "../components/profile/Options.vue";

@Component
export default class ProfilePage extends Vue {
    @inject(UserService) userService;

    user = {};

    async created() {
        this.user = await this.userService.getUser();
    }

    signout() {
        this.$buefy.dialog.confirm({
            title: this.$t("ProfilePage.signout.confirm.title"),
            message: this.$t("ProfilePage.signout.confirm.message"),
            cancelText: this.$t("ProfilePage.signout.confirm.cancelText"),
            confirmText: this.$t("ProfilePage.signout.confirm.confirmText"),
            type: "is-danger",
            onConfirm: () => {
                this.$router.push("/login").catch(() => {});
                this.userService.signOut();
            },
        });
    }

    async updatePassword() {
        try {
            await this.userService.requestToUpdatePwd(this.user);
            this.$buefy.toast.open({
                message: this.$t("ProfilePage.success.password"),
                type: "is-success",
            });
        } catch {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
        }
    }

    async saveUserName(newValue, target) {
        try {
            await this.userService.saveUser({
                name: newValue,
                email: this.user.email,
            });
            this.user.name = newValue;
        } catch {
            this.$buefy.toast.open({
                message: this.$t("ProfilePage.error.name"),
                type: "is-danger",
            });
            target.cancel();
        }
    }

    async saveUserEmail(newValue, target) {
        try {
            await this.userService.saveUser({
                name: this.user.name,
                email: newValue,
            });
            this.user.email = newValue;
        } catch {
            this.$buefy.toast.open({
                message: this.$t("ProfilePage.error.email"),
                type: "is-danger",
            });
            target.cancel();
        }
    }

    render() {
        return (
            <div class="page-content">
                <div class="page-title">{this.$t("ProfilePage.title")}</div>

                <div class="page-full page-table">
                    <div class="page-header">
                        <div class="actions">
                            <a
                                class="button is-primary is-outlined is-rounded mt-2 mr-2"
                                onclick={this.updatePassword}
                            >
                                {this.$t("ProfilePage.updatePassword")}
                            </a>
                            <a
                                class="button is-primary is-outlined is-rounded mt-2 mr-2"
                                onclick={this.signout}
                            >
                                {this.$t("ProfilePage.signout.label")}
                            </a>
                        </div>
                        <figure class="image is-96x96 mt-2">
                            <a href="https://www.libravatar.org/account/login/">
                                <img class="is-rounded" src={this.user.avatar} />
                            </a>
                        </figure>

                        <div class="is-size-6 mt-2">
                            <EditableInput
                                type="avatar"
                                value={this.user.name}
                                onChange={this.saveUserName}
                            />
                            <EditableInput
                                inputType="email"
                                type="avatar"
                                value={this.user.email}
                                onChange={this.saveUserEmail}
                            />
                        </div>
                    </div>

                    <div class="columns mt-2">
                        <div class="column is-8 is-offset-2">
                            <b-tabs animated={false} destroy-on-hide={true} expanded={true}>
                                <b-tab-item
                                    label={this.$t("ProfilePage.repositories")}
                                    icon="code-fork"
                                >
                                    <Repositories />
                                </b-tab-item>
                                <b-tab-item label={this.$t("ProfilePage.options")} icon="wrench">
                                    <Options />
                                </b-tab-item>
                                <b-tab-item
                                    label={this.$t("ProfilePage.about")}
                                    icon="question-circle"
                                >
                                    <About />
                                </b-tab-item>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.page-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

img {
    border: 1px solid #f5f5f5;
}

.actions {
    align-self: flex-end;
}

.page-full {
    display: flex;
    flex-flow: column;
}

.columns {
    margin: 0;
    flex-grow: 1;
}
</style>
