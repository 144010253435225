<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { UserService } from "../../services/UserService";

const Props = Vue.extend({
    props: {
        repo: Object,
    },
});

@Component
export default class RepoProjects extends Props {
    @inject(UserService) userService;

    searchProjects = [];
    isFetching = false;

    async onInputProjects(name) {
        this.isFetching = true;
        try {
            this.searchProjects =
                (await this.userService.searchRepoProjects(this.repo, name)) || [];
        } catch {
            this.searchProjects = [];
        }
        this.isFetching = false;
    }

    getSelectedProjectId() {
        return this.$refs.projects.selected && this.$refs.projects.selected.id;
    }

    clearSelectedProject() {
        this.$refs.projects.newValue = "";
    }

    onSelectProject() {
        this.$emit("select", this.$refs.projects.selected);
    }

    render() {
        return (
            <b-autocomplete
                ref="projects"
                placeholder={this.$t("RepoProjects.placeholder")}
                keep-first
                data={this.searchProjects}
                oninput={this.onInputProjects}
                onselect={this.onSelectProject}
                field="name"
                loading={this.isFetching}
                disabled={!this.repo || !this.repo.credential}
            >
                <template slot="empty">{this.$t("RepoProjects.empty")}</template>
            </b-autocomplete>
        );
    }
}
</script>
