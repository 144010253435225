<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { UserService } from "../../services/UserService";
import ProfileIcon from "../common/ProfileIcon.vue";
import { COLLABORATOR_TYPE } from "../../services/ProjectService";

@Component
export default class Menu extends Vue {
    @inject(UserService) userService;

    user = {};
    requestsCount = 0;

    async created() {
        try {
            this.user = await this.userService.getUser();
        } catch {
            this.$router.push("/login").catch(() => {});
        }
        this.requestsCount = await this.userService.getRequestsCount();
    }

    openProfile() {
        this.$router.push("/profile").catch(() => {});
    }

    expandMenu() {
        this.$el.classList.toggle("open");
    }

    closeMenu() {
        this.$el.classList.remove("open");
    }

    render() {
        const entries = [
            {
                to: "/profile",
                label: this.user.name,
            },
            {
                to: "/timeline",
                img: "/img/menu/timeline.svg",
                label: this.$t("Menu.timeline"),
            },
            {
                to: "/reports",
                img: "/img/menu/reports.svg",
                label: this.$t("Menu.reports"),
            },
            {
                to: "/requests",
                img: "/img/menu/requests.svg",
                label: this.$t("Menu.requests"),
                count: this.requestsCount,
            },
            {
                to: "/projects",
                img: "/img/menu/projects.svg",
                label: this.$t("Menu.projects"),
                count: 0,
            },
            {
                to: "/teams",
                img: "/img/menu/teams.svg",
                label: this.$t("Menu.teams"),
            },
        ];

        const items = entries.map((item) => (
            <router-link
                class={`menu-item${this.$route.path.includes(item.to) ? " active" : ""}`}
                to={item.to}
                nativeOnClick={this.closeMenu}
            >
                <div class="img-container">
                    {item.img ? (
                        <img class="img-icon" src={item.img} />
                    ) : (
                        <ProfileIcon
                            height={48}
                            type={COLLABORATOR_TYPE.USER}
                            avatar={this.user.avatar}
                            clickCb={() => this.openProfile()}
                            class="profileIcon"
                        />
                    )}
                    {item.count ? <div class="count">{item.count}</div> : ""}
                </div>
                <div class="label">{item.label}</div>
            </router-link>
        ));

        return (
            <div class="menu">
                <div class="logo active-previous">
                    <router-link to="/">
                        <div class="logo-container">
                            <img src="/img/logo.svg" />
                        </div>
                        <div class="title">WID</div>
                    </router-link>
                </div>
                <div class="menu-items" id="menuItems">
                    {items}
                </div>
                <div class="menu-footer" onclick={this.expandMenu}>
                    <div class="menu-footer-img-container">
                        <img src="/img/menu/close.svg" />
                    </div>
                    <div class="label">{this.$t("Menu.hide")}</div>
                </div>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
@import "../../../css/variables.scss";

//https://codepen.io/sosuke/pen/Pjoqqp

$background-color: #227093;
$menu-default-width: 100px;
$opened-menu-width: 250px;
$hamburger-width: 44px;
$menu-item-width: $menu-default-width;
$menu-item-height: 75px;
$menu-image-container-width: $menu-item-width;
$animation-duration: 200ms;
$border-size: 10px;

.menu {
    position: fixed;
    z-index: 39; // let's keep it under modals (which are z-index: 40)
    background: $background-color;
    width: $menu-default-width;
    height: 100%;
    border-radius: 0 5px 5px 0;

    transition: width $animation-duration linear;

    // Mobile menu: hamburger on top-right
    @media (max-width: 800px) {
        height: $hamburger-width;
        width: $hamburger-width;
        border-radius: $hamburger-width;
        position: fixed;
        top: 5px;
        right: 5px;

        // Hide all elements except footer when collapsed
        .logo,
        .menu-items,
        .avatar {
            display: none;
        }
        .menu-footer {
            height: $hamburger-width;
            top: 0;
            margin-top: 0px;
            .menu-footer-img-container {
                width: $hamburger-width;
            }
        }

        // Reveal elements when opened
        &.open {
            top: 0px;
            width: $opened-menu-width;
            border-radius: 0 5px 5px 0;
            right: 0px;
            height: 100%;
            .logo,
            .menu-items,
            .avatar {
                display: block;
            }

            .logo {
                padding-top: 10px;
                .logo-container {
                    float: left;
                }
                .title {
                    float: left;
                    width: 100px;
                }
            }
            .menu-footer {
                bottom: 0;
                margin-bottom: 25px;
                .menu-footer-img-container {
                    width: $menu-default-width;
                }
            }
        }
    }

    &.open {
        width: $opened-menu-width;

        .label,
        .title {
            width: 100%;
        }
        .menu-footer-img-container {
            img {
                transform: rotate(-180deg);
            }
        }
    }
}

.logo,
.menu-footer {
    width: 100%;
    display: flex;
    align-items: center;
    height: 70px;
    cursor: pointer;
}

.logo {
    margin-top: 25px;
    margin-bottom: 50px;
    justify-content: center;

    .title {
        justify-content: center;
        transition: none;
    }
}

.profileIcon {
    margin-right: $border-size;
}

.menu-footer {
    position: absolute;
    bottom: 0;
    margin-bottom: 25px;
}

.menu-footer-img-container,
.logo-container {
    width: $menu-default-width;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-items {
    background: repeating-linear-gradient(
        90deg,
        $background-color 0px,
        $background-color 35px,
        white 35px,
        white 90px
    );
}

.menu-item {
    display: flex;
    // justify-content: center;
    align-items: center;
    height: $menu-item-height;
    border-left: $border-size solid $primary;

    cursor: pointer;
    position: relative;
    background: $background-color;

    .img-container {
        width: $menu-image-container-width;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
    }

    .count {
        position: absolute;
        font-weight: bold;
        background-color: white;
        padding: 5px;
        border-radius: 100%;
        bottom: 5px;
        right: 3px;
        min-width: 31px;
        text-align: center;
        font-size: 0.9em;
    }

    .img-icon {
        height: 30px;
        margin-right: $border-size;
        filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(100deg) brightness(108%)
            contrast(101%);
    }

    &:hover {
        color: $primary;
    }
}

.label,
.title {
    color: white;
    width: 0;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    display: flex;
    transition: width $animation-duration linear;
}

.menu-item:hover:not(.active),
.menu-footer:hover {
    .img-container,
    .menu-footer-img-container,
    .label {
        opacity: 0.6;
    }
}

.active {
    background: #1b5a76;
    border-left: 10px solid white;
    border-radius: 0;

    .img-icon {
        filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(100deg) brightness(108%)
            contrast(101%);
    }
}

.active-next {
    border-radius: 5px 5px 0 5px;
}

.active-previous {
    border-radius: 5px 0 5px 5px;
}
</style>
