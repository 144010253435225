<script>
import Component from "vue-class-component";
import Vue from "vue";
import EmptyTable from "./EmptyTable.vue";

const Props = Vue.extend({
    props: {
        fetchData: Function,
        pageSize: {
            type: Number,
            default: () => 15,
        },
        defaultSort: String,
        orderBy: Object,
    },
});

@Component
export default class PaginatedRows extends Props {
    data = [];
    pageCount = 0;
    pageNumber = 1;
    sort = "";
    search = "";

    created() {
        this.pageNumber = parseInt(this.$route.query.page) || 1;
        this.sort = this.$route.query.sort || this.defaultSort || "";
        this.search = this.$route.query.search || "";

        this.loadData();
    }

    onPageChange(pageNumber) {
        this.pageNumber = pageNumber;

        const query = { ...this.$route.query, page: this.pageNumber };
        this.$router.replace({ query });

        this.loadData();
    }

    onSort() {
        const selectedIndex = this.$refs.sort.selectedIndex;
        this.sort = this.$refs.sort[selectedIndex].value;

        const query = { ...this.$route.query, sort: this.sort };
        this.$router.replace({ query });

        this.loadData();
    }

    onSearch(e) {
        this.pageNumber = 1;
        this.search = this.$refs.search.newValue;

        const query = { ...this.$route.query, page: this.pageNumber, search: this.search };
        this.$router.replace({ query });

        this.loadData();

        e.preventDefault();
        e.stopPropagation();
    }

    clearSearch() {
        this.pageNumber = 1;
        this.search = "";
        this.$refs.search.newValue = "";

        const query = { ...this.$route.query, page: this.pageNumber, search: this.search };
        this.$router.replace({ query });

        this.loadData();
    }

    async loadData() {
        try {
            // this.data = [];
            this.fetching = true;

            const result = await this.fetchData({
                limit: this.pageSize,
                offset: (this.pageNumber - 1) * this.pageSize,
                order: this.sort,
                search: this.search,
            });

            this.pageCount = result.count;
            this.data = result.items || [];
        } catch {
            this.data = [];
        } finally {
            this.fetching = false;
        }
    }

    getData() {
        return this.data;
    }

    render() {
        return (
            <div class="rows-container">
                <form onsubmit={this.onSearch} class="selector">
                    <b-input
                        ref="search"
                        class="search"
                        ellipsis
                        icon="search"
                        value={this.search}
                        placeholder={this.$t("PaginatedRow.search")}
                        icon-right="close-circle"
                        icon-right-clickable
                        onicon-right-click={this.clearSearch}
                    ></b-input>
                    <div class="select ml-2">
                        <select ref="sort" onchange={this.onSort}>
                            <option value="">{this.$t("PaginatedRow.order")}</option>
                            {Object.entries(this.orderBy).map(([key, value]) => (
                                <option value={key} selected={this.sort === key}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div>
                </form>
                <EmptyTable
                    class="rows-empty"
                    text={this.$t("PaginatedRow.empty")}
                    visible={!this.fetching && this.data && !this.data.length}
                />

                <div class="rows">
                    {this.data.map((item) => {
                        return this.$scopedSlots.default(item);
                    })}
                </div>

                <b-pagination
                    total={this.pageCount}
                    per-page={this.pageSize}
                    current={this.pageNumber}
                    onchange={this.onPageChange}
                    simple
                    order="is-right"
                />
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.rows-container {
    height: 100%;
}

.selector {
    display: flex;
    align-items: center;
}

.search {
    width: 200px;
}

.rows {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.rows-empty {
    position: relative;
    height: 80%;
}
</style>
