<script>
import Component from "vue-class-component";
import Vue from "vue";

import { inject } from "../../base/Injection";

import { TimelineService } from "../../services/TimelineService";
import { diffValue, replaceEmoji } from "../../base/Utils";
import { endOfDay, format, isSameDay, startOfDay } from "date-fns";
import { TimerService } from "../../services/TimerService";
import EmptyTable from "../common/EmptyTable.vue";

@Component
export default class TasksList extends Vue {
    @inject(TimelineService) timelineService;
    @inject(TimerService) timerService;
    tasks = [];
    total = 0;
    fetching = false;

    created() {
        this.update();
    }

    async update() {
        try {
            if (!isSameDay(this.currentDate, this.timelineService.currentDate)) {
                this.tasks = [];
                this.total = 0;

                this.fetching = true;
                this.currentDate = this.timelineService.currentDate;
            }

            this.total = await this.timerService.getTotal();

            const startDay = startOfDay(this.timelineService.currentDate);
            const endDay = endOfDay(this.timelineService.currentDate);

            const tasks = await this.timelineService.getTasks();
            this.tasks = tasks.map((task) => ({
                ...task,
                duration: Math.min(endDay, task.endDate) - Math.max(startDay, task.startDate),
            }));
        } catch {
            this.tasks = [];
        } finally {
            this.fetching = false;
        }
    }

    openTask(task) {
        this.$router
            .push({
                path: `/timeline/task/${task.id}`,
            })
            .catch(() => {});
    }

    editTask(task) {
        this.$router
            .push({
                path: `/timeline/edit/${task.id}`,
            })
            .catch(() => {});
    }

    toggleTimerTask(e, task) {
        if (task.running) {
            this.timerService.stop();
        } else {
            this.timerService.start(task);
        }

        e.preventDefault();
        e.stopPropagation();
    }

    saveSort(field, order) {
        this.timelineService.setUserPreferences({
            sort_field: field,
            sort_order: order,
        });
    }

    render() {
        const { sort_field, sort_order } = this.timelineService.getUserPreferences();

        const footer =
            this.tasks && this.tasks.length ? (
                <template slot="footer">
                    <th colspan="6">{this.$t("TasksList.total")}</th>
                    <th>{diffValue(this.total)}</th>
                </template>
            ) : (
                ""
            );

        return (
            <div class="page-full page-table">
                <b-table
                    key={`table-${this.timelineService.currentDate}`}
                    data={this.tasks}
                    default-sort={sort_field || "startDate"}
                    default-sort-direction={sort_order || "desc"}
                    sort-icon="chevron-up"
                    sort-icon-size="is-small"
                    hoverable
                    onClick={this.openTask}
                    onDblclick={this.editTask}
                    onSort={this.saveSort}
                    loading={this.fetching}
                >
                    <EmptyTable
                        text={this.$t("TasksList.empty")}
                        icon="fa-clock-o"
                        visible={!this.fetching && this.tasks && !this.tasks.length}
                    />
                    {footer}

                    <b-table-column
                        cell-class="fix-size-icon"
                        scopedSlots={{
                            default: ({ row: task }) => {
                                if (!task.running) {
                                    return;
                                }

                                return (
                                    <span class="icon mr-1 has-text-grey">
                                        <i class="fa fa-play"></i>
                                    </span>
                                );
                            },
                        }}
                    />

                    <b-table-column
                        field="title"
                        label={this.$t("TasksList.project")}
                        sortable
                        scopedSlots={{
                            default: ({ row: task }) => (
                                <span>
                                    <span class={`tag color-${task.color}`}>
                                        {task.project || this.$t("TasksList.unknown")}
                                    </span>
                                </span>
                            ),
                        }}
                    />

                    <b-table-column
                        field="title"
                        label={this.$t("TasksList.title")}
                        sortable
                        scopedSlots={{
                            default: ({ row: task }) => replaceEmoji(task.title),
                        }}
                    />

                    <b-table-column
                        label={this.$t("TasksList.tags")}
                        scopedSlots={{
                            default: ({ row: task }) => (
                                <div class="tags">
                                    {task.tags &&
                                        task.tags.map((tag) => (
                                            <span class="tag is-primary is-rounded">{tag}</span>
                                        ))}
                                </div>
                            ),
                        }}
                    />

                    <b-table-column
                        field="startDate"
                        label={this.$t("TasksList.startDate")}
                        cell-class="fix-size"
                        sortable
                        scopedSlots={{
                            default: ({ row: task }) => format(task.startDate, "HH:mm:ss"),
                        }}
                    />

                    <b-table-column
                        field="endDate"
                        label={this.$t("TasksList.endDate")}
                        cell-class="fix-size"
                        sortable
                        scopedSlots={{
                            default: ({ row: task }) => format(task.endDate, "HH:mm:ss"),
                        }}
                    />

                    <b-table-column
                        field="duration"
                        label={this.$t("TasksList.duration")}
                        cell-class="fix-size"
                        sortable
                        scopedSlots={{
                            default: ({ row: task }) => diffValue(task.duration),
                        }}
                    />
                </b-table>
            </div>
        );
    }
}
</script>
<style lang="scss">
.action-label {
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: bold;
}
</style>
