<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";

import { UserService } from "../../services/UserService";

@Component
export default class Signin extends Vue {
    @inject(UserService) userService;

    mounted() {
        this.$refs.email.focus();
    }

    async signIn() {
        let isValid = Object.values(this.$refs).reduce((acc, node) => {
            return (!node.checkHtml5Validity || node.checkHtml5Validity()) && acc;
        }, true);
        if (!isValid) {
            return;
        }

        const email = this.$refs.email.newValue;
        const password = this.$refs.password.newValue;

        try {
            await this.userService.signIn(email, password);
            this.$router.push("/timeline").catch(() => {});
        } catch (e) {
            if (e.status === 406) {
                this.$buefy.toast.open({
                    message: this.$t("Signin.error"),
                    type: "is-danger",
                });
            } else {
                this.$buefy.toast.open({
                    message: this.$t("error.server"),
                    type: "is-danger",
                });
            }
        }
    }

    submit(e) {
        this.signIn();

        e.preventDefault();
        e.stopPropagation();
    }

    render() {
        return (
            <form onsubmit={this.submit}>
                <b-field label={this.$t("Signin.email")}>
                    <b-input
                        ref="email"
                        placeholder="Email"
                        type="email"
                        required
                        placeholder={this.$t("Signin.email-placeholder")}
                    ></b-input>
                </b-field>
                <b-field label={this.$t("Signin.password")}>
                    <b-input
                        ref="password"
                        type="password"
                        required
                        placeholder={this.$t("Signin.password-placeholder")}
                    ></b-input>
                </b-field>
                <div class="control has-text-right">
                    <button class="button is-primary is-fullwidth">
                        {this.$t("Signin.submit")}
                    </button>
                </div>
                <a href="/resetpwd" class="link">
                    {this.$t("Signin.resetpwd")}
                </a>
            </form>
        );
    }
}
</script>

<style lang="scss" scoped>
.link {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.75rem;
}
</style>
