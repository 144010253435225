<script>
import Component from "vue-class-component";
import Vue from "vue";
import { TimelineService } from "../../services/TimelineService";
import { inject } from "../../base/Injection";
import { replaceEmoji } from "../../base/Utils";

@Component
export default class ActivitiesDropDownMenu extends Vue {
    @inject(TimelineService) timelineService;

    activities = [];
    activitiesFetched = false;

    async getActivities(opening) {
        if (opening) {
            try {
                this.activitiesFetched = false;
                this.activities = (await this.timelineService.getActivities()) || [];
                this.activitiesFetched = true;
            } catch {
                this.$buefy.toast.open({
                    message: this.$t("error.server"),
                    type: "is-danger",
                });
            }
        }
    }

    onChange(index) {
        const activity = this.activities[index];
        this.$emit("change", activity);
    }

    render() {
        return (
            <b-dropdown
                aria-role="list"
                onchange={this.onChange}
                onactive-change={this.getActivities}
            >
                <a slot="trigger" class="button is-primary is-outlined is-rounded">
                    <div class="icon mr-1">
                        <i class="fa fa-calendar-plus-o"></i>
                    </div>
                    {this.$t("Activities.label")}
                </a>

                {this.activities.length === 0 && (
                    <b-dropdown-item aria-role="listitem" expanded class="has-text-centered">
                        {this.activitiesFetched
                            ? this.$t("Activities.empty")
                            : this.$t("Activities.fetching")}
                    </b-dropdown-item>
                )}

                {this.activities.length > 0 &&
                    this.activities.map((a, index) => (
                        <b-dropdown-item aria-role="listitem" class="activity-item" value={index}>
                            <span class={`tag mr-2 color-${a.projectColor}`}>
                                {a.projectName || this.$t("TaskForm.unknown")}
                            </span>
                            {replaceEmoji(a.title)}
                        </b-dropdown-item>
                    ))}
            </b-dropdown>
        );
    }
}
</script>

<style lang="scss" scoped>
.history-item {
    width: 400px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.is-active {
        background-color: transparent;
        color: #4a4a4a;
    }
}
</style>
