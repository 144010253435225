<script>
import Component from "vue-class-component";
import Vue from "vue";

import { inject } from "../base/Injection";
import { TagService } from "../services/TagService";
import EmptyTable from "../components/common/EmptyTable.vue";

@Component
export default class TagsPage extends Vue {
    @inject(TagService) tagService;

    tags = [];
    fetching = false;

    async created() {
        try {
            this.tags = [];
            this.fetching = true;

            const tags = (await this.tagService.getTags()) || [];
            this.tags = tags.map((tag) => ({
                name: tag,
            }));
        } catch (e) {
            this.tags = [];
        } finally {
            this.fetching = false;
        }
    }

    render() {
        return (
            <div class="page-content">
                <div class="page-title">{this.$t("TagsPage.title")}</div>
                <div class="page-full page-table">
                    <div class="field-container">
                        <b-table
                            data={this.tags}
                            default-sort={"name"}
                            default-sort-direction={"asc"}
                            sort-icon="chevron-up"
                            sort-icon-size="is-small"
                            hoverable
                            loading={this.fetching}
                        >
                            <EmptyTable
                                text={this.$t("TagsPage.empty")}
                                visible={!this.fetching && this.tags && !this.tags.length}
                            />

                            <b-table-column
                                label={this.$t("TagsPage.name")}
                                field="name"
                                sortable
                                scopedSlots={{
                                    default: ({ row: tag }) => tag.name,
                                }}
                            />
                        </b-table>
                    </div>
                </div>
            </div>
        );
    }
}
</script>
