<script>
import Component from "vue-class-component";
import Vue from "vue";

const Props = Vue.extend({
    props: {
        value: String,
        size: String,
        disabled: Boolean,
    },
});

@Component
export default class Permission extends Props {
    newValue = this.value;

    getValue() {
        return this.newValue;
    }

    onChange(value) {
        this.newValue = value;
        this.$emit("input", this.newValue);
    }

    render() {
        return (
            <b-dropdown
                aria-role="list"
                onchange={this.onChange}
                value={this.newValue}
                disabled={this.disabled}
            >
                <button
                    class={"button is-rounded " + (this.size || "is-normal")}
                    type="button"
                    slot="trigger"
                    key={this.newValue}
                >
                    {this.newValue === "maintainer"
                        ? [
                              <b-icon icon="wrench"></b-icon>,
                              <span>Maintainer</span>,
                              <b-icon icon="caret-down"></b-icon>,
                          ]
                        : ""}
                    {this.newValue === "reporter"
                        ? [
                              <b-icon icon="pencil"></b-icon>,
                              <span>Reporter</span>,
                              <b-icon icon="caret-down"></b-icon>,
                          ]
                        : ""}
                </button>

                <b-dropdown-item value="maintainer" aria-role="listitem">
                    <div class="media">
                        <b-icon class="media-left" icon="wrench"></b-icon>
                        <div class="media-content">
                            <h3>{this.$t("Permission.maintainer")}</h3>
                        </div>
                    </div>
                </b-dropdown-item>

                <b-dropdown-item value="reporter" aria-role="listitem">
                    <div class="media">
                        <b-icon class="media-left" icon="pencil"></b-icon>
                        <div class="media-content">
                            <h3>{this.$t("Permission.reporter")}</h3>
                        </div>
                    </div>
                </b-dropdown-item>
            </b-dropdown>
        );
    }
}
</script>

<style lang="scss" scoped>
.is-normal {
    width: 155px;
}

.is-small {
    width: 120px;
}
</style>
