var render, staticRenderFns
import script from "./SitePage.vue?vue&type=script&lang=js&"
export * from "./SitePage.vue?vue&type=script&lang=js&"
import style0 from "./SitePage.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./SitePage.vue?vue&type=style&index=1&id=5553effe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5553effe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5553effe')) {
      api.createRecord('5553effe', component.options)
    } else {
      api.reload('5553effe', component.options)
    }
    
  }
}
component.options.__file = "src/js/pages/SitePage.vue"
export default component.exports