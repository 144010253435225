<script>
import Component from "vue-class-component";
import Vue from "vue";
import {
    startOfDay,
    endOfDay,
    startOfWeek,
    endOfWeek,
    startOfMonth,
    endOfMonth,
    startOfYear,
    endOfYear,
} from "date-fns";
import { REPORT_FREQUENCY } from "../../services/ReportService";

const Props = Vue.extend({
    props: {
        type: String,
        start: Date,
        end: Date,
        isCreation: Boolean,
        firstDayOfWeek: Number,
    },
});

@Component
export default class DatePicker extends Props {
    selectDate(date) {
        this.$emit("dateUpdated", [startOfDay(date), endOfDay(date)]);
    }

    selectWeek(date) {
        this.$emit("dateUpdated", [
            startOfWeek(date, { weekStartsOn: this.firstDayOfWeek }),
            endOfWeek(date, { weekStartsOn: this.firstDayOfWeek }),
        ]);
    }

    selectMonth(date) {
        this.$emit("dateUpdated", [startOfMonth(date), endOfMonth(date)]);
    }

    selectYear(date) {
        this.$emit("dateUpdated", [startOfYear(date), endOfYear(date)]);
    }

    selectRange(date) {
        this.$emit("dateUpdated", [startOfDay(date[0]), endOfDay(date[1])]);
    }

    render() {
        switch (this.type) {
            case REPORT_FREQUENCY.DAY:
                return (
                    this.isCreation && (
                        <b-datepicker
                            value={this.start}
                            oninput={(date) => this.selectDate(date)}
                            first-day-of-week={this.firstDayOfWeek}
                        >
                            <template slot="trigger">
                                <b-button icon-left="calendar" type="is-primary" size="is-small" />
                            </template>
                        </b-datepicker>
                    )
                );

            case REPORT_FREQUENCY.WEEK:
                return (
                    this.isCreation && (
                        <b-datepicker
                            value={this.start}
                            oninput={(date) => this.selectWeek(date)}
                            first-day-of-week={this.firstDayOfWeek}
                        >
                            <template slot="trigger">
                                <b-button icon-left="calendar" type="is-primary" size="is-small" />
                            </template>
                        </b-datepicker>
                    )
                );

            case REPORT_FREQUENCY.MONTH:
                return (
                    this.isCreation && (
                        <b-datepicker
                            type="month"
                            value={this.start}
                            oninput={(date) => this.selectMonth(date)}
                            first-day-of-week={this.firstDayOfWeek}
                        >
                            <template slot="trigger">
                                <b-button icon-left="calendar" type="is-primary" size="is-small" />
                            </template>
                        </b-datepicker>
                    )
                );

            case REPORT_FREQUENCY.YEAR:
                return (
                    this.isCreation && (
                        <b-datepicker
                            type="month"
                            value={this.start}
                            oninput={(date) => this.selectYear(date)}
                            first-day-of-week={this.firstDayOfWeek}
                        >
                            <template slot="trigger">
                                <b-button icon-left="calendar" type="is-primary" size="is-small" />
                            </template>
                        </b-datepicker>
                    )
                );

            case REPORT_FREQUENCY.CUSTOM:
                return (
                    <b-datepicker
                        range
                        value={[this.start, this.end]}
                        oninput={(date) => this.selectRange(date)}
                        first-day-of-week={this.firstDayOfWeek}
                    >
                        <template slot="trigger">
                            <b-button icon-left="calendar" type="is-primary" size="is-small" />
                        </template>
                    </b-datepicker>
                );
        }
    }
}
</script>
