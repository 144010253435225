<script>
import Component from "vue-class-component";
import Vue from "vue";

import { inject } from "../base/Injection";
import { ReportService, REPORT_FREQUENCY } from "../services/ReportService";
import ReportPeriod from "../components/report/ReportPeriod.vue";
import ReportChart from "../components/report/ReportChart.vue";
import { format } from "../base/Utils";
import BackButton from "../components/common/BackButton.vue";
import ProfileIcon from "../components/common/ProfileIcon.vue";
import { COLLABORATOR_TYPE } from "../services/ProjectService";

@Component
export default class ReportChartsPage extends Vue {
    @inject(ReportService) reportService;

    report = {};
    lateUsers = [];
    fetching = false;
    startDate = new Date();
    endDate = new Date();

    created() {
        this.init();
        this.initBound = () => this.init();
    }

    async init() {
        try {
            this.report = {};
            this.fetching = true;

            this.startDate = this.$route.query.startDate
                ? new Date(+this.$route.query.startDate)
                : new Date();
            this.endDate = this.$route.query.endDate
                ? new Date(+this.$route.query.endDate)
                : new Date();

            const id = this.$route.params.id;
            this.report = (await this.reportService.getReport(id)) || {};

            if (this.report.frequency === REPORT_FREQUENCY.CUSTOM) {
                this.startDate = new Date(this.report.startDate);
                this.endDate = new Date(this.report.endDate);
            }

            this.lateUsers =
                (await this.reportService.getLateValidationUsers(
                    id,
                    this.startDate,
                    this.endDate
                )) || [];
        } catch {
            this.report = {};
            this.lateUsers = [];
        } finally {
            this.fetching = false;
        }
    }

    beforeRouteEnter(to, from, next) {
        const previousPath = from.path;
        next((element) => {
            const path =
                previousPath.includes("dashboard") &&
                (previousPath.includes("edit") || previousPath.includes("new"))
                    ? `/reports/edit/${to.params.id}`
                    : "/reports";
            element.backCb = () => element.$router.push(path).catch(() => {});
        });
    }

    async updateDates(startDate, endDate) {
        this.startDate = startDate;
        this.endDate = endDate;

        this.lateUsers =
            (await this.reportService.getLateValidationUsers(
                this.$route.params.id,
                this.startDate,
                this.endDate
            )) || [];

        this.$router
            .replace(
                `/reports/charts/${
                    this.$route.params.id
                }?startDate=${+startDate}&endDate=${+endDate}`
            )
            .catch(() => {});
    }

    render() {
        const charts =
            this.report &&
            this.report.dataVisualisations &&
            this.report.dataVisualisations.map((type, index) => (
                <ReportChart
                    key={`chart-${this.startDate}-${index}`}
                    report={this.report}
                    startDate={this.startDate}
                    endDate={this.endDate}
                    type={type}
                />
            ));

        const users = this.lateUsers.map((u) => (
            <b-dropdown-item aria-role="listitem" class="has-text-left" onclick={() => {}}>
                <div class="user">
                    <ProfileIcon
                        name={u.name}
                        avatar={u.avatar}
                        height={32}
                        type={COLLABORATOR_TYPE.USER}
                        class="mr-2"
                    />
                    {u.name}
                </div>
            </b-dropdown-item>
        ));

        return (
            <div class="page-content">
                <div class="page-title">
                    <BackButton cb={() => this.backCb()} />
                    {this.$t("ReportChartsPage.title", {
                        name: this.report.name,
                    })}
                    <span class="page-desc">{this.report.description}</span>
                </div>
                <div class="page-full">
                    <div class="field-container">
                        <ReportPeriod
                            class="is-size-4 has-text-primary"
                            key={this.report.frequency}
                            noFrequency={true}
                            frequency={this.report.frequency}
                            start={this.startDate}
                            end={this.endDate}
                            onChange={this.updateDates}
                        />
                        <div class="has-text-centered mb-2">
                            {format(this.startDate, "EEEE dd MMMM yyyy")}
                            <span> - </span>
                            {format(this.endDate, "EEEE dd MMMM yyyy")}
                        </div>
                        {!this.report.synchronizable && (
                            <div
                                class={{
                                    users: true,
                                    "has-text-centered": true,
                                    "mb-5": true,
                                    "is-hidden": !this.lateUsers || !this.lateUsers.length,
                                }}
                            >
                                <b-dropdown aria-role="list">
                                    <p class="tag is-warning" slot="trigger" role="button">
                                        {this.$tc("ReportChartsPage.late", this.lateUsers.length, {
                                            count: this.lateUsers.length,
                                        })}
                                    </p>
                                    {users}
                                </b-dropdown>
                            </div>
                        )}
                        {this.report.synchronizable && (
                            <div
                                class={{
                                    users: true,
                                    "has-text-centered": true,
                                    "mb-5": true,
                                }}
                            >
                                <p class={{ tag: true, "is-warning": true }}>
                                    {this.$tc(
                                        !this.lateUsers || !this.lateUsers.length
                                            ? "ReportChartsPage.sync"
                                            : "ReportChartsPage.nosync"
                                    )}
                                </p>
                            </div>
                        )}
                        <div class="charts">{charts}</div>
                    </div>
                </div>
            </div>
        );
    }
}
</script>
<style lang="scss" scoped>
.charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > div {
        box-sizing: border-box;
        padding: 1rem;
        margin: 1.5rem;
        border: 1px solid #d2dae2;
        border-radius: 10px;
    }

    > div.counter,
    > div.bar,
    > div.pie {
        width: calc(50% - 3rem);
    }

    > div.table {
        width: calc(100% - 3rem);
        min-height: 300px;
    }
}

.users {
    cursor: pointer;
}

.user {
    display: flex;
    align-items: center;
}
</style>
