<script>
import Component from "vue-class-component";
import Vue from "vue";
import Signin from "./Signin.vue";
import Signup from "./Signup.vue";

@Component
export default class Login extends Vue {
    tabPosition = 0;

    created() {
        this.tabPosition = this.$attrs.tabPosition || this.tabPosition;
    }

    render() {
        return (
            <div class="tab-forms">
                <b-tabs
                    ref="tabs"
                    animated={false}
                    expanded={true}
                    destroy-on-hide={true}
                    value={this.tabPosition}
                >
                    <b-tab-item label={this.$t("Login.signin")}>
                        <Signin />
                    </b-tab-item>
                    <b-tab-item label={this.$t("Login.signup")}>
                        <Signup />
                    </b-tab-item>
                </b-tabs>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.tab-forms {
    box-shadow: 0 0 0 1px #e5e5e5;
    border-bottom-right-radius: 0.25rem;
    border-radius: 0.25rem;
    width: 460px;
}
</style>
