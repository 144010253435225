<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { TeamService } from "../../services/TeamService";
import PaginatedTable from "../common/PaginatedTable.vue";

const Props = Vue.extend({
    props: {
        team: Object,
    },
});

@Component
export default class AdvancedTeam extends Props {
    @inject(TeamService) teamService;

    disabledHost = false;

    mounted() {
        this.teamService.on("change", this.$refs.table.loadData);
    }

    beforeDestroy() {
        this.teamService.off("change", this.$refs.table.loadData);
    }

    async getRepositories(selector) {
        const id = this.$route.params.id;
        const repositories = await this.teamService.getRepositories(id, selector);
        return repositories;
    }

    async addRepo() {
        const category = this.$refs.category.selected;
        const name = this.$refs.name.newValue;
        const host = this.$refs.host.newValue;

        if (!category) {
            return;
        }
        try {
            await this.teamService.addRepository(this.team.id, { category, name, host });
            this.$refs.category.selected = null;
            this.$refs.name.newValue = "";
            this.$refs.host.newValue = "";
        } catch {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
        }
    }

    async removeRepo(repoId) {
        this.$buefy.dialog.confirm({
            title: this.$t("AdvancedTeam.repo.confirm.title"),
            message: this.$t("AdvancedTeam.repo.confirm.message"),
            confirmText: this.$t("AdvancedTeam.repo.confirm.confirmText"),
            type: "is-danger",
            hasIcon: true,
            onConfirm: async () => {
                try {
                    await this.teamService.removeRepository(repoId);
                    this.$buefy.toast.open({
                        message: this.$t("AdvancedTeam.repo.confirm.success"),
                        type: "is-success",
                    });
                } catch {
                    this.$buefy.toast.open({
                        message: this.$t("error.server"),
                        type: "is-danger",
                    });
                }
            },
        });
    }

    async removeTeam() {
        this.$buefy.dialog.confirm({
            title: this.$t("AdvancedTeam.remove.confirm.title"),
            message: this.$t("AdvancedTeam.remove.confirm.message"),
            confirmText: this.$t("AdvancedTeam.remove.confirm.confirmText"),
            type: "is-danger",
            hasIcon: true,
            onConfirm: async () => {
                try {
                    await this.teamService.removeTeam(this.team.id);
                    this.$buefy.toast.open({
                        message: this.$t("AdvancedTeam.remove.confirm.success"),
                        type: "is-success",
                    });
                    this.$router.replace("/teams").catch(() => {});
                } catch {
                    this.$buefy.toast.open({
                        message: this.$t("error.server"),
                        type: "is-danger",
                    });
                }
            },
        });
    }

    changeCategory(value) {
        if (value === "gitlab") {
            this.$refs.name.newValue = "Gitlab";
            this.$refs.host.newValue = "https://gitlab.com";
            this.disabledHost = false;
        }

        if (value === "github") {
            this.$refs.name.newValue = "Github";
            this.$refs.host.newValue = "https://github.com";
            this.disabledHost = true;
        }

        if (value === "jira") {
            this.$refs.name.newValue = "Jira";
            this.$refs.host.newValue = "https://www.atlassian.net";
            this.disabledHost = false;
        }

        if (value === "redmine") {
            this.$refs.name.newValue = "Redmine";
            this.$refs.host.newValue = "https://www.redmine.org";
            this.disabledHost = false;
        }
    }

    render() {
        return (
            <div class="columns">
                <div class="column is-8 is-offset-2">
                    <article class="message">
                        <div class="message-header">
                            <p>{this.$t("AdvancedTeam.repo.title")}</p>
                        </div>
                        <div class="message-body is-half repositories">
                            <div class="field-inline mb-2">
                                <div class="select-fixed">
                                    <b-select
                                        ref="category"
                                        placeholder={this.$t(
                                            "AdvancedTeam.repo.category-placeholder"
                                        )}
                                        icon="code-fork"
                                        required
                                        oninput={this.changeCategory}
                                    >
                                        <option value="gitlab">Gitlab</option>
                                        <option value="jira">Jira</option>
                                        <option value="github">Github</option>
                                        <option value="redmine">Redmine</option>
                                    </b-select>
                                </div>
                                <div class="fixed">
                                    <b-input
                                        ref="name"
                                        ellipsis
                                        icon="file"
                                        placeholder={this.$t("AdvancedTeam.repo.name-placeholder")}
                                        required
                                    />
                                </div>
                                <div class="fixed">
                                    <b-input
                                        disabled={this.disabledHost}
                                        ref="host"
                                        ellipsis
                                        icon="link"
                                        placeholder={this.$t("AdvancedTeam.repo.host-placeholder")}
                                        required
                                    />
                                </div>
                                <button
                                    class="button is-primary is-rounded is-outlined"
                                    onclick={this.addRepo}
                                >
                                    {this.$t("AdvancedTeam.repo.submit")}
                                </button>
                            </div>

                            <PaginatedTable
                                ref="table"
                                pageSize={5}
                                fetchData={this.getRepositories}
                                defaultSort="name_asc"
                            >
                                <b-table-column
                                    label={this.$t("AdvancedTeam.repo.name")}
                                    field="name"
                                    sortable
                                    scopedSlots={{
                                        default: ({ row: r }) => <span>{r.name}</span>,
                                    }}
                                />

                                <b-table-column
                                    label={this.$t("AdvancedTeam.repo.host")}
                                    field="name"
                                    sortable
                                    scopedSlots={{
                                        default: ({ row: r }) => <span>{r.host}</span>,
                                    }}
                                />

                                <b-table-column
                                    label={this.$t("AdvancedTeam.repo.action")}
                                    centered
                                    scopedSlots={{
                                        default: ({ row: r }) => (
                                            <b-button
                                                type="is-danger is-small"
                                                icon-right="trash"
                                                onclick={() => this.removeRepo(r.id)}
                                            />
                                        ),
                                    }}
                                />
                            </PaginatedTable>
                        </div>
                    </article>
                    <article class="message is-danger">
                        <div class="message-header">
                            <p>{this.$t("AdvancedTeam.remove.header")}</p>
                        </div>
                        <div class="message-body is-half">
                            <p>{this.$t("AdvancedTeam.remove.body")}</p>
                            <p>{this.$t("AdvancedTeam.remove.warning")}</p>
                            <div class="is-fullwidth has-text-right">
                                <button
                                    class="button is-primary is-rounded is-outlined is-danger"
                                    onclick={this.removeTeam}
                                >
                                    {this.$t("AdvancedTeam.remove.submit")}
                                </button>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.repositories {
    height: 510px;
}
</style>

<style lang="scss">
.select-fixed {
    width: 8rem;

    select {
        width: 8rem;
    }
}
</style>
