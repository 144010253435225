<script>
import Component from "vue-class-component";
import Vue from "vue";

const Props = Vue.extend({
    props: {
        index: String,
    },
});

@Component
export default class Color extends Props {
    select() {
        this.$el.classList.add("selected");
    }

    unselect() {
        this.$el.classList.remove("selected");
    }

    render() {
        return (
            <div
                class={`color color-${this.index}`}
                onclick={() => this.$emit("click", this)}
            ></div>
        );
    }
}
</script>

<style lang="scss" scoped>
.color {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    margin: 3px;
    border: 4px solid white;

    &.selected {
        border: 4px solid rgba(0, 0, 0, 0.5);
    }
}
</style>
