<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { UserService } from "../../services/UserService";

@Component
export default class Repos extends Vue {
    @inject(UserService) userService;

    searchRepos = [];
    isFetching = false;

    async onInputRepos(name) {
        this.isFetching = true;
        try {
            this.searchRepos =
                (await this.userService.getRepositories({ search: name })).items || [];
        } catch {
            this.searchRepos = [];
        }
        this.isFetching = false;
    }

    getSelectedRepoId() {
        return this.$refs.repos.selected && this.$refs.selected.repos.id;
    }

    clearSelectedRepo() {
        this.$refs.repos.newValue = "";
    }

    onSelectRepo() {
        this.$emit("select", this.$refs.repos.selected);
    }

    render() {
        return (
            <b-autocomplete
                ref="repos"
                placeholder={this.$t("Repos.placeholder")}
                keep-first
                data={this.searchRepos}
                oninput={this.onInputRepos}
                onselect={this.onSelectRepo}
                field="name"
                loading={this.isFetching}
                scopedSlots={{
                    default: (props) => {
                        return (
                            <div>
                                {props.option.name} [{props.option.host}]{" "}
                                {props.option.credential
                                    ? this.$t("Repos.status-connected")
                                    : this.$t("Repos.status-notconnected")}
                            </div>
                        );
                    },
                }}
            >
                <template slot="empty">{this.$t("Repos.empty")}</template>
            </b-autocomplete>
        );
    }
}
</script>
