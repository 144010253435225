import Page from "./components/layout/Page";
import TasksList from "./components/timeline/TasksList";
import TaskForm from "./components/timeline/TaskForm";
import LoginPage from "./pages/LoginPage";
import ProjectsPage from "./pages/ProjectsPage";
import TimelinePage from "./pages/TimelinePage";
import TeamsPage from "./pages/TeamsPage";
import EditTeamPage from "./pages/EditTeamPage";
import ReportsPage from "./pages/ReportsPage";
import RequestsPage from "./pages/RequestsPage";
import ValidationSheetPage from "./pages/ValidationSheetPage";
import CreateReportPage from "./pages/CreateReportPage";
import TagsPage from "./pages/TagsPage";
import EditProjectPage from "./pages/EditProjectPage";
import Login from "./components/login/Login";
import ResetPwd from "./components/pwdRecovery/ResetPwd";
import UpdatePwd from "./components/pwdRecovery/UpdatePwd";
import ProfilePage from "./pages/ProfilePage";
import ReportChartsPage from "./pages/ReportChartsPage";
import ConfirmEmail from "./components/login/ConfirmEmail";
import SitePage from "./pages/SitePage";

export const routes = [
    {
        path: "/",
        component: SitePage,
    },
    { path: "/login", component: LoginPage, children: [{ path: "", component: Login }] },
    {
        path: "/register",
        component: LoginPage,
        children: [{ path: "", component: Login, props: { tabPosition: 1 } }],
    },
    {
        path: "/projects",
        component: Page,
        children: [
            { path: "", component: ProjectsPage },
            { path: "edit/:id", component: EditProjectPage },
        ],
    },
    {
        path: "/timeline",
        component: Page,
        children: [
            {
                path: "",
                component: TimelinePage,
                children: [
                    {
                        path: "",
                        component: TasksList,
                    },
                ],
            },
        ],
    },
    {
        path: "/timeline",
        component: Page,
        children: [
            {
                path: "edit/:id",
                component: TimelinePage,
                props: true,
                children: [
                    {
                        path: "",
                        component: TaskForm,
                    },
                ],
            },
        ],
    },
    {
        path: "/timeline",
        component: Page,
        children: [
            {
                path: "task/:id",
                component: TimelinePage,
                props: true,
                children: [
                    {
                        path: "",
                        component: TasksList,
                    },
                ],
            },
        ],
    },
    {
        path: "/teams",
        component: Page,
        children: [
            { path: "", component: TeamsPage },
            { path: "edit/:id", component: EditTeamPage },
        ],
    },
    {
        path: "/reports",
        component: Page,
        children: [
            { path: "", component: ReportsPage },
            { path: "new", component: CreateReportPage },
            {
                path: "charts/:id",
                component: ReportChartsPage,
            },
            { path: "edit/:id", component: CreateReportPage },
        ],
    },
    {
        path: "/requests",
        component: Page,
        children: [
            { path: "", component: RequestsPage },
            { path: ":id", component: ValidationSheetPage },
        ],
    },
    {
        path: "/tags",
        component: Page,
        children: [{ path: "", component: TagsPage }],
    },
    {
        path: "/profile",
        component: Page,
        children: [{ path: "", component: ProfilePage }],
    },
    {
        path: "/resetpwd",
        component: LoginPage,
        children: [{ path: "", component: ResetPwd }],
    },
    {
        path: "/updatepwd",
        component: LoginPage,
        children: [{ path: "", component: UpdatePwd }],
    },
    {
        path: "/confirm",
        component: LoginPage,
        children: [{ path: "", component: ConfirmEmail }],
    },
];
