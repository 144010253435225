import { Service } from "../base/Service";
import { inject } from "../base/Injection";
import { Fetcher } from "../base/Fetcher";
import { startOfDay, endOfDay } from "date-fns";

export const REPORT_FREQUENCY = {
    DAY: "day",
    WEEK: "week",
    MONTH: "month",
    YEAR: "year",
    CUSTOM: "custom",
};

export const PINNING = {
    PINNED: "PINNED",
    UNPINNED: "UNPINNED",
};

const zeroTime = -62135596800000;

export class Report {
    constructor() {
        this.id = null;
        this.name = "";
        this.description = "";
        this.frequency = REPORT_FREQUENCY.DAY;
        this.startDate = null;
        this.endDate = null;
        this.projects = [];
        this.tags = [];
        this.dataVisualisations = [];
        this.collaborators = [];
        this.owner = null;
        this.pinning = PINNING.UNPINNED;
    }
}

// export class ReportedTask {
//     constructor() {
//         this.duration = null;
//         this.projectColor = "";
//         this.projectId = "";
//         this.projectName = "";
//         this.tags = [];
//         this.title = "";
//     }
// }

export const DEFAULT_DAY_DURATION = 7 * 3600;

export class ReportService extends Service {
    @inject(Fetcher) fetcher;

    saveReport(report) {
        return this.fetcher.post("/report", {
            id: report.id,
            name: report.name,
            description: report.description,
            frequency: report.frequency,
            startDate: report.startDate && startOfDay(report.startDate),
            endDate: report.endDate && endOfDay(report.endDate),
            tags: report.tags,
            dataVisualisations: report.dataVisualisations,
            durationDisplayModeExactDuration: report.durationDisplayModeExactDuration,
            durationDisplayModeHoursPerDay: report.durationDisplayModeHoursPerDay,
            durationDisplayModePortionOfTotal: report.durationDisplayModePortionOfTotal,
            pinning: report.pinning,
            synchronizable: report.synchronizable,
        });
    }

    async updatePinning(reportId, pinning) {
        await this.fetcher.post(`/report/${reportId}/pinning`, pinning);
        this.fireChange();
    }

    async removeReport(id) {
        await this.fetcher.delete(`/report/${id}`);
        this.fireChange();
    }

    async addProjects(reportID, IDs) {
        return this.fetcher.post(`/report/${reportID}/projects`, IDs);
    }

    async addUsers(reportId, userIds) {
        return this.fetcher.post(`/report/${reportId}/users`, userIds);
    }

    async addTeams(reportId, teamIds) {
        return this.fetcher.post(`/report/${reportId}/teams`, teamIds);
    }

    async share(reportId, userIds) {
        await this.fetcher.post(`/report/${reportId}/share`, userIds);
        this.fireChange();
    }

    async getCollaborators(reportId, ids) {
        return this.fetcher.post(`/report/${reportId}/collaborators`, ids);
    }

    async getLateValidationUsers(
        reportId,
        startDate = startOfDay(new Date()),
        endDate = endOfDay(new Date()),
    ) {
        return this.fetcher.post("/request/late", {
            reportId,
            startDate,
            endDate,
        });
    }

    async getReportChart(
        type,
        reportId,
        startDate = startOfDay(new Date()),
        endDate = endOfDay(new Date()),
    ) {
        const mapping = {
            total_project: "/chart/total/project",
            project_tag: "/chart/project/tags",
            total_tag: "/chart/total/tag",
            total_task: "/chart/total/task",
            total_user: "/chart/total/user",
            total_days: "/chart/total/days",
        };

        const chart = await this.fetcher.post(mapping[type], {
            reportId,
            startDate,
            endDate,
            timezone: startDate.getTimezoneOffset(),
        });

        let total = 0;
        if (chart.datasets) {
            chart.datasets.forEach((ds) => {
                ds.total = 0;

                if (ds.data) {
                    ds.data.forEach((d) => {
                        total += d;
                        ds.total += d;
                    });
                }
            });
            return { ...chart, total };
        }

        return chart;
    }

    async getReport(reportId) {
        const data = await this.fetcher.get(`/report/${reportId}`);
        const report = data.report;
        if (new Date(report.startDate).getTime() === zeroTime) {
            report.startDate = null;
        }
        if (new Date(report.endDate).getTime() === zeroTime) {
            report.endDate = null;
        }
        return {
            ...report,
            projects: data.projects,
            collaborators: [...data.users, ...data.teams],
        };
    }

    async getAllReports(selector) {
        const datas = await this.fetcher.get("/reports", selector);
        if (!datas.items) {
            return datas;
        }

        datas.items = datas.items.map((data) => {
            const report = data.report;
            if (new Date(report.startDate).getTime() === zeroTime) {
                report.startDate = null;
            }
            if (new Date(report.endDate).getTime() === zeroTime) {
                report.endDate = null;
            }
            return {
                ...report,
                projects: data.projects || [],
                collaborators: [...data.users, ...data.teams],
            };
        });
        return datas;
    }

    fireChange() {
        this.emit("change");
    }

    async getRequests(selector) {
        const datas = await this.fetcher.get(`/requests`, selector);
        if (!datas.items) {
            return datas;
        }

        datas.items = datas.items.map((data) => {
            const report = data.report;
            if (new Date(report.startDate).getTime() === zeroTime) {
                report.startDate = null;
            }
            if (new Date(report.endDate).getTime() === zeroTime) {
                report.endDate = null;
            }

            return {
                report: {
                    ...report,
                    projects: data.projects,
                    validationSheetsLateCount: data.validationSheetsLateCount,
                },
            };
        });
        return datas;
    }

    async getRequest(reportId) {
        const data = (await this.fetcher.get(`/request/${reportId}`)) || [];
        const report = data.report;
        if (new Date(report.startDate).getTime() === zeroTime) {
            report.startDate = null;
        }
        if (new Date(report.endDate).getTime() === zeroTime) {
            report.endDate = null;
        }

        return {
            report: { ...report, projects: data.projects },
        };
    }

    async getReportedTasks(report, startDate, endDate) {
        const tasks =
            (await this.fetcher.post(`/request/tasks`, {
                ReportID: report.id,
                StartDate: startDate && startOfDay(startDate),
                EndDate: endDate && endOfDay(endDate),
            })) || {};

        tasks.status = report.synchronizable ? "sheet_status.sync" : "sheet_status.validated";
        if (tasks.reported && !tasks.reported.every((t) => t.validated)) {
            tasks.status = report.synchronizable
                ? "sheet_status.toReSync"
                : "sheet_status.toReValidate";
        }
        if (!tasks.validated) {
            tasks.status = report.synchronizable
                ? "sheet_status.toSync"
                : "sheet_status.toValidate";
        }
        if (!tasks.reported) {
            tasks.status = "sheet_status.noData";
        }

        return tasks;
    }

    async saveReportValidation(reportId, startDate, endDate, reportedTasks) {
        await this.fetcher.post("/request/sheet", {
            period: {
                reportId: reportId,
                startDate: startDate && startOfDay(startDate),
                endDate: endDate && endOfDay(endDate),
            },
            tasks: reportedTasks,
        });
        this.fireChange();
    }

    async syncReportValidation(reportId, startDate, endDate, reportedTasks) {
        await this.fetcher.post("/request/sync", {
            period: {
                reportId: reportId,
                startDate: startDate && startOfDay(startDate),
                endDate: endDate && endOfDay(endDate),
                timezone: startDate.getTimezoneOffset(),
            },
            tasks: reportedTasks,
        });
        this.fireChange();
    }
}
