<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../base/Injection";
import { UserService } from "../services/UserService";

const Props = Vue.extend({
    props: {
        repo: Object,
    },
});

@Component
export default class ConnectModal extends Props {
    @inject(UserService) userService;

    async submit(e) {
        e.stopPropagation();
        e.preventDefault();

        if (this._request) {
            return;
        }

        const button = this.$refs.submit;
        button.classList.add("is-loading");

        const username = this.$refs.username.newValue;
        const token = this.$refs.token.newValue;
        this._request = this.userService.saveAuthRepo(this.repo, username, token);

        try {
            await this._request;
            this.$buefy.toast.open({
                message: this.$t("ConnectModal.connected", { name: this.repo.name }),
                type: "is-success",
            });

            this.$emit("close", true);
        } catch (e) {
            this.$buefy.toast.open({
                message: this.$t("ConnectModal.error"),
                type: "is-danger",
            });
        } finally {
            this._request = null;
            button.classList.remove("is-loading");
        }
    }

    render() {
        return (
            <form onsubmit={this.submit}>
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">
                            {this.$t("ConnectModal.title", { name: this.repo.name })}
                        </p>
                    </header>
                    <section class="modal-card-body">
                        <b-field label={this.$t("ConnectModal.accountId")}>
                            <b-input
                                ref="username"
                                type="text"
                                placeholder={this.$t("ConnectModal.accountId-placeholder")}
                                required
                            ></b-input>
                        </b-field>

                        <b-field label={this.$t("ConnectModal.token")}>
                            <b-input
                                ref="token"
                                type="password"
                                password-reveal
                                placeholder={this.$t("ConnectModal.token-placeholder")}
                                required
                            ></b-input>
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <button
                            class="button"
                            type="button"
                            onclick={() => this.$emit("close", false)}
                        >
                            {this.$t("ConnectModal.close")}
                        </button>
                        <button ref="submit" class="button is-primary">
                            {this.$t("ConnectModal.submit")}
                        </button>
                    </footer>
                </div>
            </form>
        );
    }
}
</script>
