<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { ReportService } from "../../services/ReportService";
import ReportChartPie from "./ReportChartPie.vue";
import ReportChartBar from "./ReportChartBar.vue";
import ReportChartTable from "./ReportChartTable.vue";
import ReportChartCounter from "./ReportChartCounter.vue";
import { format, durationStringAccordingToDisplayModes } from "../../base/Utils";

const colors = [
    "#079992",
    "#0a3d62",
    "#60a3bc",
    "#e58e26",
    "#b71540",
    "#e55039",
    "#2ed573",
    "#222f3e",
    "#8395a7",
    "#e84393",
    "#3b3b98",
    "#cc8e35",
    "#58b19f",
    "#45aaf2",
    "#c56cf0",
];

const Props = Vue.extend({
    props: {
        report: Object,
        startDate: Date,
        endDate: Date,
        type: String,
    },
});

@Component
export default class ReportChart extends Props {
    @inject(ReportService) reportService;

    chartdata = null;

    async created() {
        if (!this.report) {
            return;
        }
        if (+this.startDate === +this.endDate) {
            return;
        }

        // Default value
        if (this.type.endsWith("_counter")) {
            this.chartdata = 0;
        } else {
            this.chartdata = {};
        }

        // Get the datas
        this.shortType = this.type
            .replace("_pie", "")
            .replace("_bar", "")
            .replace("_table", "")
            .replace("_counter", "");

        this.chartdata = await this.reportService.getReportChart(
            this.shortType,
            this.report.id,
            this.startDate,
            this.endDate
        );

        if (this.type.endsWith("_counter")) {
            return;
        }

        // Transform the datasets in order to inject the colors
        if (!this.chartdata.datasets) {
            this.chartdata.datasets = [];
            return;
        }

        const defaultColors = [];
        for (
            let position = 0;
            position < (this.chartdata.labels ? this.chartdata.labels.length : 0);
            position++
        ) {
            defaultColors[position] = colors[position % colors.length];
        }

        if (this.chartdata.labels) {
            this.chartdata.labels = this.chartdata.labels.map((l) =>
                l.replace("{unknown}", this.$t("ReportChart.unknown"))
            );
        }

        this.chartdata.datasets.forEach((ds) => {
            if (!ds.backgroundColor) {
                ds.backgroundColor = defaultColors;
            } else {
                ds.backgroundColor = ds.backgroundColor.map((c) => colors[c]);
            }
            ds.label = ds.label.replace("{unknown}", this.$t("ReportChart.unknown"));
        });
    }

    copy() {
        let content = `${this.report.name} (${format(
            this.startDate,
            "EEE dd MMMM yyyy"
        )} - ${format(this.endDate, "EEE dd MMMM yyyy")})\n`;

        this.chartdata.datasets.forEach((ds) => {
            if (ds.label) {
                content += `\t- ${ds.label}\n`;
            }
            if (ds.data) {
                ds.data.forEach((d, index) => {
                    content +=
                        (ds.label ? "\t" : "") +
                        "\t* " +
                        this.chartdata.labels[index] +
                        " " +
                        durationStringAccordingToDisplayModes(
                            this._i18n,
                            this.report,
                            d,
                            this.chartdata.total
                        ) +
                        "\n";
                });
            }

            content += `\t ${this.$t("ReportChart.total")} ${durationStringAccordingToDisplayModes(
                this._i18n,
                this.report,
                this.chartdata.total,
                ds.total
            )} \n\n`;
        });

        const listener = (ev) => {
            ev.preventDefault();
            ev.clipboardData.setData("text/plain", content);
        };
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
    }

    exportCsv() {
        let content = "";

        this.chartdata.datasets.forEach((ds) => {
            content += ";" + ds.label;
        });
        content += "\n";
        this.chartdata.labels.forEach((label, index) => {
            content += label;

            this.chartdata.datasets.forEach((ds) => {
                content +=
                    ";" +
                    durationStringAccordingToDisplayModes(
                        this._i18n,
                        this.report,
                        ds.data[index],
                        ds.total
                    );
            });

            content += "\n";
        });

        const data = new Blob([content], { type: "text/csv" });
        const url = URL.createObjectURL(data);

        window.location.href = url;
    }

    async exportImage() {
        const chart = this.$refs.chart.getChart();
        const img = chart.toBase64Image();
        const base64Response = await fetch(img);
        const blob = await base64Response.blob();
        const url = URL.createObjectURL(blob);
        window.location.href = url;
    }

    render() {
        if (!this.type || (!this.type.endsWith("_counter") && !this.chartdata?.datasets)) {
            return;
        }

        let chart = "";
        let className = "";
        let actions = (
            <div>
                <button class="button is-primary ml-2 is-small" onclick={this.exportImage}>
                    <span class="icon">
                        <i class="fa fa-image"></i>
                    </span>
                </button>
                <button class="button is-primary ml-2 is-small" onclick={this.exportCsv}>
                    <span class="icon">
                        <i class="fa fa-file-text"></i>
                    </span>
                </button>
            </div>
        );

        if (this.type.endsWith("_pie")) {
            className = "pie";
            chart = <ReportChartPie ref="chart" report={this.report} chartdata={this.chartdata} />;
        } else if (this.type.endsWith("_bar")) {
            className = "bar";
            chart = <ReportChartBar ref="chart" report={this.report} chartdata={this.chartdata} />;
        } else if (this.type.endsWith("_table")) {
            className = "table";
            chart = <ReportChartTable report={this.report} chartdata={this.chartdata} />;
            actions = (
                <div>
                    <button class="button is-primary ml-2 is-small" onclick={this.copy}>
                        <span class="icon">
                            <i class="fa fa-copy"></i>
                        </span>
                    </button>
                    <button class="button is-primary ml-2 is-small" onclick={this.exportCsv}>
                        <span class="icon">
                            <i class="fa fa-file-text"></i>
                        </span>
                    </button>
                </div>
            );
        } else if (this.type.endsWith("_counter")) {
            className = "counter";
            chart = <ReportChartCounter chartdata={this.chartdata} />;
            actions = null;
        }

        return (
            <div class={className}>
                <div class="title is-size-4 has-text-weight-bold has-text-primary">
                    {this.$t(`ReportChart.${this.shortType}`)}
                    {actions}
                </div>
                {chart}
            </div>
        );
    }
}
</script>
<style lang="scss" scoped>
.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
