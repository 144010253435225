<script>
import Component from "vue-class-component";
import Vue from "vue";
import { COLLABORATOR_TYPE } from "../../services/ProjectService";

const Props = Vue.extend({
    props: {
        name: String,
        avatar: String,
        type: String,
        height: Number,
        clickCb: Function,
    },
});

@Component
export default class ProfileIcon extends Props {
    renderTeamIcon() {
        const initials = this.name?.split(" ").reduce((initials, word) => {
            return initials.concat(word.slice(0, 1));
        }, "");
        const color = this.name?.charCodeAt(0) % 15;

        return (
            <div
                class={`profileIcon-icon profileIcon-circle color-${color}`}
                onclick={() => this.clickCb?.()}
                style={`width:${this.height}px;height:${this.height}px;`}
            >
                {initials}
            </div>
        );
    }

    renderUserIcon() {
        return (
            <figure class={`image is-${`${this.height}x${this.height}`}`}>
                <img
                    class={`profileIcon-icon img-avatar is-rounded`}
                    src={this.avatar}
                    onclick={() => this.clickCb?.()}
                />
            </figure>
        );
    }

    render() {
        return (
            <div>
                {this.type === COLLABORATOR_TYPE.TEAM
                    ? this.renderTeamIcon()
                    : this.renderUserIcon()}
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.profileIcon-circle {
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
}
</style>
