export class Context {
    map = new Map();

    get(klass) {
        let instance = this.map.get(klass);
        if (!instance) {
            // create singleton
            instance = new klass(this);
            this.map.set(klass, instance);
        }
        return instance;
    }

    set(klass, instance) {
        this.map.set(klass, instance);
    }
}

// Annotation to inject a class
export function inject(klass) {
    return () => ({
        get: function () {
            return this.context.get(klass);
        },
        enumerable: true,
        configurable: true,
    });
}
