<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { UserService } from "../../services/UserService";
import PasswordConfirmation from "../login/PasswordConfirmation.vue";

@Component
export default class UpdatePwd extends Vue {
    @inject(UserService) userService;

    mounted() {
        this.$refs.passwords.focus();
    }

    async updatePwd() {
        const isValid = this.$refs.passwords.isValid();
        if (!isValid) {
            return;
        }

        try {
            const token = this.$route.query && this.$route.query.recoveryToken;
            await this.userService.updatePwd(this.$refs.passwords.getValue(), token);
            this.$router.push("/login");
            this.$buefy.toast.open({
                message: this.$t("UpdatePwd.message"),
                type: "is-success",
            });
        } catch (error) {
            this.$buefy.toast.open({
                message: this.$t("UpdatePwd.error"),
                type: "is-danger",
            });
        }
    }

    submit(e) {
        this.updatePwd();

        e.preventDefault();
        e.stopPropagation();
    }

    render() {
        return (
            <form class="tab-forms" onsubmit={this.submit}>
                <b-tabs ref="tabs" animated={false} expanded={true}>
                    <b-tab-item label={this.$t("UpdatePwd.title")}>
                        <PasswordConfirmation ref="passwords" class="passwords" />
                        <div class="control has-text-right">
                            <button class="button is-primary is-fullwidth">
                                {this.$t("UpdatePwd.submit")}
                            </button>
                        </div>
                    </b-tab-item>
                </b-tabs>
            </form>
        );
    }
}
</script>

<style lang="scss" scoped>
.tab-forms {
    box-shadow: 0 0 0 1px #e5e5e5;
    border-bottom-right-radius: 0.25rem;
    border-radius: 0.25rem;
    width: 460px;
}

.passwords {
    margin-bottom: 0.75em;
}
</style>
