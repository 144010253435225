<script>
import Component from "vue-class-component";
import Vue from "vue";

@Component
export default class TimelineScrollBar extends Vue {
    scrollerWidth = 100;

    created() {
        this.moveScrollBound = (e) => this.moveScroll(e);
        this.stopScrollBound = (e) => this.stopScroll(e);
    }

    setZoom(value) {
        this.$refs.scroll.classList.toggle("disabled", value === 1);

        if (value === 1) {
            this.$refs.scroller.style.left = "0px";
            this.$emit("scroll", 0);
        }
    }

    setScroll(value) {
        this.$refs.scroller.style.left = `${value}%`;
    }

    onScroll(e) {
        const x = this.$refs.scroll.getBoundingClientRect().x;

        const diff = e.pageX - x;
        const value = Math.min(Math.max(0, diff), this.$refs.scroll.offsetWidth);

        const position = value / this.$refs.scroll.offsetWidth;
        this.$refs.scroller.style.left = `${position * 100}%`;

        this.$emit("scroll", position * 100, true);
    }

    startScroll(e) {
        this.x = e.pageX;
        this.scrolling = true;
        this.$refs.scroller.classList.add("scrolling");

        window.addEventListener("mousemove", this.moveScrollBound);
        window.addEventListener("mouseup", this.stopScrollBound);

        e.stopPropagation();
        e.preventDefault();
    }

    moveScroll(e) {
        if (this.scrolling) {
            const left = this.$refs.scroller.offsetLeft;

            const diff = e.pageX - this.x;
            const value = Math.min(Math.max(0, left + diff), this.$refs.scroll.offsetWidth);

            const position = value / this.$refs.scroll.offsetWidth;
            this.$refs.scroller.style.left = `${position * 100}%`;

            this.$emit("scroll", position * 100, false);
            this.x = e.pageX;
        }
    }

    stopScroll() {
        this.scrolling = false;
        this.$refs.scroller.classList.remove("scrolling");
        this.x = 0;

        window.removeEventListener("mousemove", this.moveScrollBound);
        window.removeEventListener("mouseup", this.stopScrollBound);
        this.$emit("scroll", parseFloat(this.$refs.scroller.style.left), true);
    }

    render() {
        return (
            <div ref="scroll" class="scroll disabled" onmousedown={this.onScroll}>
                <div ref="scroller" class="scroller" onmousedown={this.startScroll}></div>
            </div>
        );
    }
}
</script>

<style lang="scss">
.scroll {
    position: relative;
    width: calc(100% - 1 * 100px);
    height: 20px;
    background: #fff;
    display: flex;
    align-items: center;

    &.disabled {
        .scroller {
            left: 0 !important;
            opacity: 0.5;
            width: calc(100% + 100px);
        }
    }
}

.scroller {
    height: 100%;
    width: 100px;
    position: absolute;
    left: 0;

    &.scrolling {
        opacity: 0.8;
    }

    &::after {
        content: "";
        position: absolute;
        top: 7px;
        height: 6px;
        width: 100%;
        border-radius: 31px;
        background: #808e9b;
    }
}
</style>
