<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { UserService } from "../../services/UserService";

const Props = Vue.extend({
    props: {
        type: {
            required: true,
            type: String,
        },
    },
});

@Component
export default class DatePickerInput extends Props {
    @inject(UserService) userService;

    async created() {
        this.firstDayOfWeek = await this.userService.getFirstDayOfWeek(1);
        this.locale = await this.userService.getLanguage();
        this.minutesGranularity = await this.userService.getTasksMinutesGranularity();
        this.secondsGranularity = await this.userService.getTasksSecondsGranularity();
    }

    get newValue() {
        return this.$refs.input.newValue;
    }

    render(h) {
        const timepickerProps = {
            enableSeconds: this.secondsGranularity < 60,
            incrementMinutes: this.minutesGranularity === 0 ? 1 : this.minutesGranularity,
            incrementSeconds: this.secondsGranularity,
        };
        const props = {
            ...this.$attrs,
            locale: this.locale,
            "first-day-of-week": this.firstDayOfWeek,
            timepicker: timepickerProps,
        };
        return h(`b-${this.type}picker`, {
            ref: "input",
            props: props,
            on: {
                input: (value) => this.$emit("input", value),
            },
        });
    }
}
</script>
