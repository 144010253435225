<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { ProjectService } from "../../services/ProjectService";
import TeamSelection from "../common/TeamSelection";
import Permission from "../common/Permission";
import { UserService } from "../../services/UserService";
import PaginatedTable from "../common/PaginatedTable.vue";

const Props = Vue.extend({
    props: {
        project: Object,
    },
});

@Component
export default class TeamsProject extends Props {
    @inject(ProjectService) projectService;
    @inject(UserService) userService;

    user = {};

    async mounted() {
        this.user = await this.userService.getUser();

        this.projectService.on("change", this.$refs.table.loadData);
    }

    beforeDestroy() {
        this.projectService.off("change", this.$refs.table.loadData);
    }

    async getTeams(selector) {
        const id = this.$route.params.id;
        const teams = await this.projectService.getTeams(id, selector);
        return teams;
    }

    async addPermission() {
        const projectId = this.$route.params.id;
        const teamId = this.$refs.team.getSelectedTeamId();
        const permission = this.$refs.permission.getValue();

        if (teamId) {
            try {
                await this.projectService.saveTeamPermission(projectId, teamId, permission);
                this.$refs.team.clearSelectedTeam();
            } catch {
                this.$buefy.toast.open({
                    message: this.$t("error.server"),
                    type: "is-danger",
                });
            }
        }
    }

    async removePermission(teamId) {
        const projectId = this.$route.params.id;

        this.$buefy.dialog.confirm({
            title: this.$t("TeamsProject.confirm.title"),
            message: this.$t("TeamsProject.confirm.message"),
            confirmText: this.$t("TeamsProject.confirm.confirmText"),
            type: "is-danger",
            hasIcon: true,
            onConfirm: async () => {
                try {
                    await this.projectService.removeTeamPermission(projectId, teamId);
                    this.$buefy.toast.open({
                        message: this.$t("TeamsProject.confirm.success"),
                        type: "is-success",
                    });
                } catch {
                    this.$buefy.toast.open({
                        message: this.$t("error.server"),
                        type: "is-danger",
                    });
                }
            },
        });
    }

    async setPermission(teamId, permission) {
        const projectId = this.$route.params.id;
        try {
            await this.projectService.saveTeamPermission(projectId, teamId, permission);
        } catch {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
        }
    }

    render() {
        if (!this.project) {
            return;
        }

        const isMaintainer = this.project.permission === "maintainer";

        return (
            <div class="page-table">
                <div class={`field-inline${isMaintainer ? "" : " is-hidden"}`}>
                    <div class="fixed">
                        <TeamSelection ref="team" />
                    </div>
                    <div>
                        <Permission ref="permission" value="reporter" />
                    </div>
                    <button
                        class="button is-primary is-rounded is-outlined"
                        onclick={this.addPermission}
                    >
                        {this.$t("TeamsProject.submit")}
                    </button>
                </div>
                <PaginatedTable ref="table" fetchData={this.getTeams} defaultSort="name_asc">
                    <b-table-column
                        label={this.$t("TeamsProject.team")}
                        field="name"
                        sortable
                        scopedSlots={{
                            default: ({ row: team }) => team.name,
                        }}
                    />

                    <b-table-column
                        label={this.$t("TeamsProject.permission")}
                        field="permission"
                        centered
                        sortable
                        scopedSlots={{
                            default: ({ row: team }) => (
                                <Permission
                                    key={`permission-${team.permission}`}
                                    value={team.permission}
                                    size="is-small"
                                    oninput={(value) => this.setPermission(team.id, value)}
                                />
                            ),
                        }}
                    />

                    <b-table-column
                        label={this.$t("TeamsProject.action")}
                        centered
                        scopedSlots={{
                            default: ({ row: team }) => (
                                <b-button
                                    type="is-danger is-small"
                                    icon-right="trash"
                                    onclick={() => this.removePermission(team.id)}
                                />
                            ),
                        }}
                    />
                </PaginatedTable>
            </div>
        );
    }
}
</script>
