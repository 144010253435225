import { Service } from "./Service";

const serverUrl = window.WID_THC_URL;

export const CREDENTIALS_LOST_EVENT = "credentialsLost";

export class Fetcher extends Service {
    _notifyCrendentialsLost() {
        this.emit(CREDENTIALS_LOST_EVENT);
    }

    toFullUrl(url) {
        return `${serverUrl}${url}`;
    }

    async post(url, data) {
        const response = await fetch(this.toFullUrl(url), {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        let result = "";
        try {
            if (response.status !== 204) {
                result = await response.json();
            }
        } catch (e) {
            console.error(e);
        }

        if (response.ok) {
            return Promise.resolve(result);
        } else if (response.status === 401) {
            this._notifyCrendentialsLost();
        }

        return Promise.reject({ status: response.status });
    }

    async get(url, params = {}) {
        const path = new URL(url, serverUrl);

        Object.entries(params).forEach(([name, value]) => {
            if (Array.isArray(value)) {
                value.forEach((v) => {
                    path.searchParams.append(name, v);
                });
            } else {
                path.searchParams.append(name, value);
            }
        });

        const response = await fetch(path, {
            method: "GET",
            mode: "cors",
            credentials: "include",
            headers: {
                Accept: "application/json",
            },
        });

        let result = "";
        try {
            if (response.status !== 204) {
                result = await response.json();
            }
        } catch (e) {
            console.error(e);
        }

        if (response.ok) {
            return Promise.resolve(result);
        } else if (response.status === 401) {
            this._notifyCrendentialsLost();
        }

        return Promise.reject({ status: response.status });
    }

    async delete(url, data) {
        const response = await fetch(this.toFullUrl(url), {
            method: "DELETE",
            mode: "cors",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            return Promise.resolve();
        } else if (response.status === 401) {
            this._notifyCrendentialsLost();
        }

        return Promise.reject({ status: response.status });
    }

    async put(url, data) {
        const response = await fetch(this.toFullUrl(url), {
            method: "PUT",
            mode: "cors",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            return Promise.resolve();
        } else if (response.status === 401) {
            this._notifyCrendentialsLost();
        }

        return Promise.reject({ status: response.status });
    }
}
