<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";

import { TimelineService } from "../../services/TimelineService";
import { getProgressInDay, diffValue, EMPTY_UUID, replaceEmoji } from "../../base/Utils";

const Props = Vue.extend({
    props: {
        task: {
            type: Object,
            required: true,
        },
    },
});

export const Actions = {
    MOVE: "move",
    RESIZE_LEFT: "resizeLeft",
    RESIZE_RIGHT: "resizeRight",
};

@Component
export default class Task extends Props {
    @inject(TimelineService) timelineService;

    state = {
        isEditing: false,
        isUpdating: false,
        isMoving: false,
        isSelected: false,
    };

    created() {
        this.onMouseUpBound = (event) => this._onMouseUp(event);
    }

    editTask() {
        this.$router
            .push({
                path: `/timeline/edit/${this.task.id}`,
            })
            .catch(() => {});
    }

    _onMouseUp(event) {
        this.$emit("mouseUp", event);

        window.removeEventListener("mousemove", this.onMouseMoveBound);
        window.removeEventListener("mouseup", this.onMouseUpBound);
    }

    _prepareAction(actionName, event) {
        this.$emit("mouseDown", event, actionName);

        this.onMouseMoveBound = (e) => {
            this.$emit(actionName, e);
            e.stopPropagation();
            e.preventDefault();
        };

        window.addEventListener("mousemove", this.onMouseMoveBound);
        window.addEventListener("mouseup", this.onMouseUpBound);

        event.stopPropagation();
        event.preventDefault();
    }

    setState(state) {
        this.state = {
            ...this.state,
            ...state,
        };
    }

    getState() {
        return this.state;
    }

    render() {
        const currentDate = this.timelineService.currentDate;
        const progressStart = getProgressInDay(this.task.startDate, currentDate);
        const progressEnd = getProgressInDay(this.task.endDate, currentDate);

        return (
            <div
                ref="task"
                class={{
                    task: true,
                    [`color-${this.task.color || 0}`]: true,
                    "is-editing": this.state.isEditing,
                    "is-updating": this.state.isUpdating,
                    "is-moving": this.state.isMoving,
                    "is-selected": this.state.isSelected,
                }}
                style={`
                                left:${progressStart}%;
                                width:${progressEnd - progressStart}%
                            `}
                onmousedown={(e) => this._prepareAction(Actions.MOVE, e)}
                ondblclick={this.editTask}
            >
                <div
                    class="task-border task-border--left"
                    onmousedown={(e) => this._prepareAction(Actions.RESIZE_LEFT, e)}
                ></div>
                <div class="task-text">
                    <div>
                        <strong>
                            {this.task.projectId && this.task.projectId !== EMPTY_UUID
                                ? this.task.project
                                : this.$t("Task.unknown")}
                        </strong>
                        {this.task.title ? `-${replaceEmoji(this.task.title)}` : ""}
                    </div>
                    <div>
                        {this.task.tags &&
                            this.task.tags.map((tag) => <span class="mr-2">{tag}</span>)}
                    </div>
                    <div>
                        <strong>{diffValue(this.task.endDate - this.task.startDate)}</strong>
                    </div>
                </div>
                <div
                    class="task-border task-border--right"
                    onmousedown={(e) => this._prepareAction(Actions.RESIZE_RIGHT, e)}
                ></div>
            </div>
        );
    }
}
</script>

<style lang="scss">
$task-border-width: 15px;
$selected-task-opacity: 0.9;

.task {
    position: absolute;
    height: 85px;
    top: 75px;
    padding: 10px 0;
    overflow: visible;

    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(33, 33, 33, 0.7);

    transition-property: opacity, transform;
    transition-duration: 0.1s;

    will-change: opacity, transform;
}

.task:hover {
    opacity: 0.9;
}

.task.is-editing {
    opacity: 1 !important;
}

.task.is-updating {
    opacity: $selected-task-opacity;
    z-index: 38;
}

.task.is-moving {
    opacity: $selected-task-opacity;
    cursor: move;
}

.task.is-selected {
    opacity: $selected-task-opacity;
}

.task-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin: 0 16px;

    div {
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

strong {
    color: inherit;
}

.task-border {
    position: absolute;
    top: 0;
    height: 100%;
    width: $task-border-width;
    cursor: col-resize;
}

.task-border--left {
    left: -$task-border-width / 3;
}

.task-border--right {
    right: -$task-border-width / 3;
}
</style>
