<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { ProjectService } from "../../services/ProjectService";
import { UserService } from "../../services/UserService";
import RepoProjects from "./RepoProjects";
import ConnectModal from "../../pages/ConnectModal.vue";
import Repos from "./Repos.vue";

const Props = Vue.extend({
    props: {
        project: Object,
    },
});

@Component
export default class AdvancedProject extends Props {
    @inject(ProjectService) projectService;
    @inject(UserService) userService;

    repo = null;
    selectedRepoProject = null;
    currentRepoProject = null;

    created() {
        this.init();

        this.initBound = () => this.init();
        this.projectService.on("change", this.initBound);
        this.userService.on("change", this.initBound);
    }

    destroyed() {
        this.projectService.off("change", this.initBound);
        this.userService.off("change", this.initBound);
    }

    async init() {
        try {
            this.currentRepoProject = await this.projectService.getRepo(this.project.id);
        } catch (err) {
            if (err.status === 404) {
                this.currentRepoProject = null;
            } else {
                this.currentRepoProject = {};
            }
        }
    }

    selectRepo(repo) {
        this.repo = repo;

        this.selectedRepoProject = null;
        this.$refs.repoProjects.clearSelectedProject();
    }

    connectRepo(repo) {
        this.$buefy.modal.open({
            parent: this,
            component: ConnectModal,
            hasModalCard: true,
            customClass: "custom-class custom-class-2",
            trapFocus: true,
            events: {
                close: (value) => {
                    this.repo = {
                        ...this.repo,
                        credential: value,
                    };
                },
            },
            props: { repo },
        });
    }

    async addRepo() {
        await this.projectService.addRepo(this.project.id, this.repo, this.selectedRepoProject.id);
    }

    async removeRepo() {
        this.$buefy.dialog.confirm({
            title: this.$t("AdvancedProject.repo.confirm.title"),
            message: this.$t("AdvancedProject.repo.confirm.message"),
            confirmText: this.$t("AdvancedProject.repo.confirm.confirmText"),
            type: "is-danger",
            hasIcon: true,
            onConfirm: async () => {
                try {
                    await this.projectService.removeRepo(this.project.id);
                    this.repo = null;
                    this.$buefy.toast.open({
                        message: this.$t("AdvancedProject.repo.confirm.success"),
                        type: "is-success",
                    });
                } catch {
                    this.$buefy.toast.open({
                        message: this.$t("error.server"),
                        type: "is-danger",
                    });
                }
            },
        });
    }

    async removeProject() {
        this.$buefy.dialog.confirm({
            title: this.$t("AdvancedProject.remove.confirm.title"),
            message: this.$t("AdvancedProject.remove.confirm.message"),
            confirmText: this.$t("AdvancedProject.remove.confirm.confirmText"),
            type: "is-danger",
            hasIcon: true,
            onConfirm: async () => {
                try {
                    await this.projectService.removeProjectById(this.project.id);
                    this.$buefy.toast.open({
                        message: this.$t("AdvancedProject.remove.confirm.success"),
                        type: "is-success",
                    });
                    this.$router.replace("/projects").catch(() => {});
                } catch {
                    this.$buefy.toast.open({
                        message: this.$t("error.server"),
                        type: "is-danger",
                    });
                }
            },
        });
    }

    render() {
        if (!this.project) {
            return;
        }

        let content;

        if (this.currentRepoProject) {
            content = (
                <div class="message-body is-half">
                    <b-field label={this.$t("AdvancedProject.repo.current")}>
                        <a class="button" target="_blank" href={this.currentRepoProject.url}>
                            {this.currentRepoProject.name}
                        </a>
                    </b-field>
                    <div class="is-fullwidth has-text-right">
                        <button
                            class="button is-danger is-rounded is-outlined "
                            onclick={this.removeRepo}
                        >
                            {this.$t("AdvancedProject.repo.remove")}
                        </button>
                    </div>
                </div>
            );

            if (!this.currentRepoProject.id) {
                content = (
                    <div class="message-body is-half">
                        <b-field label={this.$t("AdvancedProject.repo.current")}>
                            <p>
                                {this.$t("AdvancedProject.repo.warning")}{" "}
                                <a
                                    onclick={() =>
                                        this.connectRepo({
                                            id: this.project.repoId,
                                            category: this.project.repoCategory,
                                        })
                                    }
                                >
                                    {this.$t("AdvancedProject.repo.connect")}
                                </a>
                                .
                            </p>
                        </b-field>
                        <div class="is-fullwidth has-text-right">
                            <button
                                class="button is-danger is-rounded is-outlined "
                                onclick={this.removeRepo}
                            >
                                {this.$t("AdvancedProject.repo.remove")}
                            </button>
                        </div>
                    </div>
                );
            }
        } else {
            content = (
                <div class="message-body is-half">
                    <b-field label={this.$t("AdvancedProject.repo.available")}>
                        <div class="control has-icons-left">
                            <div class="select is-fullwidth">
                                <Repos ref="repos" onselect={this.selectRepo} />
                            </div>
                            <span class="icon is-left">
                                <span class="fa fa-code-fork fa-w-12 fa-lg" />
                            </span>
                        </div>
                    </b-field>
                    <p class={this.repo && !this.repo.credential ? "mb-2" : "is-hidden"}>
                        {this.$t("AdvancedProject.repo.warning")}{" "}
                        <a onclick={() => this.connectRepo(this.repo)}>
                            {this.$t("AdvancedProject.repo.connect")}
                        </a>
                    </p>

                    <b-field label={this.$t("AdvancedProject.repo.projects")}>
                        <RepoProjects
                            ref="repoProjects"
                            repo={this.repo}
                            onSelect={(p) => (this.selectedRepoProject = p)}
                        />
                    </b-field>

                    <div class="is-fullwidth has-text-right">
                        <button
                            disabled={!this.selectedRepoProject}
                            class="button is-primary is-rounded is-outlined "
                            onclick={this.addRepo}
                        >
                            {this.$t("AdvancedProject.repo.save")}
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div class="columns">
                <div class="column is-8 is-offset-2">
                    <article class="message">
                        <div class="message-header">
                            <p>{this.$t("AdvancedProject.repo.header")}</p>
                        </div>
                        {content}
                    </article>
                    <article class="message is-danger">
                        <div class="message-header">
                            <p>{this.$t("AdvancedProject.remove.header")}</p>
                        </div>
                        <div class="message-body is-half">
                            <p>{this.$t("AdvancedProject.remove.body")}</p>
                            <p>{this.$t("AdvancedProject.remove.warning")}</p>
                            <div class="is-fullwidth has-text-right">
                                <button
                                    class="button is-primary is-rounded is-outlined is-danger"
                                    onclick={this.removeProject}
                                >
                                    {this.$t("AdvancedProject.remove.submit")}
                                </button>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
option[default] {
    display: none;
}
</style>
