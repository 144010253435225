<script>
import Component from "vue-class-component";
import Vue from "vue";
import { isEquals } from "../../base/Utils";

const Props = Vue.extend({
    props: {
        value: Array,
        disabled: Boolean,
    },
});

@Component
export default class EditableTags extends Props {
    editable = false;
    newValue = "";

    created() {
        this.newValue = this.value;
        this.$watch("value", () => {
            this.newValue = this.value;
        });
        this.end_bound = (e) => this.end(e);
    }

    start() {
        if (this.disabled) {
            return;
        }

        this.editable = true;
        document.addEventListener("mousedown", this.end_bound, true);
    }

    updated() {
        if (this.$refs.input) {
            this.$refs.input.focus();
        }
    }

    end(e) {
        if (this.$el === e.target || this.$el.contains(e.target)) {
            return;
        }

        this.validate();
    }

    validate(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const isValid = !this.$refs.input || this.$refs.input.checkHtml5Validity();
        if (!isValid) {
            setTimeout(() => {
                this.$refs.input.focus();
            }, 0);
            return;
        }

        this.exit();

        const newValue = this.$refs.input.tags;
        if (isEquals(newValue, this.newValue)) {
            return;
        }

        newValue.sort();
        this.newValue = newValue;
        this.$emit("change", newValue, this);
    }

    cancel() {
        this.newValue = this.value;
        this.exit();
    }

    exit() {
        this.editable = false;
        document.removeEventListener("mousedown", this.end_bound, true);
    }

    render() {
        if (!this.editable || this.disabled) {
            return (
                <div
                    class={{
                        EditableTags: true,
                        disabled: this.disabled,
                    }}
                >
                    <div class="tags" onclick={this.start}>
                        {this.value &&
                            this.value.map((tag) => (
                                <span class="tag is-primary is-rounded">{tag}</span>
                            ))}
                    </div>
                </div>
            );
        }

        return (
            <div class={{ EditableTags: true }}>
                <form onsubmit={this.validate}>
                    <b-taginput
                        ref="input"
                        value={this.newValue}
                        rounded
                        ellipsis
                        icon="tag"
                        size="is-small"
                    ></b-taginput>
                </form>
                <div class="actions">
                    <b-button
                        type="is-danger"
                        size="is-small"
                        icon-right="times"
                        onclick={this.cancel}
                    />
                    <b-button
                        type="is-primary ml-1"
                        size="is-small"
                        icon-right="check"
                        onclick={this.validate}
                    />
                </div>
            </div>
        );
    }
}
</script>

<style lang="scss">
.EditableTags {
    margin: 0;
    position: relative;
    height: 27px;
    width: 90%;

    .actions {
        position: absolute;
        right: 0;
        z-index: 2;
        top: calc(100% + 4px);
    }

    .tags {
        position: relative;
        height: 100%;
    }
}
</style>
