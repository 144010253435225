<script>
import Component from "vue-class-component";
import Vue from "vue";
import { Doughnut } from "vue-chartjs";
import { durationStringAccordingToDisplayModes } from "../../base/Utils";
import EmptyTable from "../common/EmptyTable.vue";

const Props = Vue.extend({
    props: {
        chartdata: Object,
        report: Object,
    },
});

@Component
export default class ReportChartPie extends Props {
    mounted() {
        const options = {
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {
                        var label = data.labels[tooltipItem.index] || "";
                        if (label) {
                            label += ": ";
                        }
                        label += durationStringAccordingToDisplayModes(
                            this._i18n,
                            this.report,
                            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
                            data.datasets[tooltipItem.datasetIndex].total
                        );
                        return label;
                    },
                },
            },
            responsive: true,
            maintainAspectRatio: false,
        };

        this.$refs.chart.renderChart(this.chartdata, options);
    }

    getChart() {
        return this.$refs.chart._data._chart;
    }

    render() {
        return (
            <div class="container">
                <EmptyTable
                    text={this.$t("ReportChartPie.empty")}
                    icon="fa-pie-chart"
                    visible={
                        this.chartdata.datasets.length === 0 ||
                        !this.chartdata.labels ||
                        this.chartdata.labels.length === 0
                    }
                />
                <Doughnut ref="chart" />
            </div>
        );
    }
}
</script>
<style lang="scss" scoped>
.container {
    position: relative;
}
</style>
