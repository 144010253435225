<script>
import Component from "vue-class-component";
import Vue from "vue";

const Props = Vue.extend({
    props: {
        value: String,
        updateCb: Function,
        message: String,
        disabled: Boolean,
    },
});

@Component
export default class InlineInput extends Props {
    update(e) {
        const input = e.srcElement && e.srcElement.value;
        const correctInput = this.updateCb?.(input);
        this.$refs.message.classList.toggle("is-hidden", correctInput);
        this.$refs.control.classList.toggle("has-icons-right", !correctInput);
        this.$refs.input.classList.toggle("is-danger", !correctInput);
    }

    render() {
        return (
            <div>
                <div class={`inlineInput-message is-hidden`} ref="message">
                    {this.message}
                </div>
                <div class="inlineInput-field">
                    <p class={`control `} ref="control">
                        <input
                            class={`input`}
                            oninput={this.update}
                            value={this.value}
                            type="text"
                            ref="input"
                            disabled={this.disabled}
                        ></input>
                        <span class={`icon is-small is-right has-text-danger`}>
                            <i class="fa fa-exclamation-circle"></i>
                        </span>
                    </p>
                </div>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
@import "../../../css/variables";

.inlineInput-field {
    .input {
        border: none;
        background-color: #f7f7f7;
        color: $primary;
        box-shadow: none;
        padding: 0 40px 0 0;

        &.is-danger {
            color: $danger;
        }
    }

    .control {
        .icon {
            display: none;
            height: 100%;
        }
        &.has-icons-right {
            .icon {
                display: inline-flex;
            }
        }
    }
}

.inlineInput-message {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 12px;
    color: $danger;
    font-size: 14px;
}
</style>
