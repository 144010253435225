<script>
import Component from "vue-class-component";
import Vue from "vue";
import { TimelineService } from "../../services/TimelineService";
import { inject } from "../../base/Injection";

@Component
export default class History extends Vue {
    @inject(TimelineService) timelineService;

    history = {
        count: 0,
        items: [],
    };
    search = "";
    pageSize = 8;
    pageNumber = 1;
    historyFetched = false;

    async loadData() {
        try {
            this.historyFetched = false;
            this.history = await this.timelineService.getHistory({
                search: this.search,
                limit: this.pageSize,
                offset: (this.pageNumber - 1) * this.pageSize,
            });
            this.historyFetched = true;
        } catch {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
        }
    }

    onOpenHistory(opening) {
        if (opening) {
            this.loadData();
        }
    }

    onChange(index) {
        const history = this.history.items[index];
        this.$emit("change", history);
    }

    onPage(index) {
        this.pageNumber = index;
        this.loadData();
    }

    onSearch(e) {
        this.pageNumber = 1;
        this.search = this.$refs.search.newValue;

        this.loadData();

        e.preventDefault();
        e.stopPropagation();
    }

    clearSearch() {
        this.pageNumber = 1;
        this.search = "";
        this.$refs.search.newValue = "";

        this.loadData();
    }

    render() {
        return (
            <b-dropdown
                aria-role="list"
                onchange={this.onChange}
                onactive-change={this.onOpenHistory}
            >
                <a slot="trigger" class="button is-primary is-outlined is-rounded mr-2">
                    <div class="icon mr-1">
                        <i class="fa fa-history"></i>
                    </div>
                    {this.$t("History.label")}
                </a>

                <b-dropdown-item custom aria-role="listitem" class="history-item">
                    <form onsubmit={this.onSearch}>
                        <b-input
                            ref="search"
                            class="search"
                            ellipsis
                            icon="search"
                            expanded
                            value={this.search}
                            placeholder={this.$t("History.search")}
                            icon-right="close-circle"
                            icon-right-clickable
                            onicon-right-click={this.clearSearch}
                        ></b-input>
                    </form>
                </b-dropdown-item>

                {this.history.count > 0 &&
                    this.history.items.map((p, index) => (
                        <b-dropdown-item aria-role="listitem" class="history-item" value={index}>
                            <span class={`tag mr-2 color-${p.color}`}>
                                {p.project || this.$t("History.unknown")}
                            </span>
                            {p.title}
                        </b-dropdown-item>
                    ))}

                {this.history.count === 0 && (
                    <b-dropdown-item
                        aria-role="listitem"
                        expanded
                        class="history-item has-text-centered"
                    >
                        {this.historyFetched
                            ? this.$t("History.empty")
                            : this.$t("History.fetching")}
                    </b-dropdown-item>
                )}

                <b-dropdown-item custom aria-role="listitem" class="history-item">
                    <b-pagination
                        total={this.history.count}
                        per-page={this.pageSize}
                        current={this.pageNumber}
                        onchange={this.onPage}
                        simple
                        size="is-small"
                        order="is-right"
                    />
                </b-dropdown-item>
            </b-dropdown>
        );
    }
}
</script>

<style lang="scss" scoped>
.history-item {
    width: 400px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.is-active {
        background-color: transparent;
        color: #4a4a4a;
    }
}
</style>
