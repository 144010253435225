<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";

import { UserService } from "../../services/UserService";
import { from2Json } from "../../base/Utils";

import PasswordConfirmation from "./PasswordConfirmation.vue";

@Component
export default class Signup extends Vue {
    @inject(UserService) userService;
    emailFielType = "";
    emailFielMessage = "";
    nameFielType = "";
    nameFielMessage = "";

    mounted() {
        this.$refs.email.focus();
    }

    async signUp() {
        const user = from2Json(this.$refs);

        let isValid = Object.values(this.$refs).reduce((acc, node) => {
            return (!node.checkHtml5Validity || node.checkHtml5Validity()) && acc;
        }, true);
        isValid =
            isValid && this.$refs.password.isValid() && !this.emailFielType && !this.nameFielType;
        if (!isValid) {
            return;
        }

        delete user.confirmation;
        try {
            await this.userService.confirmEmail(user);
            this.$router.push("/confirm").catch(() => {});
            this.$buefy.toast.open({
                message: this.$t("Signup.confirm-mail"),
                type: "is-success",
            });
        } catch (e) {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
        }
    }

    async verifyEmail() {
        try {
            const verified = await this.userService.verifyEmail(this.$refs.email.newValue);
            this.emailFielType = "";
            this.emailFielMessage = "";

            if (!verified) {
                this.emailFielType = "is-danger";
                this.emailFielMessage = this.$t("Signup.no-verify-email");
            }
        } catch (e) {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
        }
    }

    async verifyName() {
        try {
            const verified = await this.userService.verifyName(this.$refs.name.newValue);
            this.nameFielType = "";
            this.nameFielMessage = "";

            if (!verified) {
                this.nameFielType = "is-danger";
                this.nameFielMessage = this.$t("Signup.no-verify-name");
            }
        } catch (e) {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
        }
    }

    submit(e) {
        this.signUp();

        e.preventDefault();
        e.stopPropagation();
    }

    render() {
        return (
            <form onsubmit={this.submit}>
                <b-field
                    label={this.$t("Signup.email")}
                    type={this.emailFielType}
                    message={this.emailFielMessage}
                >
                    <b-input
                        ref="email"
                        placeholder="Email"
                        type="email"
                        required
                        placeholder={this.$t("Signup.email-placeholder")}
                        onblur={this.verifyEmail}
                    ></b-input>
                </b-field>
                <b-field
                    label={this.$t("Signup.username")}
                    type={this.nameFielType}
                    message={this.nameFielMessage}
                >
                    <b-input
                        ref="name"
                        required
                        placeholder={this.$t("Signup.username-placeholder")}
                        onblur={this.verifyName}
                    ></b-input>
                </b-field>
                <PasswordConfirmation ref="password" class="passwords" />
                <div class="control has-text-right">
                    <button class="button is-primary is-fullwidth">
                        {this.$t("Signup.submit")}
                    </button>
                </div>
            </form>
        );
    }
}
</script>

<style lang="scss" scoped>
.passwords {
    margin-bottom: 0.75em;
}
</style>
