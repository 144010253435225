import "../css/global.scss";

import Vue from "vue";
import VueRouter from "vue-router";
import VueI18n from "vue-i18n";
import Buefy from "buefy";
import { routes } from "./Routes";
import { Context } from "./base/Injection";

import i18n_en from "../i18n/en.json";
import i18n_fr from "../i18n/fr.json";
import { UserService } from "./services/UserService";
import App from "./App.vue";

Vue.use(Buefy, {
    defaultIconPack: "fa",
});

// Inject the context in components
const context = new Context();

Vue.mixin({
    created() {
        this.context = context;
    },
});

// Create a component with the router
Vue.use(VueRouter);
const router = new VueRouter({ mode: "history", routes });

// I18n
Vue.use(VueI18n);
window.__localeId__ = "en";
const i18n = new VueI18n({
    locale: "en",
    messages: {
        en: i18n_en,
        fr: i18n_fr,
    },
});

const userService = context.get(UserService);
userService.getLanguage().then((lang) => {
    i18n.locale = lang;
    window.__localeId__ = lang;
});

// Start the application
new Vue({
    i18n,
    router,
    render: (h) => h(App),
}).$mount("#app");
