var render, staticRenderFns
import script from "./ReportChart.vue?vue&type=script&lang=js&"
export * from "./ReportChart.vue?vue&type=script&lang=js&"
import style0 from "./ReportChart.vue?vue&type=style&index=0&id=36bf6e8a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36bf6e8a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36bf6e8a')) {
      api.createRecord('36bf6e8a', component.options)
    } else {
      api.reload('36bf6e8a', component.options)
    }
    
  }
}
component.options.__file = "src/js/components/report/ReportChart.vue"
export default component.exports