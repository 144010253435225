<script>
import Component from "vue-class-component";
import Vue from "vue";

const Props = Vue.extend({
    props: {
        selected: {
            type: Array,
            default: () => [],
        },
    },
});

@Component
export default class ReportCharts extends Props {
    draggingRow = null;
    draggingRowIndex = null;

    typeByIcon = {
        pie: "pie-chart",
        bar: "bar-chart",
        table: "table",
        counter: "counter",
    };

    allCharts = {
        total_project: {
            id: "total_project",
            name: this.$t(`ReportCharts.total_by_project`),
            type: "pie",
            selected: false,
        },
        total_tag: {
            id: "total_tag",
            name: this.$t(`ReportCharts.total_by_tag`),
            type: "pie",
            selected: false,
        },
        total_task: {
            id: "total_task",
            name: this.$t(`ReportCharts.total_by_task`),
            type: "table",
            selected: false,
        },
        project_tag: {
            id: "project_tag",
            name: this.$t(`ReportCharts.project_by_tag`),
            type: "bar",
            selected: false,
        },
        total_user: {
            id: "total_user",
            name: this.$t(`ReportCharts.total_by_user`),
            type: "bar",
            selected: false,
        },
        total_days: {
            id: "total_days",
            name: this.$t(`ReportCharts.total_days`),
            type: "counter",
            selected: false,
        },
    };

    noSelectedCharts = [];
    selectedCharts = [];

    created() {
        this.selected.forEach((id) => {
            const chartId = id.substring(0, id.lastIndexOf("_"));
            const chart = this.allCharts[chartId];
            this.selectedCharts.push({
                ...chart,
                type: id.replace(`${chartId}_`, ""),
                selected: true,
            });
        });
        Object.keys(this.allCharts).forEach((id) => {
            const selectedId = this.selected.find((chartId) => chartId.startsWith(id));

            const chart = this.allCharts[id];
            if (!selectedId) {
                this.noSelectedCharts.push(chart);
            }
        });
    }

    getSelectedCharts() {
        return this.selectedCharts.map((c) => `${c.id}_${c.type}`);
    }

    get charts() {
        const _data = [];
        this.selectedCharts && this.selectedCharts.forEach((item) => _data.push(item));
        this.noSelectedCharts && this.noSelectedCharts.forEach((item) => _data.push(item));
        return _data;
    }

    dragstart(payload) {
        if (payload.index >= this.selectedCharts.length) {
            payload.event.preventDefault();
            return;
        }
        this.draggingRow = payload.row;
        this.draggingRowIndex = payload.index;
        payload.event.dataTransfer.effectAllowed = "copy";
    }

    dragover(payload) {
        payload.event.dataTransfer.dropEffect = "copy";
        payload.event.preventDefault();
    }

    dragleave(payload) {
        payload.event.preventDefault();
    }

    drop(payload) {
        const droppedOnRowIndex = payload.index;

        if (droppedOnRowIndex < this.draggingRowIndex) {
            this.selectedCharts.splice(
                droppedOnRowIndex,
                0,
                this.selectedCharts[this.draggingRowIndex]
            );
            this.selectedCharts.splice(this.draggingRowIndex + 1, 1);
        } else {
            this.selectedCharts.splice(
                droppedOnRowIndex + 1,
                0,
                this.selectedCharts[this.draggingRowIndex]
            );
            this.selectedCharts.splice(this.draggingRowIndex, 1);
        }
    }

    toggleSelect(chart) {
        if (chart.selected) {
            chart.selected = false;
            this.noSelectedCharts.unshift(chart);
            this.selectedCharts = this.selectedCharts.filter((c) => c.id !== chart.id);
        } else {
            chart.selected = true;
            this.selectedCharts.push(chart);
            this.noSelectedCharts = this.noSelectedCharts.filter((c) => c.id !== chart.id);
        }
    }

    setOption(chart, value) {
        chart.type = value;
    }

    render() {
        return (
            <div class="charts-selection">
                <b-table
                    ref="charts"
                    data={this.charts}
                    draggable
                    onDragstart={this.dragstart}
                    onDrop={this.drop}
                    onDragover={this.dragover}
                    onDragleave={this.dragleave}
                >
                    <b-table-column
                        scopedSlots={{
                            default: ({ row: chart }) => {
                                return (
                                    <span
                                        class="middle"
                                        key={`chart-${chart.id}-${chart.selected}`}
                                    >
                                        <i
                                            class={{
                                                fa: chart.selected,
                                                "fa-arrows-v": chart.selected,
                                                "mr-4": !chart.selected,
                                            }}
                                        ></i>
                                        <b-checkbox
                                            class="ml-4"
                                            oninput={() => this.toggleSelect(chart)}
                                            value={chart.selected}
                                        >
                                            {chart.name}
                                        </b-checkbox>
                                    </span>
                                );
                            },
                        }}
                    />
                    <b-table-column
                        cell-class="fix-size-option"
                        centered
                        scopedSlots={{
                            default: ({ row: chart }) => {
                                if (chart.type === "counter") {
                                    return;
                                }

                                return (
                                    <b-dropdown
                                        key={`option-${chart.id}-${chart.type}`}
                                        aria-role="list"
                                        disabled={!chart.selected}
                                        onchange={(value) => this.setOption(chart, value)}
                                    >
                                        <button
                                            type="button"
                                            class="button is-small is-primary"
                                            slot="trigger"
                                        >
                                            <b-icon icon={this.typeByIcon[chart.type]}></b-icon>
                                        </button>

                                        <b-dropdown-item value="table" aria-role="listitem">
                                            <div class="media">
                                                <b-icon class="media-left" icon="table"></b-icon>
                                                <div class="media-content">
                                                    <h3>Table</h3>
                                                </div>
                                            </div>
                                        </b-dropdown-item>

                                        <b-dropdown-item value="bar" aria-role="listitem">
                                            <div class="media">
                                                <b-icon
                                                    class="media-left"
                                                    icon="bar-chart"
                                                ></b-icon>
                                                <div class="media-content">
                                                    <h3>Bar</h3>
                                                </div>
                                            </div>
                                        </b-dropdown-item>

                                        <b-dropdown-item value="pie" aria-role="listitem">
                                            <div class="media">
                                                <b-icon
                                                    class="media-left"
                                                    icon="pie-chart"
                                                ></b-icon>
                                                <div class="media-content">
                                                    <h3>Pie</h3>
                                                </div>
                                            </div>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                );
                            },
                        }}
                    />
                </b-table>
            </div>
        );
    }
}
</script>
<style lang="scss" scoped>
.middle {
    display: flex;
    align-items: center;
}

.fix-size-option {
    width: 54px;
}
</style>
<style lang="scss">
.charts-selection {
    .b-table .table-wrapper {
        overflow: visible;
    }

    thead {
        display: none;
    }
}
</style>
