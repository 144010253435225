<script>
import Component from "vue-class-component";
import Vue from "vue";

@Component
export default class About extends Vue {
    render() {
        return (
            <div class="selectable">
                <div class="field">
                    <div class="label">{this.$t("About.version")}</div>
                    WEBPACK_VERSION
                </div>
                <div class="field">
                    <div class="label">{this.$t("About.versionName")}</div>
                    WEBPACK_VERSION_NAME
                </div>
                <div class="field">
                    <div class="label">{this.$t("About.gitCommitHash")}</div>
                    GIT_COMMIT_HASH
                </div>
                <div class="field">
                    <div class="label">{this.$t("About.date")}</div>
                    WEBPACK_DATE
                </div>
                <div class="field">
                    <div class="label">{this.$t("About.links")}</div>
                    <div class="links">
                        <a
                            href="https://gitlab.nuiton.org/jruchaud/wid-cbd/-/issues"
                            target="_blank"
                        >
                            {this.$t("About.issues")}
                        </a>
                        <a href="https://gitlab.nuiton.org/jruchaud/wid-thc" target="_blank">
                            {this.$t("About.thc")}
                        </a>
                        <a href="https://gitlab.nuiton.org/jruchaud/wid-cbd" target="_blank">
                            {this.$t("About.cbd")}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.field {
    display: flex;
    align-items: center;
}

.label {
    flex-grow: 1;
}

.links {
    > a {
        margin-left: 1rem;
    }
}
</style>
