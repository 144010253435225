<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../base/Injection";
import { TeamService } from "../services/TeamService";
import BackButton from "../components/common/BackButton.vue";
import UsersTeam from "../components/team/UsersTeam.vue";
import EditableInput from "../components/common/EditableInput.vue";
import AdvancedTeam from "../components/team/AdvancedTeam.vue";

@Component
export default class EditTeamPage extends Vue {
    @inject(TeamService) teamService;

    team = {};
    fetching = false;

    async created() {
        this.init();
    }

    async init() {
        try {
            this.fetching = true;
            const id = this.$route.params.id;
            this.team = await this.teamService.getTeam(id);
        } catch {
            this.team = {};
        } finally {
            this.fetching = false;
        }
    }

    async save(name, target) {
        this.team = {
            ...this.team,
            name,
        };

        try {
            await this.teamService.saveTeam({ id: this.team.id, name });
        } catch {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
            target.cancel();
        }
    }

    onChangeTab(position) {
        const query = { position };
        this.$router.replace({ query });
    }

    render() {
        let currentPosition = parseInt(this.$route.query.position) || 0;

        return (
            <div class="page-content">
                <div class="page-title">
                    <BackButton cb={() => this.$router.push("/teams")} />
                    {this.$t("EditTeam.title")}
                    <EditableInput
                        type="title"
                        value={this.team.name}
                        disabled={!this.team.admin}
                        onChange={this.save}
                    />
                </div>
                <div class="page-full">
                    <b-tabs
                        animated={false}
                        destroy-on-hide={true}
                        expanded={true}
                        value={currentPosition}
                        onInput={this.onChangeTab}
                    >
                        <b-tab-item label={this.$t("EditTeam.users")} icon="users">
                            <UsersTeam team={this.team} />
                        </b-tab-item>
                        <b-tab-item
                            label={this.$t("EditTeam.advanced")}
                            icon="ellipsis-h"
                            visible={Boolean(this.team.admin)}
                        >
                            <AdvancedTeam team={this.team} />
                        </b-tab-item>
                    </b-tabs>
                </div>
            </div>
        );
    }
}
</script>
