<script>
import Component from "vue-class-component";
import Vue from "vue";

import TimelineScrollBar from "./TimelineScrollBar.vue";
import TimelineCurrentTime from "./TimelineCurrentTime.vue";
import TimelineUnderCursorTime from "./TimelineUnderCursorTime.vue";
import { getDateWithLeft, getBaseLog } from "../../base/Utils";
import Tasks from "./Tasks.vue";
import Activities from "./Activities.vue";
import Events from "./Events.vue";
import { inject } from "../../base/Injection";
import { UserService } from "../../services/UserService";
import {
    TimelineService,
    MIN_TIMELINE_SCALE_BOUND,
    MAX_TIMELINE_SCALE_BOUND,
    BASE_ZOOM,
} from "../../services/TimelineService";
import { TimerService } from "../../services/TimerService";
import { add, intervalToDuration } from "date-fns";

const MAX_DISPLAYED_SCALE_BOUND = getBaseLog(BASE_ZOOM, MAX_TIMELINE_SCALE_BOUND);

@Component
export default class Timeline extends Vue {
    @inject(TimelineService) timelineService;
    @inject(TimerService) timerService;
    @inject(UserService) userService;

    copiedTasks = [];
    displayEventsEnabled = null;
    fixedTimeline = false;

    async init() {
        this.displayEventsEnabled = await this.userService.getTimelineDisplayEventsEnabled();

        const startingHourFixedTimeline = await this.userService.getStartingHourFixedTimeline();
        const endingHourFixedTimeline = await this.userService.getEndingHourFixedTimeline();
        this.fixedTimeline = startingHourFixedTimeline && endingHourFixedTimeline;
    }

    mounted() {
        this.init();
        this._containerPos = this.$refs.container.getBoundingClientRect();
        this._pageX = 0;

        this._selectionRectangle = {
            show: false,
            hourMarkTop: getComputedStyle(this.$refs.container).getPropertyValue("--hourMarkTop"),
            hourMarkHeight: getComputedStyle(this.$refs.container).getPropertyValue(
                "--hourMarkHeight"
            ),
        };

        this._selectionCreateTask = {
            show: false,
        };

        this._onkeydown = (e) => {
            if (e.key === "Control") {
                this._controlKeyPressed = true;
            } else if (e.key === "Shift") {
                const dateAtX = this._getDateAtX(this._pageX);
                this.$refs.underCursorTime.setDate(dateAtX);
                this.$refs.underCursorTime.setHidden(false);
            } else if (e.key === "c" && this._controlKeyPressed) {
                this._copyPressed = true;
            } else if (e.key === "v" && this._controlKeyPressed) {
                this._pastePressed = true;
            }
        };
        this._onkeyup = (e) => {
            if (e.key === "Control") {
                this._controlKeyPressed = false;
            } else if (e.key === "Shift") {
                this.$refs.underCursorTime.setHidden(true);
            } else if (e.key === "c") {
                if (this._copyPressed) {
                    this.copyTasks();
                    this._copyPressed = false;
                }
            } else if (e.key === "v") {
                if (this._pastePressed) {
                    this.pasteTasks();
                    this._pastePressed = false;
                }
            }
        };
        this._onMouseMove = (e) => {
            this._pageX = e.pageX;

            if (!this.$refs.underCursorTime.isHidden) {
                const dateAtX = this._getDateAtX(e.pageX);
                const newDateAtX = this.timelineService.normalizeFromTasks(dateAtX);
                this.$refs.underCursorTime.setDate(newDateAtX);
            }

            if (this._selectionCreateTask.show) {
                this.drawCreateTaskRectangle(e);
            }

            if (this._selectionRectangle.show) {
                this.drawRectangle(e);
            }
        };
        document.addEventListener("keydown", this._onkeydown);
        document.addEventListener("keyup", this._onkeyup);
        document.addEventListener("mousemove", this._onMouseMove);
    }

    beforeDestroy() {
        document.removeEventListener("keydown", this._onkeydown);
        document.removeEventListener("keyup", this._onkeyup);
        document.removeEventListener("mousemove", this._onMouseMove);
    }

    onZoomOnWheel(event) {
        if (this.fixedTimeline) {
            return;
        }

        const value = event.deltaY > 0 ? -1 : 1;
        const old = getBaseLog(BASE_ZOOM, this.zoom);
        if (value + old > MAX_DISPLAYED_SCALE_BOUND) {
            return;
        }

        const roughScaleValue = Math.pow(BASE_ZOOM, old + value);

        // Restrict scale
        const scaleValue = Math.min(
            Math.max(MIN_TIMELINE_SCALE_BOUND, roughScaleValue),
            MAX_TIMELINE_SCALE_BOUND
        );

        // Compute the scroll position in order to keep the mouse pointer
        // in the same place on the screen after the scale
        const propertieScroll = this.$refs.container.style.getPropertyValue("--scroll");
        const propertieLimitScroll = this.$refs.container.style.getPropertyValue("--limitScroll");
        const propertieScale = this.$refs.container.style.getPropertyValue("--scale");

        const currentOffsetLeft =
            this._containerPos.width *
            (parseFloat(propertieScroll) / 100) *
            parseFloat(propertieScale) *
            parseFloat(propertieLimitScroll);

        const position = event.clientX - this._containerPos.x;
        const positionInTimeline = position + currentOffsetLeft;

        const nextWidth = this._containerPos.width * scaleValue;
        const nextPositionInTimeline = positionInTimeline / (this.zoom / scaleValue);
        const nextOffsetLeft = nextPositionInTimeline - position;
        const nextLimitScroll = (nextWidth - this._containerPos.width) / nextWidth;

        let nextPosition;
        if (nextLimitScroll === 0) {
            nextPosition = 0;
        } else {
            nextPosition = nextOffsetLeft / (nextWidth * nextLimitScroll);

            if (nextPosition > 1) {
                nextPosition = 1;
            }
            if (nextPosition < 0) {
                nextPosition = 0;
            }
        }

        this.$refs.scroll.setScroll(nextPosition * 100);
        this.onScroll(nextPosition * 100, true);

        this.$emit("zoom", scaleValue);
        this.setZoom(scaleValue);

        event.preventDefault();
    }

    getLimitScroll() {
        const cw = this.$refs.container.offsetWidth;
        const tw = this.zoom * cw;

        return (tw - cw) / tw;
    }

    getInverseLimitScroll() {
        if (this.zoom === 1) {
            return 0;
        }

        const cw = this.$refs.container.offsetWidth;
        const tw = this.zoom * cw;

        return tw / (tw - cw);
    }

    onScroll(value, ended) {
        if (this.fixedTimeline) {
            return;
        }
        this.$refs.container.style.setProperty("--scroll", `${value}%`);
        this.$refs.container.style.setProperty("--limitScroll", `${this.getLimitScroll()}`);

        this.$emit("scroll", value, ended);
    }

    setZoom(scaleValue) {
        this.zoom = scaleValue;

        this.$refs.container.style.setProperty("--scale", scaleValue);
        this.$refs.container.classList.toggle("active-hour-half", scaleValue > 4);
        this.$refs.container.classList.toggle("active-hour-quarter", scaleValue > 10);
        this.$refs.container.classList.toggle("active-hour-five", scaleValue > 25);

        this.$refs.container.style.setProperty("--limitScroll", `${this.getLimitScroll()}`);

        this.$refs.scroll.setZoom(scaleValue);
    }

    setScroll(value) {
        if (this.zoom === 1) {
            value = 0;
        }

        value = Math.min(value, 100);

        this.$refs.container.style.setProperty("--scroll", `${value}%`);
        this.$refs.scroll.setScroll(value);
        this.$refs.container.style.setProperty("--limitScroll", `${this.getLimitScroll()}`);
    }

    _getDateAtX(pageX) {
        const x = pageX - this._containerPos.x;
        const timeline = this.$refs.timeline;
        const position = ((-timeline.offsetLeft + x) / timeline.offsetWidth) * 100;
        const date = getDateWithLeft(this.timelineService.currentDate, position);
        return date;
    }

    async createTask(e) {
        if (!e.target.classList.contains("hour") && !e.target.classList.contains("timeline")) {
            // Double click on existing task
            return;
        }

        const startDate = this._getDateAtX(e.pageX);
        this._createTask(startDate);
    }

    async _createTask(startDate, endDate = null) {
        if (endDate === null) {
            endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // 1h
        }

        const id = await this.timelineService.createTask({
            startDate,
            endDate,
        });

        this.$router.push(`/timeline/edit/${id}`);
        this.update();
    }

    renderHours() {
        const hours = [];

        const renderHour = (text, position, extraClass) => (
            <div
                class={`hour is-size-6 ${extraClass}`}
                style={{ left: `calc(100% / 24 * ${position})` }}
            >
                <div>{text}</div>
            </div>
        );

        //Go 5min by 5min
        for (let position = 0, count = 0; position < 24; position += 0.25 / 3, count++) {
            //We need to round up because adding 0.25/3 will not result in round numbers
            const roundPosition = Math.round((position + Number.EPSILON) * 1000) / 1000;

            if (roundPosition % 1 === 0) {
                hours.push(renderHour(`${roundPosition}h`, position, "has-text-weight-bold"));
                count = 0;
            } else if (roundPosition % 0.5 === 0) {
                hours.push(renderHour(`${Math.trunc(roundPosition)}h30`, position, "hour-half"));
            } else if (roundPosition % 0.25 === 0) {
                hours.push(
                    renderHour(
                        `${Math.trunc(roundPosition)}h${count * 5}`,
                        position,
                        "hour-quarter"
                    )
                );
            } else {
                const minutes = count === 1 ? "05" : count * 5;
                hours.push(
                    renderHour(`${Math.trunc(roundPosition)}h${minutes}`, position, "hour-five")
                );
            }
        }

        return hours;
    }

    async update() {
        this.$refs.currentTime.update();
        this.$refs.tasks.update();
        this.$refs.activities.update();
        if (this.displayEventsEnabled) {
            this.$refs.events.update();
        }
    }

    drawRectangle(e) {
        const width = Math.abs(e.clientX - this._selectionRectangle.startX);

        const adjustedLeft =
            this._selectionRectangle.startX > e.clientX
                ? e.clientX - this._containerPos.x
                : this._selectionRectangle.startX - this._containerPos.x;

        let adjustedTop;
        let height = Math.abs(e.clientY - this._selectionRectangle.startY);
        if (this._selectionRectangle.startY > e.clientY) {
            adjustedTop = e.clientY - this._containerPos.y;
            // ensure the selection does not overflow the top of the timeline
            if (adjustedTop - this._selectionRectangle.hourMarkTop < 0) {
                adjustedTop = this._selectionRectangle.hourMarkTop;
                height =
                    this._selectionRectangle.startY -
                    this._containerPos.y -
                    this._selectionRectangle.hourMarkTop;
            }
        } else {
            adjustedTop = this._selectionRectangle.startY - this._containerPos.y;
        }

        this.$refs.select.style.left = `${adjustedLeft}px`;
        this.$refs.select.style.top = `${adjustedTop}px`;
        this.$refs.select.style.width = `${width}px`;
        this.$refs.select.style.height = `${height}px`;
    }

    drawCreateTaskRectangle() {
        const forceX = this.$refs.underCursorTime.getCurrentPosition();

        const width = Math.abs(forceX - this._selectionCreateTask.startX);

        const adjustedLeft =
            this._selectionCreateTask.startX > forceX
                ? forceX - this._containerPos.x
                : this._selectionCreateTask.startX - this._containerPos.x;

        this.$refs.createTask.style.left = `${adjustedLeft}px`;
        this.$refs.createTask.style.width = `${width}px`;
    }

    onMouseDown(e) {
        e.stopPropagation();
        e.preventDefault();

        // Create task from selection
        if (!this.$refs.underCursorTime.isHidden) {
            this._selectionCreateTask.startX = this.$refs.underCursorTime.getCurrentPosition();
            this._selectionCreateTask.show = true;

            this.onMouseUp = async () => {
                window.removeEventListener("mouseup", this.onMouseUp);
                this._selectionCreateTask.show = false;
                this.$refs.underCursorTime.setHidden(true);

                this._selectionCreateTask.endX = this.$refs.underCursorTime.getCurrentPosition();

                // create a task from the selection rectangle on an empty period and with no running task
                const startDate = this._getDateAtX(
                    Math.min(this._selectionCreateTask.endX, this._selectionCreateTask.startX)
                );
                const endDate = this._getDateAtX(
                    Math.max(this._selectionCreateTask.endX, this._selectionCreateTask.startX)
                );

                try {
                    await this._createTask(startDate, endDate);
                } catch {
                    this.$buefy.toast.open({
                        message: this.$t("error.server"),
                        type: "is-danger",
                    });
                }

                this.$refs.createTask.style.left = `0px`;
                this.$refs.createTask.style.width = `0px`;
            };
            window.addEventListener("mouseup", this.onMouseUp);

            return;
        }

        // Selection tasks
        this._selectionRectangle.startX = e.clientX;
        this._selectionRectangle.startY = e.clientY;
        this._selectionRectangle.pageX = e.pageX;
        this._selectionRectangle.show = true;

        this.onMouseUp = async () => {
            window.removeEventListener("mouseup", this.onMouseUp);

            this.$refs.tasks.selectTasksInRect(this.$refs.select.getBoundingClientRect());

            // Reset rectangle size
            this.$refs.select.style.width = `0px`;
            this.$refs.select.style.height = `0px`;

            this._selectionRectangle.show = false;
        };
        window.addEventListener("mouseup", this.onMouseUp);
    }

    duplicateTasks(copy) {
        if (copy) {
            this.copyTasks();
        } else {
            this.pasteTasks();
        }
    }

    copyTasks() {
        const selectedTasks = this.$refs.tasks.getSelectedTasks();
        if (selectedTasks.length === 0) {
            return;
        }
        this.copiedTasks = selectedTasks;
        this.$buefy.toast.open({
            message: this.$t("Timeline.copied"),
            type: "is-success",
        });
        this.$emit("copyTasks");
    }

    pasteTasks() {
        const position =
            (-this.$refs.timeline.offsetLeft / this.$refs.timeline.offsetWidth) * 100 +
            this._containerPos.x / 2 / this.zoom; // to center the position
        const initialDate = getDateWithLeft(this.timelineService.currentDate, position);

        const firstStartDate = new Date(
            this.copiedTasks.reduce((first, task) => {
                if (!first) {
                    first = task.startDate.getTime();
                }
                return Math.min(first, task.startDate.getTime());
            }, 0)
        );

        let addedDuration;
        this.copiedTasks.forEach(async (task) => {
            addedDuration = intervalToDuration({ start: firstStartDate, end: task.startDate });
            const startDate = add(initialDate, addedDuration);
            const taskDuration = intervalToDuration({ start: task.startDate, end: task.endDate });
            const endDate = add(startDate, taskDuration);
            await this.timelineService.createTask({
                ...task,
                startDate,
                endDate,
                id: null,
            });
        });
        this.update();
        this.$emit("pasteTasks");
        this.copiedTasks = [];
    }

    onTasksSelectionChanges(hasSelection) {
        this.$emit("hasSelection", hasSelection);
    }

    renderTimelineScrollBar() {
        if (this.fixedTimeline) {
            return (
                <div class="pl-2 pr-2">
                    <TimelineScrollBar
                        ref="scroll"
                        onscroll={this.onScroll}
                        style="visibility: hidden;"
                    />
                </div>
            );
        }
        return (
            <div class="pl-2 pr-2">
                <TimelineScrollBar ref="scroll" onscroll={this.onScroll} />
            </div>
        );
    }

    render() {
        const hours = this.renderHours();

        return (
            <div ref="container" class="timeline-container">
                <div
                    ref="timeline"
                    class="timeline"
                    onwheel={this.onZoomOnWheel}
                    ondblclick={this.createTask}
                    onmousedown={this.onMouseDown}
                >
                    {hours}
                    <TimelineCurrentTime ref="currentTime" />
                    <TimelineUnderCursorTime ref="underCursorTime" />
                    <Tasks ref="tasks" onHasSelection={this.onTasksSelectionChanges} />
                    <Activities ref="activities" />
                    {this.displayEventsEnabled && <Events ref="events" />}
                </div>
                {this.renderTimelineScrollBar()}
                <div ref="select" class="select-rectangle"></div>
                <div ref="createTask" class="select-create-task"></div>
            </div>
        );
    }
}
</script>

<style lang="scss">
$animation-duration: 200ms;

.timeline-container {
    position: relative;
    width: 100%;
    // 1 <= scale <= 60
    --scale: 1;
    --scroll: 0;
    --limitScroll: 0;
    --hourMarkHeight: 155;
    --hourMarkTop: 46;
}

.timeline {
    position: relative;
    left: calc(-1 * var(--scale) * var(--scroll) * var(--limitScroll));
    // 1px -> 60s -> 24 x 60 = 1440px = 100%
    // 1px -> 1s  -> 24 x 60 x 60 = 86400px = 6000%
    width: calc(var(--scale) * 100%);
    height: 205px;
    transition: width $animation-duration linear, left $animation-duration linear;
}

.hour {
    position: absolute;
    top: 0;
    overflow: visible;
    user-select: none;

    > div {
        width: 100%;
        margin-left: -50%;
        color: #485460;
    }

    &::after {
        content: "";
        position: absolute;
        height: calc(var(--hourMarkHeight) * 1px);
        width: 1px;
        border: 1px dashed #808e9b;
        left: 0;
        top: calc(var(--hourMarkTop) * 1px);
    }
}

.hour-half,
.hour-quarter,
.hour-five {
    opacity: 0;
    transition: opacity $animation-duration linear;
}

.active-hour-half {
    .hour-half {
        opacity: 1;
    }
}

.active-hour-quarter {
    .hour-quarter {
        opacity: 1;
    }
}

.active-hour-five {
    .hour-five {
        opacity: 1;
    }
}

.select-rectangle {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.select-create-task {
    position: absolute;
    background-color: rgba(10, 61, 98, 0.6);
    border-radius: 4px;
    top: 75px;
    height: 85px;
}
</style>
