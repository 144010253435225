import { Service } from "../base/Service";
import { inject } from "../base/Injection";
import { TimelineService } from "./TimelineService";
import { Fetcher } from "../base/Fetcher";
import { IdleService, TIMER_ACTION } from "./IdleService";
import { UserService, UserStatusEvents } from "./UserService";
import { getHours, add, set, startOfDay, endOfDay } from "date-fns";

// Number of hours a task can be running before printing a warning
const STOP_TASK_MAX_DURATION = 10;
// Hour at which stop the task
const STOP_TASK_HOURS = 18;

export class TimerService extends Service {
    @inject(UserService) userService;
    @inject(TimelineService) timelineService;
    @inject(IdleService) idleService;
    @inject(Fetcher) fetcher;

    task = null;

    constructor(context) {
        super(context);

        this.resume();

        this.userService.on(UserStatusEvents.LOGGED_OUT, () => {
            this.task = null;
            clearInterval(this.timerId);
        });
        this.userService.on(UserStatusEvents.LOGGED_IN, () => {
            this.resume();
        });

        this.idleService.on(TIMER_ACTION.STOP, (date) => {
            this.stop(date);
        });

        this.idleService.on(TIMER_ACTION.NEW, (date) => {
            this.start(null, date);
        });
    }

    get value() {
        if (this.task === null) {
            return 0;
        }
        return Date.now() - this.task.startDate;
    }

    get startDate() {
        if (this.task === null) {
            return Date.now();
        }
        return this.task.startDate;
    }

    get isTaskRunning() {
        if (this.task === null) {
            return false;
        }
        return this.task.running;
    }

    async resume() {
        try {
            const previousTaskRunning = await this.fetcher.get("/period/running");
            const [converted] = await this.timelineService.convertPeriodToTasks(
                previousTaskRunning,
            );
            this.task = converted;
            this.emit("change", this.value);
            this.createInterval();
            this.idleService.run();
        } catch (e) {
            if (e.status !== 404) {
                throw e;
            }

            console.warn("No running period found");
        }
    }

    async start(task, startDate = new Date()) {
        if (this.task !== null) {
            await this.stop(startDate);
        }

        this.task = {
            ...task,
            startDate: startDate,
            endDate: startDate,
            running: true,
        };

        delete this.task.id;
        this.task.id = await this.timelineService.createTask(this.task);

        this.emit("change", this.value);
        this.createInterval();
        this.idleService.run();

        return this.task.id;
    }

    async stop(endDate = new Date()) {
        if (endDate < this.task.startDate) {
            endDate = this.task.startDate;
        }
        this.task.endDate = endDate;
        this.task.running = false;

        await this.timelineService.updateTask(this.task);
        await this.fetcher.get("/period/stop");

        this.emit("change", 0);
        clearInterval(this.timerId);
        this.task = null;
        this.timerId = null;
        this.idleService.stop();
    }

    createInterval() {
        clearInterval(this.timerId);

        this.timerId = setInterval(() => {
            const newTask = this.timelineService._replaceTask({
                id: this.task.id,
                endDate: new Date(),
            });

            if (newTask) {
                this.task = newTask;
            }
            this.emit("change", this.value);
        }, 1000);
    }

    async getTotal() {
        const tasks = await this.timelineService.getTasks();

        const startDay = startOfDay(this.timelineService.currentDate);
        const endDay = endOfDay(this.timelineService.currentDate);

        return tasks.reduce((sum, task) => {
            return (
                sum +
                (task.running
                    ? this.value
                    : Math.min(endDay, task.endDate) - Math.max(startDay, task.startDate))
            );
        }, 0);
    }

    warnStopDate() {
        // Have a timer ?
        if (!this.startDate) {
            return;
        }

        // Not exceed the max duration ?
        if (this.value <= STOP_TASK_MAX_DURATION * 3600 * 1000) {
            return;
        }

        const startHours = getHours(this.startDate);
        // Start after le stop task hours
        if (startHours >= STOP_TASK_HOURS) {
            return add(this.startDate, { hours: 1 });
        }

        return set(this.startDate, {
            hours: STOP_TASK_HOURS,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
        });
    }
}
