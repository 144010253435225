import { Service } from "../base/Service";
import { inject } from "../base/Injection";
import { Fetcher } from "../base/Fetcher";

export class TagService extends Service {
    @inject(Fetcher) fetcher;

    getTags() {
        return this.fetcher.get("/tags");
    }
}
