<script>
import Component from "vue-class-component";
import Vue from "vue";

import { inject } from "../../base/Injection";

import { TimelineService } from "../../services/TimelineService";
import { getProgressInDay, replaceEmoji } from "../../base/Utils";
import { addHours, differenceInSeconds, isSameDay } from "date-fns";

const EVENT_TYPES = {
    scm: {
        icon: "code-fork",
        color: 1,
        toTask(event) {
            return {
                title: event.data.message,
                tags: [event.data.namespace, event.data.project],
            };
        },
    },
    code: {
        icon: "code",
        color: 2,
        toTask(event) {
            return {
                tags: [event.data.language, event.data.project],
            };
        },
    },
    idle: {
        icon: "power-off",
        color: 3,
        toTask() {
            return {};
        },
    },
    application: {
        icon: "circle",
        color: 4,
        toTask(event) {
            return {
                title: event.data.title + event.data.name,
            };
        },
    },
    note: {
        icon: "sticky-note",
        color: 5,
        toTask(event) {
            return {
                title: event.data.content,
                tags: [this.$t("Events.note")],
            };
        },
    },
    shell: {
        icon: "terminal",
        color: 6,
        toTask(event) {
            return {
                title: event.data.command,
                tags: [event.data.device, event.data.program, event.data.shell],
            };
        },
    },
    task: {
        icon: "tasks",
        color: 7,
        toTask(event) {
            return {
                title: event.data.description,
                tags: [event.data.status],
            };
        },
    },
    call: {
        icon: "phone",
        color: 8,
        toTask(event) {
            return {
                title: event.data.name,
                tags: [this.$t("Events.call")],
            };
        },
    },
    calendar: {
        icon: "calendar",
        color: 9,
        toTask(event) {
            return {
                title: event.data.title,
            };
        },
    },
    step: {
        icon: "circle",
        color: 10,
        toTask(event) {
            return {
                title: this.$t("Events.step.xSteps", event.data),
            };
        },
    },
    heartbeat: {
        icon: "heart",
        color: 11,
        toTask(event) {
            return {
                title: this.$t("Events.heartbeat.xBeatsPerMinute", event.data),
            };
        },
    },
    location: {
        icon: "map-marker",
        color: 12,
        toTask(event) {
            return {
                title: event.data.place ? event.data.place : this.$t("Events.location.emptyPlace"),
            };
        },
    },
    mark: {
        icon: "pause",
        color: 13,
        toTask() {
            return {};
        },
    },
};

@Component
export default class Events extends Vue {
    @inject(TimelineService) timelineService;

    currentDate = null;
    events = [];

    created() {
        this.currentDate = this.timelineService.currentDate;
        this.update();
    }

    async update() {
        if (!isSameDay(this.currentDate, this.timelineService.currentDate)) {
            this.events = [];
            this.currentDate = this.timelineService.currentDate;
        }
        this.events = (await this.timelineService.getEvents()).events || [];
    }

    async createTask(e, event) {
        const newTaskEventType = EVENT_TYPES[event.type].toTask.call(this, event);
        const newTask = {
            title: "",
            startDate: event.startDate,
            endDate: event.endDate,
            tags: [],
            ...newTaskEventType,
        };
        if (differenceInSeconds(new Date(newTask.startDate), new Date(newTask.endDate)) === 0) {
            newTask.endDate = addHours(new Date(newTask.endDate), 1);
        }
        const id = await this.timelineService.createTask(newTask);
        this.$router.push(`/timeline/edit/${id}`);
    }

    renderEvent(event) {
        switch (event.type) {
            case "scm":
                return replaceEmoji(event.data.message);
            case "application":
                return event.data.title + " (" + event.data.name + ")";
            case "note":
                return replaceEmoji(event.data.content);
            case "shell":
                return event.data.command;
            case "task":
                return replaceEmoji(event.data.description);
            case "call":
                return replaceEmoji(event.data.name);
            case "calendar":
                return replaceEmoji(event.data.title);
            case "step":
                return this.$t("Events.step.xSteps", event.data);
            case "heartbeat":
                return this.$t("Events.heartbeat.xBeatsPerMinute", event.data);
            case "location": {
                const mapUrl = `https://www.openstreetmap.org/#map=13/${event.data.latitude}/${event.data.longitude}`;
                return (
                    <a href={mapUrl}>
                        {event.data.place
                            ? event.data.place
                            : this.$t("Events.location.emptyPlace")}
                    </a>
                );
            }
            case "mark":
                return event.data.tags;
            case "code":
            case "idle":
            default:
                return JSON.stringify(event.data);
        }
    }

    render() {
        const currentDate = this.currentDate;

        return (
            <div>
                {this.events.map((event) => {
                    const position = getProgressInDay(new Date(event.startDate), currentDate);
                    return (
                        <div
                            class={`doted color-${EVENT_TYPES[event.type].color}`}
                            style={`left:${position}%;`}
                            ondblclick={(e) => this.createTask(e, event)}
                        >
                            <div class={`event--content color-${EVENT_TYPES[event.type].color}`}>
                                <div class="event--title">
                                    <span key={`cat-${event.type}`}>
                                        <i class={`fa fa-${EVENT_TYPES[event.type].icon}`}></i>
                                    </span>
                                    {this.renderEvent(event)}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}
</script>
<style lang="scss" scoped>
@import "../../../css/variables";

.doted {
    position: absolute;
    cursor: pointer;
    bottom: 20px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    font-size: 14px;

    &:hover .event--content {
        display: flex;
    }
}

.event--content {
    display: none;
    position: absolute;
    top: 17px;
    flex-direction: column;
    z-index: 1000;

    padding: 10px 15px;
    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(33, 33, 33, 0.7);

    i {
        margin-right: 5px;
    }

    a {
        color: inherit;
    }
}
</style>
