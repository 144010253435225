<script>
import Component from "vue-class-component";
import Vue from "vue";
import EmptyTable from "../common/EmptyTable.vue";
import { durationStringAccordingToDisplayModes } from "../../base/Utils";

const Props = Vue.extend({
    props: {
        chartdata: Object,
        report: Object,
    },
});

@Component
export default class ReportChartTable extends Props {
    dataArray = [];
    dataColumns = [];
    fetching = false;

    async created() {
        if (!this.chartdata.labels) {
            return;
        }

        this.dataArray = this.chartdata.labels.map((label) => ({ label }));
        this.dataColumns = [
            {
                field: "label",
                label: this.$t("ReportChartTable.label"),
                subheading: this.$t("ReportChartTable.total"),
            },
        ];

        this.chartdata.datasets.forEach((ds) => {
            const column = {
                field: ds.label || "data",
                label: ds.label || this.$t("ReportChartTable.data"),
                sortable: true,
                cellClass: "fix-size-duration",
            };
            this.dataColumns.push(column);

            if (ds.data) {
                ds.data.forEach((d, index) => {
                    this.dataArray[index][ds.label || "data"] =
                        durationStringAccordingToDisplayModes(this._i18n, this.report, d, ds.total);
                });
            }

            column.subheading = durationStringAccordingToDisplayModes(
                this._i18n,
                this.report,
                ds.total,
                ds.total
            );
        });
    }

    render() {
        return (
            <b-table
                data={this.dataArray}
                default-sort={"label"}
                default-sort-direction={"asc"}
                sort-icon="chevron-up"
                sort-icon-size="is-small"
                hoverable
                loading={this.fetching}
                columns={this.dataColumns}
            >
                <EmptyTable
                    text={this.$t("ReportChartTable.empty")}
                    icon="fa-table"
                    visible={!this.fetching && this.dataArray && !this.dataArray.length}
                />
            </b-table>
        );
    }
}
</script>

<style lang="scss">
@import "../../../css/variables";
.table td {
    &.fix-size-duration {
        width: 200px;
    }
}
</style>
