<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { UserService } from "../../services/UserService";

@Component
export default class ConfirmEmail extends Vue {
    @inject(UserService) userService;

    created() {
        if (!this.userService.hasConfirmEmail()) {
            this.$router.replace("/register").catch(() => {});
        }
    }

    mounted() {
        this.$refs.code.focus();
    }

    async confirm() {
        try {
            await this.userService.signUp(this.$refs.code.newValue);

            this.$buefy.toast.open({
                message: this.$t("ConfirmEmail.success"),
                type: "is-success",
            });

            this.$router.push("/login").catch(() => {});
        } catch (e) {
            if (e.status === 406) {
                this.$buefy.toast.open({
                    message: this.$t("ConfirmEmail.error-code"),
                    type: "is-danger",
                });
            } else if (e.status === 400) {
                this.$buefy.toast.open({
                    message: this.$t("ConfirmEmail.error"),
                    type: "is-danger",
                });
                this.$router.replace("/register").catch(() => {});
            } else {
                this.$buefy.toast.open({
                    message: this.$t("error.server"),
                    type: "is-danger",
                });
            }
        }
    }

    async resend() {
        try {
            await this.userService.resendConfirmEmail();
            this.$buefy.toast.open({
                message: this.$t("ConfirmEmail.resend-mail"),
                type: "is-success",
            });
        } catch (e) {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
        }
    }

    cancel() {
        this.userService.cancelConfirmEmail();
        this.$router.replace("/register").catch(() => {});
    }

    submit(e) {
        this.confirm();

        e.preventDefault();
        e.stopPropagation();
    }

    render() {
        return (
            <form class="tab-forms" onsubmit={this.submit}>
                <b-tabs ref="tabs" animated={false} expanded={true}>
                    <b-tab-item label={this.$t("ConfirmEmail.title")}>
                        <div class="mb-4">{this.$t("ConfirmEmail.message")}</div>
                        <b-field label={this.$t("ConfirmEmail.code")}>
                            <b-input
                                ref="code"
                                placeholder={this.$t("ConfirmEmail.code-placeholder")}
                                type="code"
                                required
                                maxlength="6"
                            ></b-input>
                        </b-field>
                        <div class="control has-text-right">
                            <button class="button is-primary is-fullwidth">
                                {this.$t("ConfirmEmail.submit")}
                            </button>
                        </div>
                        <a class="link" onClick={this.resend}>
                            {this.$t("ConfirmEmail.resend")}
                        </a>
                        <a class="link" onClick={this.cancel}>
                            {this.$t("ConfirmEmail.cancel")}
                        </a>
                    </b-tab-item>
                </b-tabs>
            </form>
        );
    }
}
</script>

<style lang="scss" scoped>
.tab-forms {
    box-shadow: 0 0 0 1px #e5e5e5;
    border-bottom-right-radius: 0.25rem;
    border-radius: 0.25rem;
    width: 460px;
}

.link {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.75rem;
}
</style>
