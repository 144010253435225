<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../base/Injection";
import { ReportService } from "../services/ReportService";
import ReportItem from "../components/report/ReportItem.vue";
import PaginatedRows from "../components/common/PaginatedRows.vue";

@Component
export default class ReportsPage extends Vue {
    @inject(ReportService) reportService;

    mounted() {
        this.reportService.on("change", this.$refs.rows.loadData);
    }

    beforeDestroy() {
        this.reportService.off("change", this.$refs.rows.loadData);
    }

    async getReports(selector) {
        const reports = await this.reportService.getAllReports(selector);
        return reports;
    }

    openCreateReportsPage() {
        this.$router.push("/reports/new");
    }

    openCharts(reportId) {
        this.$router.push(`/reports/charts/${reportId}`);
    }

    render() {
        return (
            <div class="page-content">
                <div class="page-title">{this.$t("ReportsPage.title")}</div>

                <div class="page-full page-full--padded">
                    <div class="field-inline">
                        <button
                            class="button is-primary is-rounded is-outlined"
                            onclick={this.openCreateReportsPage}
                        >
                            {this.$t("ReportsPage.new")}
                        </button>
                    </div>

                    <PaginatedRows
                        ref="rows"
                        fetchData={this.getReports}
                        pageSize={10}
                        orderBy={{
                            name_asc: this.$t("ReportsPage.name_asc"),
                            name_desc: this.$t("ReportsPage.name_desc"),
                            pinning_asc: this.$t("ReportsPage.pinning_asc"),
                            pinning_desc: this.$t("ReportsPage.pinning_desc"),
                        }}
                        scopedSlots={{
                            default: (r) => (
                                <ReportItem
                                    clickCb={(rId) => this.openCharts(rId)}
                                    report={r}
                                    key={`report-${r.id}`}
                                />
                            ),
                        }}
                    />
                </div>
            </div>
        );
    }
}
</script>
