<script>
import Component from "vue-class-component";
import Vue from "vue";
import { format } from "../../base/Utils";
import { Report, ReportService, REPORT_FREQUENCY } from "../../services/ReportService";
import { inject } from "../../base/Injection";
import ProfileIcon from "../common/ProfileIcon.vue";
import { COLLABORATOR_TYPE } from "../../services/ProjectService";

const Props = Vue.extend({
    props: {
        request: Object,
        clickCb: Function,
    },
});

@Component
export default class RequestItem extends Props {
    @inject(ReportService) reportService;

    report = new Report();

    created() {
        this.report = this.request?.report;
    }

    renderProjectNames() {
        return this.report.projects?.reduce((items, project, index) => {
            if (index < 3) {
                return [...items, <span class={`tag color-${project.color}`}>{project.name}</span>];
            }
            if (index === 3) {
                return [...items, <span class="tag">+ {this.report.projects.length - 3}</span>];
            }
            return items;
        }, []);
    }

    renderProjectTags() {
        return this.report.tags?.reduce((items, tag, index) => {
            if (index < 3) {
                return [...items, <span class="tag">{tag}</span>];
            }
            if (index === 3) {
                return [...items, <span class="tag">+ {this.report.tags.length - 3}</span>];
            }
            return items;
        }, []);
    }

    render() {
        return (
            <div class="card card--report" onclick={() => this.clickCb(this.report.id)}>
                <header class="card-header">
                    <div class="card-header-title">
                        <p class="tags">{this.renderProjectNames()}</p>
                        <p class="tags">{this.renderProjectTags()}</p>
                    </div>
                </header>
                <div class="card-content">
                    <p class="title is-4">{this.report.name}</p>
                    <div class="content">
                        {this.report.description || this.$t("RequestItem.noDescription")}
                    </div>
                    <p class={{ tags: true, "is-hidden": !this.report.validationSheetsLateCount }}>
                        <div class="tag is-warning">
                            {this.report.synchronizable
                                ? this.$tc(
                                      "RequestItem.toSync",
                                      this.report.validationSheetsLateCount,
                                      { count: this.report.validationSheetsLateCount }
                                  )
                                : this.$tc(
                                      "RequestItem.toValidated",
                                      this.report.validationSheetsLateCount,
                                      { count: this.report.validationSheetsLateCount }
                                  )}
                        </div>
                    </p>
                </div>
                <footer class="card-footer card-footer--report">
                    <div
                        class={`report-date ${
                            this.report.frequency === REPORT_FREQUENCY.CUSTOM &&
                            this.report.startDate &&
                            this.report.endDate
                                ? ""
                                : "is-hidden"
                        }`}
                    >
                        {format(new Date(this.report.startDate), "dd MMM")}
                        <span> - </span>
                        {format(new Date(this.report.endDate), "dd MMM")}
                    </div>
                    {this.report.synchronizable && (
                        <i
                            class={{
                                fa: true,
                                "fa-sync": this.report.synchronizable,
                            }}
                            aria-hidden="true"
                        ></i>
                    )}
                    {!this.report.synchronizable && (
                        <b-tooltip
                            label={`${this.$t("RequestItem.asked-by")} ${this.report.owner.name}`}
                            delay={250}
                        >
                            <ProfileIcon
                                avatar={this.report.owner.avatar}
                                type={COLLABORATOR_TYPE.USER}
                                height={24}
                                class="requestItem-profileIcon"
                            />
                        </b-tooltip>
                    )}
                </footer>
            </div>
        );
    }
}
</script>
<style lang="scss" scoped>
@import "../../../css/variables.scss";

$internal-card-padding: 1rem;

.card--report {
    width: $card-width;
    height: $card-height;
    border-radius: 10px;
    margin: 1rem;
    cursor: pointer;
    overflow: visible;

    flex-shrink: 0;
    flex-grow: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-content {
        cursor: pointer;

        .title {
            margin-bottom: $internal-card-padding;
        }
    }

    .card-header-title {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: $internal-card-padding;

        .tags {
            margin-bottom: 0;
        }
    }
}

.card-footer--report {
    padding: $card-footer-padding;
    padding-top: $internal-card-padding;
    justify-content: flex-end;
}

.report-date {
    flex-grow: 1;
}

.title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.team-icon {
    display: inline-flex;
}
.team-count {
    margin-left: 5px;
}

.report-item-dropdown {
    height: 20px;
}

.report-item-dropdown-container {
    padding: 1.5rem;
    padding-bottom: $internal-card-padding;
}

.requestItem-profileIcon {
    .profileIcon-icon {
        border: solid 1px rgba(0, 0, 0, 0.4);
    }
}
</style>
