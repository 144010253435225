<script>
import Component from "vue-class-component";
import Vue from "vue";

import { inject } from "../../base/Injection";
import { TimerService } from "../../services/TimerService";
import { diffValue } from "../../base/Utils";
import { format } from "../../base/Utils";

@Component
export default class Timer extends Vue {
    @inject(TimerService) timerService;
    timerId;
    value = 0;
    startDate = 0;

    created() {
        if (this.timerService.value > 0) {
            this.value = this.timerService.value;
        }

        this.onTimerChange = (value) => {
            this.value = value;
            this.startDate = this.timerService.startDate;
        };
        this.timerService.on("change", this.onTimerChange);
    }

    destroyed() {
        this.timerService.off("change", this.onTimerChange);
    }

    async start() {
        const id = await this.timerService.start();
        this.$router.push(`/timeline/edit/${id}`).catch(() => {});
    }

    async stop(date) {
        await this.timerService.stop(date);
        this.value = 0;
        this.$router.push(`/timeline`).catch(() => {});
    }

    render() {
        const action = this.value ? (
            <button
                key="stop"
                class="button button-timer is-rounded is-danger is-outlined"
                onclick={() => this.stop()}
            >
                <span class="icon">
                    <i class="fa fa-stop"></i>
                </span>
                <span>{this.$t("Timer.stop")}</span>
            </button>
        ) : (
            <button
                key="start"
                class="button button-timer is-rounded is-primary is-outlined"
                onclick={this.start}
            >
                <span class="icon">
                    <i class="fa fa-play"></i>
                </span>
                <span>{this.$t("Timer.start")}</span>
            </button>
        );

        const stopDate = this.timerService.warnStopDate();
        const stopLongTaskTheDayItBeganAction = stopDate ? (
            <article class="message is-warning mt-2">
                <div class="message-header">
                    <p>Warning</p>
                </div>
                <div class="message-body has-text-centered">
                    <div>{this.$t("Timer.longTask")}</div>

                    <button
                        key="stop"
                        class="button is-rounded is-danger is-outlined"
                        onclick={() => this.stop(stopDate)}
                    >
                        <span class="icon">
                            <i class="fa fa-stop"></i>
                        </span>
                        <span>
                            {this.$t("Timer.stopAt")}
                            {format(stopDate, "EEEE dd MMMM yyyy HH:mm")}
                        </span>
                    </button>
                </div>
            </article>
        ) : null;

        return (
            <div class="timer-holder">
                <div class="timer">
                    {action}
                    <span class="timer-value ml-2 is-family-monospace is-size-5">
                        {diffValue(this.value)}
                    </span>
                </div>
                {stopLongTaskTheDayItBeganAction}
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.timer-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.timer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.button-timer {
    width: 110px;
}

.timer-value {
    color: #1e272e;
}
</style>
