<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { ProjectService } from "../../services/ProjectService";
import ProjectSelector from "../common/ProjectSelector.vue";

const Props = Vue.extend({
    props: {
        task: Object,
        test: String,
    },
});

@Component
export default class MoveTaskModal extends Props {
    @inject(ProjectService) projectService;

    async submit(e) {
        e.stopPropagation();
        e.preventDefault();

        if (this._request) {
            return;
        }

        const project = this.$refs.project.getSelectedProject();
        if (!project) {
            return;
        }

        const onlyForMe = this.$refs.onlyForMe.newValue;
        const projectId = this.$route.params.id;

        const button = this.$refs.submit;
        button.classList.add("is-loading");

        this._request = this.projectService.moveTasks(
            projectId,
            this.task.title,
            this.task.tags,
            project.id,
            onlyForMe
        );

        try {
            await this._request;
            this.$buefy.toast.open({
                message: this.$t("MoveTaskModal.moved"),
                type: "is-success",
            });

            this.$emit("close", true);
        } catch (e) {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
        } finally {
            this._request = null;
            button.classList.remove("is-loading");
        }
    }

    render() {
        return (
            <form onsubmit={this.submit}>
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">{this.$t("MoveTaskModal.title")}</p>
                    </header>
                    <section class="modal-card-body">
                        <b-field label={this.$t("MoveTaskModal.project")}>
                            <ProjectSelector ref="project" />
                        </b-field>

                        <b-field label={this.$t("MoveTaskModal.onlyForMe")}>
                            <b-checkbox ref="onlyForMe">
                                {this.$t("MoveTaskModal.onlyForMeValue")}
                            </b-checkbox>
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <button
                            class="button"
                            type="button"
                            onclick={() => this.$emit("close", false)}
                        >
                            {this.$t("MoveTaskModal.close")}
                        </button>
                        <button ref="submit" class="button is-primary">
                            {this.$t("MoveTaskModal.submit")}
                        </button>
                    </footer>
                </div>
            </form>
        );
    }
}
</script>
