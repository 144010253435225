import { Service } from "../base/Service";
import { inject } from "../base/Injection";
import { Fetcher } from "../base/Fetcher";
import { EMPTY_UUID } from "../base/Utils";

export const COLLABORATOR_TYPE = {
    USER: "user",
    TEAM: "team",
};
export class ProjectService extends Service {
    @inject(Fetcher) fetcher;

    getProjects(selector) {
        return this.fetcher.get("/projects", selector);
    }

    getProjectById(id) {
        return this.fetcher.get(`/project/${id}`);
    }

    async removeProjectById(id) {
        await this.fetcher.delete(`/project/${id}`);
        this.fireChange();
    }

    searchProjects(q) {
        return this.fetcher.get(`/projects/search`, { q });
    }

    searchTasks(id, q) {
        if (!id || id === EMPTY_UUID) {
            return [];
        }
        return this.fetcher.get(`/project/${id}/tasks/search`, { q });
    }

    searchIssues(id, q) {
        if (!id || id === EMPTY_UUID) {
            return [];
        }
        return this.fetcher.get(`/project/${id}/issues/search`, { q });
    }

    async getTasks(id, selector) {
        const tasks = await this.fetcher.get(`/project/${id}/tasks`, selector);
        return tasks;
    }

    async getTags(id, selector) {
        return this.fetcher.get(`/project/${id}/tags`, selector);
    }

    async getDefaultTags(id) {
        if (!id || id === EMPTY_UUID) {
            return [];
        }

        const tags = await this.fetcher.get(`/project/${id}/default-tags`);
        return tags || [];
    }

    async getUsers(id, selector) {
        return this.fetcher.get(`/project/${id}/users`, selector);
    }

    async getTeams(id, selector) {
        return this.fetcher.get(`/project/${id}/teams`, selector);
    }

    async getCollaborators(ids) {
        return this.fetcher.post(`/projects/collaborators`, ids);
    }

    async createProject(project) {
        await this.saveProject(project);
        this.fireChange();
    }

    async saveProject(project) {
        await this.fetcher.post("/project", project);
        this.fireChange();
    }

    async saveTask(id, { title, tags }) {
        await this.fetcher.post("/task", { projectId: id, title, tags });
        this.fireChange();
    }

    async saveTag(id, { name, isDefault }) {
        await this.fetcher.post(`/project/${id}/tag`, { name, isDefault });
        this.fireChange();
    }

    async setDefaultTag(id, { name, isDefault }) {
        await this.fetcher.post(`/project/${id}/tag`, { name, isDefault });
    }

    async saveUserPermission(projectId, userId, permission) {
        await this.fetcher.post(`/permission/user`, { projectId, userId, permission });
        this.fireChange();
    }

    async removeUserPermission(projectId, userId) {
        await this.fetcher.delete(`/permission/user`, { projectId, userId });
        this.fireChange();
    }

    async saveTeamPermission(projectId, teamId, permission) {
        await this.fetcher.post(`/permission/team`, { projectId, teamId, permission });
        this.fireChange();
    }

    async removeTeamPermission(projectId, teamId) {
        await this.fetcher.delete(`/permission/team`, { projectId, teamId });
        this.fireChange();
    }

    async addRepo(projectId, repo, repoProjectId) {
        await this.fetcher.post(`/project/${projectId}/repo`, { id: repoProjectId, repo });
        this.fireChange();
    }

    getRepo(projectId) {
        return this.fetcher.get(`/project/${projectId}/repo`);
    }

    async removeRepo(projectId) {
        await this.fetcher.delete(`/project/${projectId}/repo`);
        this.fireChange();
    }

    async openIssue(projectId, issueId) {
        const issue = await this.fetcher.get(`/project/${projectId}/issue`, {
            q: issueId,
        });
        if (issue) {
            window.open(issue.url);
            return true;
        }
        return false;
    }

    async createIssue(projectId, title) {
        const issue = await this.fetcher.post(`/project/${projectId}/issue?title=${title}`);
        return issue;
    }

    searchTags(name, projectIds) {
        return this.fetcher.get("/tags/search", {
            q: name,
            projectIds,
        });
    }

    async renameTag(projectId, oldTag, newTag) {
        await this.fetcher.post(
            `/project/${projectId}/tags?tag=${encodeURIComponent(oldTag)}&by=${encodeURIComponent(
                newTag,
            )}`,
        );
        this.fireChange();
    }

    async removeTag(projectId, tag) {
        await this.fetcher.delete(`/project/${projectId}/tags?tag=${encodeURIComponent(tag)}`);
        this.fireChange();
    }

    async renameTask(projectId, { title, tags, isDefault }, byTitle, byTags) {
        await this.fetcher.post(`/project/${projectId}/tasks`, {
            title,
            byTitle,
            tags,
            byTags,
            isDefault,
        });
        this.fireChange();
    }

    async removeTask(taskId) {
        await this.fetcher.delete(`/task/${taskId}`);
        this.fireChange();
    }

    async moveTasks(projectId, title, tags, toProjectId, onlyForMe) {
        await this.fetcher.put(`/project/${projectId}/tasks`, {
            title,
            tags,
            toProjectId,
            onlyForMe,
        });
        this.fireChange();
    }

    fireChange() {
        this.emit("change");
    }
}
