<script>
import Component from "vue-class-component";
import Vue from "vue";
import { UserService } from "../services/UserService";
import { inject } from "../base/Injection";

@Component
export default class SitePage extends Vue {
    @inject(UserService) userService;

    isOpenLang = false;
    promptConnection = true;
    lang = "FR";
    langs = ["FR", "EN"];

    async created() {
        this.lang = (await this.userService.getLanguage(true)).toUpperCase();
        this.promptConnection = !this.userService.isConnected();
    }

    toggleOpenLang() {
        this.isOpenLang = !this.isOpenLang;
    }

    async onChangeLanguage(value) {
        this.lang = value;
        this.isOpenLang = false;

        await this.userService.setLanguage(this.lang.toLowerCase());
        this.$root.$i18n.locale = await this.userService.getLanguage();
    }

    render() {
        return (
            <div class="site">
                <div class="site_header">
                    <img src="/img/site/logo.svg" />

                    <div class="actions">
                        {this.promptConnection ? (
                            <div>
                                <a href="/register" class="action button is-primary is-rounded">
                                    {this.$t("SitePage.header.signup")}
                                </a>
                                <a
                                    href="/login"
                                    class="action action-secondary button is-primary is-outlined is-rounded"
                                >
                                    {this.$t("SitePage.header.signin")}
                                </a>
                            </div>
                        ) : (
                            <a href="/timeline" class="action button is-primary is-rounded">
                                {this.$t("SitePage.header.open")}
                            </a>
                        )}
                        <div class="lang">
                            <a onClick={this.toggleOpenLang}>
                                {this.lang}
                                <span class="lang-caret" key={`lang-${this.isOpenLang}`}>
                                    <i
                                        class={{
                                            fa: true,
                                            "fa-caret-up": this.isOpenLang,
                                            "fa-caret-down": !this.isOpenLang,
                                        }}
                                    ></i>
                                </span>
                            </a>
                            <div class={{ "lang-items": true, "is-hidden": !this.isOpenLang }}>
                                {this.langs
                                    .filter((lang) => lang !== this.lang)
                                    .map((lang) => (
                                        <a
                                            class="lang-item"
                                            onClick={() => this.onChangeLanguage(lang)}
                                        >
                                            {lang}
                                        </a>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main">
                    <div class="slogan">
                        <div class="slogan-title">
                            <img src="/img/site/feed.svg" />
                            <div domProps={{ innerHTML: this.$t("SitePage.slogan.title") }} />
                        </div>
                        <div class="slogan-translate">{this.$t("SitePage.slogan.translate")}</div>
                        <div class="slogan-text">{this.$t("SitePage.slogan.text")}</div>
                    </div>
                    <img src="/img/site/citron.svg" />
                </div>
                <div class="features">
                    <div class="feature">
                        <img src="/img/site/feature_timeline.svg" />
                        <div>{this.$t("SitePage.feature.timeline")}</div>
                    </div>
                    <div class="feature">
                        <img src="/img/site/feature_team.svg" />
                        <div>{this.$t("SitePage.feature.team")}</div>
                    </div>
                    <div class="feature">
                        <img src="/img/site/feature_report.svg" />
                        <div>{this.$t("SitePage.feature.report")}</div>
                    </div>
                </div>
                <div class="times">
                    <div class="times-content">
                        <img class="logo" src="/img/site/time_section_logo.svg" />
                        <div
                            class="section-title"
                            domProps={{ innerHTML: this.$t("SitePage.times.title") }}
                        />
                        <div
                            class="section-content"
                            domProps={{ innerHTML: this.$t("SitePage.times.content") }}
                        />
                        <div class="times-icons">
                            <img src="/img/site/time_github_icon.svg" />
                            <img src="/img/site/time_gitlab_icon.svg" />
                            <img src="/img/site/time_jira_icon.svg" />
                            <img src="/img/site/time_redmine_icon.svg" />
                        </div>
                    </div>
                    <div class="times_screenshot">
                        <img src="/img/site/screenshot.png" />
                        <img src="/img/site/time_screenshot.png" />
                    </div>
                </div>
                <div class="report">
                    <img src="/img/site/report_section.svg" />
                    <div class="report-content">
                        <div
                            class="section-title"
                            domProps={{ innerHTML: this.$t("SitePage.report.title") }}
                        />
                        <div
                            class="section-content"
                            domProps={{ innerHTML: this.$t("SitePage.report.content") }}
                        />
                    </div>
                </div>
                <div class="demo">
                    <div class="demo-content">
                        <div
                            class="section-title"
                            domProps={{ innerHTML: this.$t("SitePage.demo.title") }}
                        />
                        <div
                            class="section-content"
                            domProps={{ innerHTML: this.$t("SitePage.demo.content") }}
                        />
                        <div class="demo-action">
                            <a href="/register" class="button is-primary is-rounded">
                                {this.$t("SitePage.demo.action")}
                            </a>
                        </div>
                        <video width="600" height="350" controls poster="/img/site/demo-poster.png">
                            <source src="/img/site/demo.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div class="donnate">
                    <img src="/img/site/donnate_section.svg" />
                    <div class="donnate-content">
                        <div
                            class="section-title"
                            domProps={{ innerHTML: this.$t("SitePage.donnate.title") }}
                        />
                        <div
                            class="section-content"
                            domProps={{ innerHTML: this.$t("SitePage.donnate.content") }}
                        />
                        <div class="donnate-action">
                            <a
                                href="https://liberapay.com/wid/"
                                class="button is-primary is-rounded"
                            >
                                {this.$t("SitePage.donnate.action")}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="contact">
                    <div class="contact_section">
                        <img src="/img/site/contact_section.svg" />
                        <div class="contact_email">
                            <div class="section-title">{this.$t("SitePage.contact.email")}</div>
                            <a
                                href="mailto:contact@wid-timer.org"
                                class="button is-primary is-outlined is-rounded contact_button"
                            >
                                <img src="/img/site/icon_email.svg" />
                                {this.$t("SitePage.contact.send")}
                            </a>
                        </div>
                        <div class="contact_gitlab">
                            <div class="section-title">{this.$t("SitePage.contact.gitlab")}</div>
                            <a
                                href="https://gitlab.nuiton.org/jruchaud/wid-cbd/"
                                class="button is-primary is-outlined is-rounded contact_button"
                            >
                                <img src="/img/site/icon_gitlab.svg" />
                                {this.$t("SitePage.contact.url")}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="site_footer">
                    Wid 2021 -<a href="http://codelutin.com">Code Lutin</a>-
                    <a href="/legals.html">{this.$t("SitePage.footer.legals")}</a>-
                    <a href="/cgu.html">{this.$t("SitePage.footer.terms")}</a>-
                    <a href="https://fr.freepik.com/pch-vector">
                        {this.$t("SitePage.footer.credits")}
                    </a>
                </div>
            </div>
        );
    }
}
</script>
<style lang="scss">
.site {
    strong {
        font-size: 72px;
        color: black;
        font-weight: normal;
    }
}
</style>

<style lang="scss" scoped>
@font-face {
    font-family: "bangers";
    src: url("../../fonts/Bangers.ttf");
    font-weight: normal;
    font-style: normal;
}

.site {
    padding-top: 81px;
}

.site_header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 81px;
    background: #ebf6fb;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.logo {
    height: 49px;
}

.actions {
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    display: flex;
    align-items: center;

    .lang {
        margin-left: 15px;

        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 100%;
        background-color: #dfeef5;
        color: #216695;

        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
    }

    .lang-caret {
        margin-left: 3px;
        font-size: 10px;
    }

    .lang-items {
        position: absolute;
        top: 81px;
        left: 0;

        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .lang-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 76px;
    }

    .action {
        width: 135px;
    }

    .action-secondary {
        background-color: white;
        margin-left: 5px;

        &:hover {
            background-color: #227093;
        }
    }
}

.main {
    display: flex;
    justify-content: center;
    min-height: 405px;
    flex-wrap: wrap-reverse;
    padding: 20px;

    > img {
        width: 268px;
        margin: 20px 41px;
    }
}

.section-title {
    color: black;
    font-family: "bangers";
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 38px;
    margin-bottom: 24px;
}

.section-content {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
}

.slogan {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 20px 41px;

    img {
        position: absolute;
        width: 153.96px;
        height: 154.87px;
        left: -55px;
        top: -70px;
        transform: rotate(20.39deg);
    }
}

.slogan-title {
    position: relative;
    color: black;
    font-family: "bangers";
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 51px;
    width: 488px;
}

.slogan-translate {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #969696;
}

.slogan-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    margin-top: 31px;
    width: 410px;
    color: black;
}

.features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 269px;
}

.feature {
    margin: 20px 87px;

    div {
        width: 185px;
        margin-top: 25px;
        font-family: Bangers;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 26px;
        text-align: center;
        color: #000000;
    }
}

.times {
    min-height: 604px;
    background: #e5e5e5;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.times_screenshot {
    width: 309px;
    height: 249px;
    margin: 0 83px;
    position: relative;

    :nth-child(1) {
        position: absolute;
        transform: rotate(-4deg);
        max-width: inherit;
        width: 340px;
        // top: 30px;
        // left: 33px;
        // clip-path: inset(0 36% 0 0);
        top: 32px;
        left: 3px;
        clip-path: inset(0 27% 0 8%);
    }
    :nth-child(2) {
        position: relative;
    }
}

.times-content {
    width: 380px;
    position: relative;
    margin: 170px 83px 20px 83px;
}

.times-content .logo {
    position: absolute;
    width: 267px;
    height: 216px;
    left: -143px;
    top: -170px;
}

.times-content .section-content {
    margin-bottom: 28px;
}

.times-icons {
    display: flex;

    > img {
        width: 50px;
        height: 50px;
        margin-right: 13px;
    }
}

.report {
    height: 604px;
    background: white;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;

    > img {
        width: 500px;
        margin: 0 62px;
    }
}

.report-content {
    width: 319px;
    margin: 0 62px;
}

.demo {
    min-height: 600px;
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;

    video {
        margin-top: 20px;
    }
}

.demo-content {
    text-align: center;
    margin: 0 83px;
}

.demo-action {
    text-align: center;
    margin-top: 20px;
}

.donnate {
    min-height: 427px;
    background: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    > img {
        width: 358px;
        margin: 0 83px;
    }
}

.donnate-action {
    text-align: center;
    margin-top: 20px;
}

.contact {
    min-height: 494px;
    background: #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact_section {
    position: relative;
    width: 672px;
}

.contact_email {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
}

.contact_gitlab {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: center;
}

.button.is-primary.is-outlined.contact_button {
    color: #485460;
    border-color: #485460;

    img {
        margin-right: 12px;
    }

    &:hover {
        background-color: #48546023;
    }

    &:focus {
        background-color: transparent;
    }
}

.site_footer {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        margin: 0 10px;
    }
}
</style>
