<script>
import Component from "vue-class-component";
import Vue from "vue";
import { replaceEmoji } from "../../base/Utils";

const Props = Vue.extend({
    props: {
        value: String,
        type: String,
        inputType: String,
        disabled: Boolean,
    },
});

@Component
export default class EditableInput extends Props {
    editable = false;
    newValue = "";

    created() {
        this.newValue = this.value;
        this.$watch("value", () => {
            this.newValue = this.value;
        });
        this.end_bound = (e) => this.end(e);
    }

    start() {
        if (this.disabled) {
            return;
        }

        this.editable = true;
        document.addEventListener("mousedown", this.end_bound, true);
    }

    updated() {
        if (this.$refs.input) {
            this.$refs.input.focus();
        }
    }

    end(e) {
        if (this.$el === e.target || this.$el.contains(e.target)) {
            return;
        }

        this.validate();
    }

    validate(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const isValid = !this.$refs.input || this.$refs.input.checkHtml5Validity();
        if (!isValid) {
            setTimeout(() => {
                this.$refs.input.focus();
            }, 0);
            return;
        }

        this.exit();

        const newValue = this.$refs.input.newValue;

        if (newValue === "") {
            this.$refs.input.newValue = this.newValue;
            return;
        }

        if (newValue === this.newValue) {
            return;
        }

        this.newValue = newValue;
        this.$emit("change", newValue, this);
    }

    cancel() {
        this.newValue = this.value;
        this.exit();
    }

    exit() {
        this.editable = false;
        document.removeEventListener("mousedown", this.end_bound, true);
    }

    render() {
        if (!this.editable || this.disabled) {
            return (
                <div
                    class={{
                        EditableInput: true,
                        [this.type]: true,
                        disabled: this.disabled,
                    }}
                >
                    <span class="text" onclick={this.start}>
                        {replaceEmoji(this.newValue)}
                    </span>
                </div>
            );
        }

        return (
            <div class={{ EditableInput: true, [this.type]: true }}>
                <form onsubmit={this.validate}>
                    <b-input ref="input" value={this.newValue} type={this.inputType} required />
                </form>
                <div class="actions">
                    <b-button
                        type="is-danger"
                        size="is-small"
                        icon-right="times"
                        onclick={this.cancel}
                    />
                    <b-button
                        type="is-primary ml-1"
                        size="is-small"
                        icon-right="check"
                        onclick={this.validate}
                    />
                </div>
            </div>
        );
    }
}
</script>

<style lang="scss">
.EditableInput {
    margin: 0;
    position: relative;

    &.title {
        min-width: 395px;

        .text {
            color: #227093;
            font-weight: 300;
            font-size: 28px;
            line-height: 38px;

            padding-bottom: calc(0.3em - 1px);
            padding-left: calc(0.3em - 1px);
            padding-right: calc(0.3em - 1px);
            padding-top: calc(0.3em - 1px);

            height: 2.25rem;
        }

        input {
            font-family: "Open sans";
            color: #227093;
            font-weight: 300;
            font-size: 28px;

            padding-bottom: calc(0.3em - 1px);
            padding-left: calc(0.3em - 1px);
            padding-right: calc(0.3em - 1px);
            padding-top: calc(0.3em - 1px);
        }
    }

    &.row {
        .text {
            padding-bottom: calc(0.3em - 1px);
            padding-left: calc(0.3em - 1px);
            padding-right: calc(0.3em - 1px);
            padding-top: calc(0.3em - 1px);

            height: 23px;
        }

        input {
            width: 90%;
            height: 23px;
            font-family: "Open sans";
            color: #363636;
            font-size: 1em;

            padding-bottom: calc(0.3em - 1px);
            padding-left: calc(0.3em - 1px);
            padding-right: calc(0.3em - 1px);
            padding-top: calc(0.3em - 1px);
        }

        .actions {
            left: calc(90% - 64px);
        }
    }

    &.avatar {
        display: flex;
        justify-content: center;

        min-width: 200px;
        text-align: center;

        .text {
            height: 32px;
            padding-bottom: calc(0.3em - 1px);
            padding-left: calc(0.3em - 1px);
            padding-right: calc(0.3em - 1px);
            padding-top: calc(0.3em - 1px);
        }

        input {
            height: 32px;
            font-family: "Open sans";
            color: #363636;
            font-size: 1em;
            text-align: center;

            padding-bottom: calc(0.3em - 1px);
            padding-left: calc(0.3em - 1px);
            padding-right: calc(0.3em - 1px);
            padding-top: calc(0.3em - 1px);
        }
    }

    &:hover:not(.disabled) span.text {
        background-color: rgb(235, 236, 240);
        cursor: text;
    }

    .actions {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 2;
    }
}
</style>
