<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../base/Injection";
import { ReportService } from "../services/ReportService";
import RequestItem from "../components/report/RequestItem.vue";
import PaginatedRows from "../components/common/PaginatedRows.vue";

@Component
export default class RequestsPage extends Vue {
    @inject(ReportService) reportService;

    mounted() {
        this.reportService.on("change", this.$refs.rows.loadData);
    }

    beforeDestroy() {
        this.reportService.off("change", this.$refs.rows.loadData);
    }

    async getRequests(selector) {
        const requests = await this.reportService.getRequests(selector);
        return requests;
    }

    openValidationSheet(reportId) {
        this.$router.push(`/requests/${reportId}`);
    }

    render() {
        return (
            <div class="page-content">
                <div class="page-title">{this.$t("RequestsPage.title")}</div>

                <div class="page-full page-full--padded">
                    <PaginatedRows
                        ref="rows"
                        fetchData={this.getRequests}
                        pageSize={10}
                        orderBy={{
                            name_asc: this.$t("RequestsPage.name_asc"),
                            name_desc: this.$t("RequestsPage.name_desc"),
                        }}
                        scopedSlots={{
                            default: (r) => (
                                <RequestItem
                                    clickCb={(rId) => this.openValidationSheet(rId)}
                                    request={r}
                                    key={`request-${r.report.id}`}
                                />
                            ),
                        }}
                    />
                </div>
            </div>
        );
    }
}
</script>
