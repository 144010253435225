import { Service } from "../base/Service";
import { inject } from "../base/Injection";
import { Fetcher } from "../base/Fetcher";

export class TeamService extends Service {
    @inject(Fetcher) fetcher;

    async saveTeam({ id, name }) {
        await this.fetcher.post("/team", { id, name });

        if (!id) {
            this.emit("change");
        }
    }

    removeTeam(id) {
        return this.fetcher.delete(`/team/${id}`);
    }

    getTeams(selector) {
        return this.fetcher.get("/teams", selector);
    }

    getTeam(id) {
        return this.fetcher.get(`/team/${id}`);
    }

    getMembers(id, selector) {
        return this.fetcher.get(`/team/${id}/members`, selector);
    }

    searchMembers(name) {
        return this.fetcher.get("/users/search", { q: name });
    }

    searchTeams(name) {
        return this.fetcher.get("/teams/search", { q: name });
    }

    async saveMember(teamId, userId, admin) {
        await this.fetcher.post(`/team/member`, {
            teamId,
            userId,
            admin,
        });
        this.emit("change");
    }

    async saveAdmin(teamId, userId, admin) {
        await this.fetcher.post(`/team/member`, {
            teamId,
            userId,
            admin,
        });
    }

    async deleteMember(teamId, userId) {
        await this.fetcher.delete(`/team/member`, {
            teamId,
            userId,
        });
        this.emit("change");
    }

    getRepositories(teamId, selector) {
        return this.fetcher.get(`/repos/${teamId}`, selector);
    }

    async addRepository(teamId, { category, name, host }) {
        await this.fetcher.post(`/repo/${teamId}`, { category, name, host });
        this.emit("change");
    }

    async removeRepository(id) {
        await this.fetcher.delete(`/repo/${id}`);
        this.emit("change");
    }
}
