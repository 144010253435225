<script>
import Component from "vue-class-component";
import Vue from "vue";
import { format } from "../../base/Utils";
import { ReportService, PINNING } from "../../services/ReportService";
import { inject } from "../../base/Injection";
import ShareReportModal from "./ShareReportModal.vue";
import { COLLABORATOR_TYPE } from "../../services/ProjectService";
import ProfileIcon from "../common/ProfileIcon.vue";

const Props = Vue.extend({
    props: {
        report: Object,
        clickCb: Function,
    },
});

@Component
export default class ReportItem extends Props {
    @inject(ReportService) reportService;

    editReport() {
        this.$router.push(`/reports/edit/${this.report.id}`);
    }

    togglePinning() {
        const pinning = this.report.pinning === PINNING.PINNED ? PINNING.UNPINNED : PINNING.PINNED;
        this.reportService.updatePinning(this.report.id, pinning);
    }

    async removeReport() {
        this.$buefy.dialog.confirm({
            title: this.$t("ReportItem.confirm.title"),
            message: this.$t("ReportItem.confirm.message"),
            confirmText: this.$t("ReportItem.confirm.confirmText"),
            type: "is-danger",
            hasIcon: true,
            onConfirm: async () => {
                try {
                    await this.reportService.removeReport(this.report.id);

                    this.$buefy.toast.open({
                        message: this.$t("ReportItem.confirm.success"),
                        type: "is-success",
                    });
                } catch {
                    this.$buefy.toast.open({
                        message: this.$t("error.server"),
                        type: "is-danger",
                    });
                }
            },
        });
    }

    share() {
        this.$buefy.modal.open({
            parent: this,
            component: ShareReportModal,
            hasModalCard: true,
            customClass: "custom-class custom-class-2",
            trapFocus: true,
            props: { report: this.report },
        });
    }

    renderProjectNames() {
        return this.report.projects?.reduce((items, project, index) => {
            if (index < 3) {
                return [...items, <span class={`tag color-${project.color}`}>{project.name}</span>];
            }
            if (index === 3) {
                return [...items, <span class="tag">+ {this.report.projects.length - 3}</span>];
            }
            return items;
        }, []);
    }

    renderProjectTags() {
        return this.report.tags?.reduce((items, tag, index) => {
            if (index < 3) {
                return [...items, <span class="tag">{tag}</span>];
            }
            if (index === 3) {
                return [...items, <span class="tag">+ {this.report.tags.length - 3}</span>];
            }
            return items;
        }, []);
    }

    render() {
        return (
            <div
                class="card card--report"
                onclick={(e) =>
                    !e.target.classList.contains("avoid-click") && this.clickCb(this.report.id)
                }
            >
                <header class="card-header">
                    <div class="card-header-title">
                        <p class="tags">{this.renderProjectNames()}</p>
                        <p class="tags">{this.renderProjectTags()}</p>
                    </div>
                    <div class="report-item-dropdown-container">
                        {this.report.pinning === PINNING.PINNED && (
                            <i class="fa fa-thumb-tack" aria-hidden="true" />
                        )}
                        {this.report.shared && <i class="fa fa-share-alt" aria-hidden="true" />}

                        {!this.report.shared && (
                            <b-dropdown
                                aria-role="list"
                                aria-label="more options"
                                class="report-item-dropdown"
                            >
                                <a slot="trigger" role="button">
                                    <b-icon
                                        custom-class="avoid-click"
                                        icon="ellipsis-h"
                                        aria-hidden="true"
                                    ></b-icon>
                                </a>

                                <b-dropdown-item
                                    class="avoid-click"
                                    aria-role="listitem"
                                    onclick={this.editReport}
                                >
                                    {this.$t("ReportItem.edit-report")}
                                </b-dropdown-item>

                                <b-dropdown-item
                                    class="avoid-click"
                                    aria-role="listitem"
                                    onclick={this.togglePinning}
                                >
                                    {this.report.pinning === PINNING.PINNED
                                        ? this.$t("ReportItem.unpin")
                                        : this.$t("ReportItem.pin")}
                                </b-dropdown-item>

                                <b-dropdown-item
                                    class="avoid-click"
                                    aria-role="listitem"
                                    onclick={this.share}
                                >
                                    {this.$t("ReportItem.share")}
                                </b-dropdown-item>

                                <b-dropdown-item
                                    class="avoid-click"
                                    aria-role="listitem"
                                    onclick={this.removeReport}
                                >
                                    {this.$t("ReportItem.remove-report")}
                                </b-dropdown-item>
                            </b-dropdown>
                        )}
                    </div>
                </header>
                <div class="card-content">
                    <p class="title is-4">{this.report.name}</p>
                    <div class="content">
                        {this.report.description || this.$t("ReportItem.noDescription")}
                    </div>
                </div>
                <footer class="card-footer card-footer--report">
                    <div
                        class={{
                            "report-date": true,
                            "is-hidden":
                                !this.report.startDate ||
                                !this.report.endDate ||
                                this.report.frequency !== "custom",
                        }}
                    >
                        {format(new Date(this.report.startDate), "dd MMM")}
                        <span> - </span>
                        {format(new Date(this.report.endDate), "dd MMM")}
                    </div>
                    <div class="team-icon">
                        <span class="icon">
                            <i
                                class={{
                                    fa: true,
                                    "fa-sync": this.report.synchronizable,
                                    "fa-users": this.report.collaborators.length,
                                    "fa-user": !this.report.collaborators.length,
                                }}
                                aria-hidden="true"
                            ></i>
                        </span>
                    </div>
                    {this.report.shared && (
                        <b-tooltip
                            label={`${this.$t("ReportItem.shared-by")} ${this.report.owner.name}`}
                            delay={250}
                        >
                            <ProfileIcon
                                avatar={this.report.owner.avatar}
                                type={COLLABORATOR_TYPE.USER}
                                height={24}
                                class="reportItem-profileIcon"
                            />
                        </b-tooltip>
                    )}
                </footer>
            </div>
        );
    }
}
</script>
<style lang="scss" scoped>
@import "../../../css/variables.scss";

$internal-card-padding: 1rem;

.card--report {
    width: $card-width;
    height: $card-height;
    border-radius: 10px;
    margin: 1rem;
    cursor: pointer;

    flex-shrink: 0;
    flex-grow: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: visible;

    .card-content {
        cursor: pointer;

        .title {
            margin-bottom: $internal-card-padding;
        }
    }

    .card-header-title {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: $internal-card-padding;

        .tags {
            margin-bottom: 0;
        }
    }
}

.card-footer--report {
    padding: $card-footer-padding;
    padding-top: $internal-card-padding;
    justify-content: flex-end;
}

.report-date {
    flex-grow: 1;
}

.title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.team-icon {
    display: inline-flex;
}

.report-item-dropdown {
    height: 20px;
}

.report-item-dropdown-container {
    padding: 1.5rem;
    padding-bottom: $internal-card-padding;
}
.reportItem-profileIcon {
    .profileIcon-icon {
        border: solid 1px rgba(0, 0, 0, 0.4);
    }
}
</style>
