<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { ProjectService } from "../../services/ProjectService";
import EditableInput from "../common/EditableInput.vue";
import { TimelineService } from "../../services/TimelineService";
import { isEquals } from "../../base/Utils";
import EditableTags from "../common/EditableTags.vue";
import { format } from "date-fns/esm";
import PaginatedTable from "../common/PaginatedTable.vue";
import MoveTaskModal from "./MoveTaskModal.vue";

const Props = Vue.extend({
    props: {
        project: Object,
    },
});

@Component
export default class TasksProject extends Props {
    @inject(ProjectService) projectService;
    @inject(TimelineService) timelineService;

    mounted() {
        this.projectService.on("change", this.$refs.table.loadData);
    }

    beforeDestroy() {
        this.projectService.off("change", this.$refs.table.loadData);
    }

    async getTasks(selector) {
        const id = this.$route.params.id;
        const tasks = await this.projectService.getTasks(id, selector);
        return tasks;
    }

    async addTask() {
        if (this.$refs.title.newValue) {
            try {
                await this.projectService.saveTask(this.project.id, {
                    title: this.$refs.title.newValue,
                    tags: this.$refs.tags.tags,
                });
                this.$refs.title.newValue = "";
                this.$refs.tags.tags = [];
            } catch {
                this.$buefy.toast.open({
                    message: this.$t("error.server"),
                    type: "is-danger",
                });
            }
        }
    }

    async save(task, newTitle, newTags, target) {
        const isOverwrite = this.$refs.table
            .getData()
            .some(
                (t) =>
                    t.title === newTitle &&
                    isEquals(t.tags, newTags) &&
                    t.isDefault === task.isDefault
            );

        const callback = async () => {
            try {
                await this.projectService.renameTask(this.project.id, task, newTitle, newTags);
                await this.timelineService.cleanCache();
            } catch {
                this.$buefy.toast.open({
                    message: this.$t("error.server"),
                    type: "is-danger",
                });
                target.cancel();
            }
        };

        if (isOverwrite) {
            this.$buefy.dialog.confirm({
                title: this.$t("TasksProject.overwrite.title"),
                message: this.$t("TasksProject.overwrite.message"),
                confirmText: this.$t("TasksProject.overwrite.confirmText"),
                type: "is-danger",
                hasIcon: true,
                onConfirm: callback,
                onCancel: () => {
                    target.cancel();
                },
            });
        } else {
            callback();
        }
    }

    async remove(taskId) {
        this.$buefy.dialog.confirm({
            title: this.$t("TasksProject.remove.title"),
            message: this.$t("TasksProject.remove.message"),
            confirmText: this.$t("TasksProject.remove.confirmText"),
            type: "is-danger",
            hasIcon: true,
            onConfirm: async () => {
                try {
                    await this.projectService.removeTask(taskId);
                    await this.timelineService.cleanCache();
                    this.$buefy.toast.open({
                        message: this.$t("TasksProject.remove.success"),
                        type: "is-success",
                    });
                } catch {
                    this.$buefy.toast.open({
                        message: this.$t("error.server"),
                        type: "is-danger",
                    });
                }
            },
        });
    }

    async move(task) {
        this.$buefy.modal.open({
            parent: this,
            component: MoveTaskModal,
            hasModalCard: true,
            customClass: "custom-class custom-class-2",
            trapFocus: true,
            props: { task },
        });
    }

    render() {
        if (!this.project) {
            return;
        }

        const isMaintainer = this.project.permission === "maintainer";

        return (
            <div class="page-table">
                <div class={`field-inline${isMaintainer ? "" : " is-hidden"}`}>
                    <div class="fixed">
                        <b-input
                            ref="title"
                            ellipsis
                            icon="tasks"
                            placeholder={this.$t("TasksProject.input-title")}
                            required
                        ></b-input>
                    </div>
                    <div class="fixed">
                        <b-taginput
                            rounded
                            ref="tags"
                            ellipsis
                            icon="tag"
                            placeholder={this.$t("TasksProject.input-tags")}
                        ></b-taginput>
                    </div>
                    <button class="button is-primary is-rounded is-outlined" onclick={this.addTask}>
                        {this.$t("TasksProject.submit")}
                    </button>
                </div>
                <PaginatedTable ref="table" fetchData={this.getTasks} defaultSort="title_asc">
                    <b-table-column
                        label={this.$t("TasksProject.tasks")}
                        cellClass="fix-third"
                        field="title"
                        sortable
                        scopedSlots={{
                            default: ({ row: task }) => {
                                return (
                                    <EditableInput
                                        type="row"
                                        value={task.title}
                                        disabled={!isMaintainer}
                                        onChange={(newValue, target) =>
                                            this.save(task, newValue, task.tags, target)
                                        }
                                    />
                                );
                            },
                        }}
                    />

                    <b-table-column
                        label={this.$t("TasksProject.tags")}
                        cellClass="fix-third"
                        scopedSlots={{
                            default: ({ row: task }) => {
                                return (
                                    <EditableTags
                                        value={task.tags}
                                        disabled={!isMaintainer}
                                        onChange={(newValue, target) =>
                                            this.save(task, task.title, newValue, target)
                                        }
                                    />
                                );
                            },
                        }}
                    />

                    <b-table-column
                        label={this.$t("TasksProject.count")}
                        centered
                        scopedSlots={{
                            default: ({ row: task }) => {
                                return task.count;
                            },
                        }}
                    />

                    <b-table-column
                        label={this.$t("TasksProject.lastDate")}
                        centered
                        scopedSlots={{
                            default: ({ row: task }) => {
                                return task.isDefault
                                    ? ""
                                    : format(new Date(task.lastDate), "EEE dd MMMM yyyy");
                            },
                        }}
                    />

                    <b-table-column
                        label={this.$t("TasksProject.isDefault")}
                        scopedSlots={{
                            default: ({ row: task }) => {
                                return <b-switch value={task.isDefault} disabled />;
                            },
                        }}
                    />

                    <b-table-column
                        label={this.$t("TagsProject.action")}
                        numeric
                        visible={isMaintainer}
                        scopedSlots={{
                            default: ({ row: task }) => (
                                <div>
                                    <b-button
                                        class={{ "is-hidden": !task.isDefault }}
                                        type="is-danger is-small"
                                        icon-right="trash"
                                        onclick={() => this.remove(task.firstId)}
                                    />
                                    <b-button
                                        class={{ "is-hidden": task.isDefault }}
                                        type="is-danger is-small"
                                        icon-right="level-up"
                                        onclick={() => this.move(task)}
                                    />
                                </div>
                            ),
                        }}
                    />
                </PaginatedTable>
            </div>
        );
    }
}
</script>
