<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { TeamService } from "../../services/TeamService";

@Component
export default class TeamSelection extends Vue {
    @inject(TeamService) teamService;

    searchTeams = [];

    async onInputTeams(name) {
        this.searchTeams = (await this.teamService.searchTeams(name)) || [];
    }

    getSelectedTeamId() {
        return this.$refs.teams.selected && this.$refs.teams.selected.id;
    }

    clearSelectedTeam() {
        this.$refs.teams.newValue = "";
    }

    render() {
        return (
            <b-autocomplete
                ref="teams"
                icon="users"
                placeholder={this.$t("TeamSelection.placeholder")}
                keep-first
                data={this.searchTeams}
                oninput={this.onInputTeams}
                field="name"
            >
                <template slot="empty">{this.$t("TeamSelection.empty")}</template>
            </b-autocomplete>
        );
    }
}
</script>
