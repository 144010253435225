<script>
import Component from "vue-class-component";
import Vue from "vue";
import EmptyTable from "./EmptyTable.vue";

const Props = Vue.extend({
    props: {
        fetchData: Function,
        pageSize: {
            type: Number,
            default: () => 15,
        },
        defaultSort: String,
    },
});

@Component
export default class PaginatedTable extends Props {
    data = [];
    pageCount = 0;
    pageNumber = 1;
    sort = "";
    search = "";

    created() {
        this.pageNumber = parseInt(this.$route.query.page) || 1;
        this.sort = this.$route.query.sort || this.defaultSort || "";
        this.search = this.$route.query.search || "";

        this.loadData();
    }

    onPageChange(pageNumber) {
        this.pageNumber = pageNumber;

        const query = { ...this.$route.query, page: this.pageNumber };
        this.$router.replace({ query });

        this.loadData();
    }

    onSort(field, dir) {
        this.sort = `${field}_${dir}`;

        const query = { ...this.$route.query, sort: this.sort };
        this.$router.replace({ query });

        this.loadData();
    }

    onSearch(e) {
        this.pageNumber = 1;
        this.search = this.$refs.search.newValue;

        const query = { ...this.$route.query, page: this.pageNumber, search: this.search };
        this.$router.replace({ query });

        this.loadData();

        e.preventDefault();
        e.stopPropagation();
    }

    clearSearch() {
        this.pageNumber = 1;
        this.search = "";
        this.$refs.search.newValue = "";

        const query = { ...this.$route.query, page: this.pageNumber, search: this.search };
        this.$router.replace({ query });

        this.loadData();
    }

    async loadData() {
        try {
            // this.data = [];
            this.fetching = true;

            const result = await this.fetchData({
                limit: this.pageSize,
                offset: (this.pageNumber - 1) * this.pageSize,
                order: this.sort,
                search: this.search,
            });

            this.pageCount = result.count;
            this.data = result.items || [];
        } catch (e) {
            console.error(e);
            this.data = [];
            this.pageCount = 0;
        } finally {
            this.fetching = false;
        }
    }

    getData() {
        return this.data;
    }

    render() {
        return (
            <div>
                <form onsubmit={this.onSearch}>
                    <b-input
                        ref="search"
                        class="search"
                        ellipsis
                        icon="search"
                        value={this.search}
                        placeholder={this.$t("PaginatedTable.search")}
                        icon-right="close-circle"
                        icon-right-clickable
                        onicon-right-click={this.clearSearch}
                    ></b-input>
                </form>
                <b-table
                    hoverable
                    data={this.data}
                    loading={this.fetching}
                    paginated
                    backend-pagination
                    current-page={this.pageNumber}
                    total={this.pageCount}
                    per-page={this.pageSize}
                    onPage-change={this.onPageChange}
                    sort-icon="chevron-up"
                    sort-icon-size="is-small"
                    backend-sorting
                    default-sort={this.sort && this.sort.split("_")[0]}
                    default-sort-direction={this.sort && this.sort.split("_")[1]}
                    onSort={this.onSort}
                    onDblclick={(item) => this.$emit("open", item)}
                >
                    <EmptyTable
                        text={this.$t("PaginatedTable.empty")}
                        visible={!this.fetching && this.data && !this.data.length}
                    />

                    {this.$slots.default}
                </b-table>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.search {
    width: 200px;
}
</style>
