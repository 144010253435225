<script>
import Component from "vue-class-component";
import Vue from "vue";

import { inject } from "../../base/Injection";

import { TimelineService } from "../../services/TimelineService";
import { getProgressInDay, replaceEmoji } from "../../base/Utils";
import { addMinutes, subMinutes, isSameDay } from "date-fns";

const mappingActivityIcon = {
    gitlab: "gitlab",
    github: "github",
    jira: "wrench",
    redmine: "wrench",
};

@Component
export default class Activities extends Vue {
    @inject(TimelineService) timelineService;

    currentDate = null;
    activities = [];

    created() {
        this.currentDate = this.timelineService.currentDate;
        this.update();
    }

    async update() {
        if (!isSameDay(this.currentDate, this.timelineService.currentDate)) {
            this.activities = [];
            this.currentDate = this.timelineService.currentDate;
        }
        this.activities = (await this.timelineService.getActivities()) || [];
    }

    async createTask(e, activity) {
        const id = await this.timelineService.createTask({
            title: activity.title,
            projectId: activity.projectId,
            startDate: subMinutes(new Date(activity.date), 30),
            endDate: addMinutes(new Date(activity.date), 30),
            tags: activity.tags,
        });
        this.$router.push(`/timeline/edit/${id}`);
    }

    render() {
        const currentDate = this.currentDate;

        return (
            <div>
                {this.activities.map((activity) => {
                    const position = getProgressInDay(new Date(activity.date), currentDate);
                    return (
                        <div
                            class={{
                                doted: true,
                                [`color-${
                                    activity.projectColor || this.timelineService.defaultColor
                                }`]: true,
                            }}
                            style={`left:${position}%;`}
                            ondblclick={(e) => this.createTask(e, activity)}
                        >
                            <div
                                class={`activity-project-content color-${
                                    activity.projectColor || this.timelineService.defaultColor
                                }`}
                            >
                                <div class="activity-project-title">
                                    <span key={`cat-${activity.category}`}>
                                        <i
                                            class={`activity-project-category fa fa-${
                                                mappingActivityIcon[activity.category]
                                            }`}
                                        ></i>
                                    </span>
                                    <strong
                                        class={{
                                            "activity-project-name--disabled":
                                                activity.projectId ===
                                                "00000000-0000-0000-0000-000000000000",
                                        }}
                                    >
                                        {activity.projectName}
                                    </strong>
                                    {activity.title ? `-${replaceEmoji(activity.title)}` : ""}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}
</script>
<style lang="scss" scoped>
@import "../../../css/variables";

.doted {
    position: absolute;
    cursor: pointer;
    bottom: 20px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    font-size: 14px;

    &:hover .activity-project-content {
        display: flex;
    }
}

.activity-project-content {
    display: none;
    position: absolute;
    top: 17px;
    flex-direction: column;
    width: 300px;
    z-index: 1000;

    padding: 10px 15px;
    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(33, 33, 33, 0.7);
}

.activity-project-category {
    margin-right: 5px;
}

.activity-project-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.activity-project-name--disabled {
    font-style: italic;
}
</style>
