<script>
import Component from "vue-class-component";
import Vue from "vue";
import { COLLABORATOR_TYPE } from "../../services/ProjectService";
import { inject } from "../../base/Injection";
import ProfileIcon from "./ProfileIcon.vue";
import { TeamService } from "../../services/TeamService";

const Props = Vue.extend({
    props: {
        collaborator: Object,
        enabled: Boolean,
    },
});

@Component
export default class CollaboratorProfile extends Props {
    @inject(TeamService) teamService;

    opened = false;
    teamUsers = {};

    async init() {}

    async toggleTeam() {
        if (!this.enabled) {
            return;
        }

        this.opened = !this.opened;
        if (this.opened) {
            this.teamUsers = await this.teamService.getMembers(this.collaborator.id);
        } else {
            this.teamUsers = {};
        }
    }

    async selectCollaborator() {
        if (!this.enabled) {
            return;
        }

        this.opened = false;
        this.teamUsers = {};
        this.collaborator.selected = !this.collaborator.selected;

        this.$emit("select", this.collaborator);
    }

    async selectTeamUser(user) {
        if (!this.enabled) {
            return;
        }

        user.selected = !user.selected;
        user.type = COLLABORATOR_TYPE.USER;
        this.teamUsers = { ...this.teamUsers };

        this.$emit("select", user);
    }

    render() {
        let iconClass = `profileIcon`;
        if (!this.enabled) {
            iconClass += " disabled";
            this.collaborator.selected = false;
        } else if (this.collaborator.selected) {
            iconClass += " selected";
        }

        return (
            <div class="collaborator">
                <b-tooltip label={this.collaborator.name} delay={250}>
                    <ProfileIcon
                        name={this.collaborator.name}
                        avatar={this.collaborator.avatar}
                        type={this.collaborator.type}
                        height={48}
                        clickCb={this.selectCollaborator}
                        class={iconClass}
                    />
                    {this.collaborator.type === COLLABORATOR_TYPE.TEAM &&
                        !this.collaborator.selected && (
                            <div class="team-action" onclick={this.toggleTeam}>
                                <i
                                    class={{
                                        fa: true,
                                        "fa-folder-o ": !this.opened,
                                        "fa-folder-open-o ": this.opened,
                                    }}
                                ></i>
                            </div>
                        )}
                </b-tooltip>
                {this.teamUsers?.items?.map((user) => (
                    <b-tooltip label={user.name} delay={250}>
                        <ProfileIcon
                            name={user.name}
                            avatar={user.avatar}
                            type={COLLABORATOR_TYPE.USER}
                            height={32}
                            class={`profileIcon${user.selected ? " selected" : ""}`}
                            clickCb={() => this.selectTeamUser(user)}
                        />
                    </b-tooltip>
                ))}
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
@import "../../../css/variables";
.collaborator {
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-action {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    bottom: -5px;
    right: calc(0.5rem - 5px);
    width: 24px;
    height: 24px;
    border-radius: 12px;
    text-align: center;
    border: $field-border 1px solid;
    background-color: white;
    cursor: pointer;
}
</style>

<style lang="scss">
@import "../../../css/variables";

.collaboratorList {
    display: flex;
    flex-wrap: wrap;

    .profileIcon {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;

        .profileIcon-icon {
            cursor: pointer;
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }
        }

        &.selected {
            .profileIcon-icon {
                opacity: 1;
            }

            &::after {
                font-family: "ForkAwesome";
                content: "\f00c";
                position: absolute;
                top: -5px;
                right: calc(0.5rem - 5px);
                width: 24px;
                height: 24px;
                border-radius: 12px;
                text-align: center;
                border: $field-border 1px solid;

                background-color: white;
                color: $success;
            }
        }

        &.disabled {
            .profileIcon-icon {
                cursor: auto;
                opacity: 0.5;

                &:hover {
                    opacity: 0.5;
                    border: none;
                }
            }
        }
    }
}
</style>
