<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import UserSelection from "../../components/common/UserSelection";
import { TeamService } from "../../services/TeamService";
import { UserService } from "../../services/UserService";
import PaginatedTable from "../common/PaginatedTable.vue";

const Props = Vue.extend({
    props: {
        team: Object,
    },
});

@Component
export default class UsersTeam extends Props {
    @inject(TeamService) teamService;
    @inject(UserService) userService;

    user = {};

    async mounted() {
        this.user = await this.userService.getUser();
        this.teamService.on("change", this.$refs.table.loadData);
    }

    beforeDestroy() {
        this.teamService.off("change", this.$refs.table.loadData);
    }

    async getMembers(selector) {
        const id = this.$route.params.id;
        const members = await this.teamService.getMembers(id, selector);

        if (members.items) {
            members.items = members.items.map((m) => ({
                ...m,
                admin: Boolean((this.team.admin && this.user.id === m.id) || m.admin),
            }));
        }

        return members;
    }

    async saveMember() {
        const userId = this.$refs.user.getSelectedUserId();
        if (userId) {
            try {
                await this.teamService.saveMember(this.team.id, userId, this.$refs.admin.newValue);

                this.$refs.user.clearSelectedUser();
            } catch {
                this.$buefy.toast.open({
                    message: this.$t("error.server"),
                    type: "is-danger",
                });
            }
        }
    }

    async saveAdmin(userId, admin) {
        try {
            await this.teamService.saveAdmin(this.team.id, userId, admin);
        } catch {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
        }
    }

    async deleteMember(userId) {
        this.$buefy.dialog.confirm({
            title: this.$t("UsersTeam.confirm.title"),
            message: this.$t("UsersTeam.confirm.message"),
            confirmText: this.$t("UsersTeam.confirm.confirmText"),
            type: "is-danger",
            hasIcon: true,
            onConfirm: async () => {
                try {
                    await this.teamService.deleteMember(this.team.id, userId);
                    this.$buefy.toast.open({
                        message: this.$t("UsersTeam.confirm.success"),
                        type: "is-success",
                    });
                } catch {
                    this.$buefy.toast.open({
                        message: this.$t("error.server"),
                        type: "is-danger",
                    });
                }
            },
        });
    }

    render() {
        return (
            <div class="field-container">
                <div class={`field-inline${this.team.admin ? "" : " is-hidden"}`}>
                    <div class="fixed">
                        <UserSelection ref="user" />
                    </div>
                    <div>
                        <b-switch ref="admin" value={false}>
                            {this.$t("UsersTeam.input-admin")}
                        </b-switch>
                    </div>
                    <button
                        class="button is-primary is-rounded is-outlined"
                        onclick={this.saveMember}
                    >
                        {this.$t("UsersTeam.submit")}
                    </button>
                </div>

                <PaginatedTable ref="table" fetchData={this.getMembers} defaultSort="name_asc">
                    <b-table-column
                        label={this.$t("UsersTeam.name")}
                        field="name"
                        sortable
                        scopedSlots={{
                            default: ({ row: member }) => (
                                <span class="avatar">
                                    <figure class="image is-32x32">
                                        <img class="is-rounded" src={member.avatar} />
                                    </figure>
                                    {member.name}
                                    <span
                                        class={`is-tag ml205${
                                            this.user.id === member.id ? "" : " is-hidden"
                                        }`}
                                    >
                                        {this.user.id === member.id ? this.$t("UsersTeam.you") : ""}
                                    </span>
                                </span>
                            ),
                        }}
                    />

                    <b-table-column
                        label={this.$t("UsersTeam.admin")}
                        field="admin"
                        centered
                        sortable
                        scopedSlots={{
                            default: ({ row: member }) => (
                                <b-switch
                                    value={member.admin}
                                    disabled={!this.team.admin || this.user.id === member.id}
                                    oninput={(value) => this.saveAdmin(member.id, value)}
                                />
                            ),
                        }}
                    />

                    <b-table-column
                        label={this.$t("UsersTeam.action")}
                        centered
                        scopedSlots={{
                            default: ({ row: member }) => (
                                <b-button
                                    class={`${this.team.admin ? "" : " is-hidden"}`}
                                    disabled={this.user.id === member.id}
                                    type="is-danger is-small"
                                    icon-right="trash"
                                    onclick={() => this.deleteMember(member.id)}
                                />
                            ),
                        }}
                    />
                </PaginatedTable>
            </div>
        );
    }
}
</script>
