import { Service } from "../base/Service";

export const TIMER_ACTION = {
    NEW: "idle-new",
    STOP: "idle-stop",
};

export class IdleService extends Service {
    constructor(context) {
        super(context);

        this.threshold = 5 * 60; // seconds

        navigator.serviceWorker.addEventListener("message", (event) => {
            this.emit(event.data.action, new Date(event.data.date));
        });
    }

    setThreshold(threshold) {
        this.threshold = threshold;
    }

    isSupported() {
        return Boolean(window.IdleDetector);
    }

    async run() {
        if (!this.isSupported()) {
            console.log(`Idle service not supported.`);
            return;
        }

        this.controller = new AbortController();
        const signal = this.controller.signal;

        try {
            const idleDetector = new window.IdleDetector({ threshold: this.threshold, signal });
            idleDetector.addEventListener("change", () => {
                const screen = idleDetector.screenState;
                const user = idleDetector.userState;
                console.log(`[${new Date().toLocaleString()}] idle change: ${user}, ${screen}`);

                if (user === "idle") {
                    this.notify();
                }
            });
            await idleDetector.start({
                threshold: this.threshold,
                signal,
            });
        } catch (e) {
            console.log(`Initialization error: ${e}.`);
        }
    }

    async stop() {
        this.controller && this.controller.abort();
    }

    async notify() {
        if (!window.Notification) {
            return;
        }

        if (Notification.permission !== "denied") {
            const permission = await Notification.requestPermission();
            if (permission !== "granted") {
                return;
            }
        }

        const title = "Idle detected";
        const options = {
            body: `Wid have detected that you're idle. What do you want to do with the current task ?`,
            actions: [
                {
                    action: TIMER_ACTION.NEW,
                    title: "New",
                },
                {
                    action: TIMER_ACTION.STOP,
                    title: "Stop",
                },
            ],
            data: Date.now() - this.threshold * 1000,
            tag: "idle",
        };

        const registration = await navigator.serviceWorker.ready;
        const notifications = await registration.getNotifications({ tag: "idle" });
        if (!notifications.length) {
            registration.showNotification(title, options);
        }
    }
}
