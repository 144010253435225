<script>
import Component from "vue-class-component";
import Vue from "vue";
import { formatDuration, timeStringToSeconds } from "../../base/Utils";
import { DEFAULT_DAY_DURATION, REPORT_FREQUENCY } from "../../services/ReportService";
import InlineInput from "../common/InlineInput.vue";
import { differenceInBusinessDays } from "date-fns";

const Props = Vue.extend({
    props: {
        tasks: Array,
        disabled: Boolean,
        startDate: Date,
        endDate: Date,
        frequency: String,
        hoursPerDay: String,
    },
});

@Component
export default class ValidationSheetGauge extends Props {
    totalHours = 0;
    totalHoursString = "";

    mounted() {
        this.$watch("startDate", (startDate) => {
            this.startDate = startDate;
            this.totalHours = this.getTotalHoursCount();
            this.totalHoursString = formatDuration(this.totalHours, false);
        });
        this.$watch("endDate", (endDate) => {
            this.endDate = endDate;
            this.totalHours = this.getTotalHoursCount();
            this.totalHoursString = formatDuration(this.totalHours, false);
        });
        this.$watch("hoursPerDay", () => {
            this.totalHours = this.getTotalHoursCount();
            this.totalHoursString = formatDuration(this.totalHours, false);
        });

        this.totalHours = this.getTotalHoursCount();
        this.totalHoursString = formatDuration(this.totalHours, false);

        this.adaptTasksWidth();
    }

    updated() {
        this.adaptTasksWidth();
    }

    adaptTasksWidth() {
        const container = this.$refs.container;

        Array.from(container.children).forEach((child, index) => {
            const ratio = this.tasks[index].duration / this.totalHours;
            child.style.width = `${ratio * 100}%`;
        });
    }

    updateTotalHours(input) {
        this.totalHoursString = input;
        if (input) {
            const total = timeStringToSeconds(this.totalHoursString);
            if (total) {
                this.totalHours = total;
                this.totalHoursString = formatDuration(total, false);
                this.$refs.count.classList.toggle("exceed", this.getHoursDone() > this.totalHours);
                return true;
            }
        }
        return false;
    }

    getHoursDone() {
        return this.tasks?.reduce((acc, task) => {
            return acc + task.duration;
        }, 0);
    }

    getTotalHoursCount() {
        if (this.frequency === REPORT_FREQUENCY.DAY) {
            return this.hoursPerDay ? timeStringToSeconds(this.hoursPerDay) : DEFAULT_DAY_DURATION;
        }

        const businessDaysCount = differenceInBusinessDays(this.endDate, this.startDate);
        return (
            businessDaysCount *
            (this.hoursPerDay ? timeStringToSeconds(this.hoursPerDay) : DEFAULT_DAY_DURATION)
        );
    }

    renderTasks() {
        return this.tasks?.map((task) => {
            return (
                <div key={task.id} class={`gauge-task color-${task.projectColor}`}>
                    <div class="gauge-taskTitle">{task.title}</div>
                    <div class="gauge-taskDuration">{formatDuration(task.duration, false)}</div>
                </div>
            );
        });
    }

    render() {
        return (
            <div class="gauge  mb-3 mt-4">
                <div class="gauge-container" ref="container">
                    {this.renderTasks()}
                </div>
                <div
                    class={`gauge-hoursCount ml-3 ${
                        this.getHoursDone() > this.totalHours ? "exceed" : ""
                    }`}
                    ref="count"
                >
                    <div class="gauge-hoursCountContainer">
                        <div class="gauge-hoursDone">
                            {formatDuration(this.getHoursDone(), false)}
                        </div>
                        <span class="ml-1 mr-1">/</span>
                        <InlineInput
                            message={this.$t("ValidationSheetGauge.message")}
                            value={this.totalHoursString}
                            updateCb={this.updateTotalHours}
                            disabled={this.disabled}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
@import "../../../css/variables";

$gauge-task-pading-left: 8px;

.gauge {
    display: flex;
    justify-content: space-between;
    height: $validationSheet-gauge-height;
}

.gauge-container {
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    width: calc(100% - 200px);
}

.gauge-task {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    font-size: 14px;
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.gauge-taskTitle {
    font-weight: 700;
}

.gauge-taskDuration,
.gauge-taskTitle {
    position: relative;
    left: $gauge-task-pading-left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.gauge-hoursCount {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: min-content;
    flex-direction: column;
    justify-content: center;
    color: $primary;
    width: 200px;

    &.exceed {
        color: #b71540;

        .gauge-hoursDone {
            color: #b71540;
        }
    }
}

.gauge-hoursCountContainer {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.gauge-hoursDone {
    font-weight: 700;
    color: #808e9b;
}

.inlineInput-message {
    position: absolute;
    top: -($validationSheet-gauge-height/2);
    width: 100%;
    padding-right: 2.5rem;
}
</style>
