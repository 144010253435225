<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { UserService } from "../../services/UserService";
import ConnectModal from "../../pages/ConnectModal.vue";
import PaginatedTable from "../common/PaginatedTable.vue";

@Component
export default class Repositories extends Vue {
    @inject(UserService) userService;

    mounted() {
        this.userService.on("change", this.$refs.table.loadData);
    }

    beforeDestroy() {
        this.userService.off("change", this.$refs.table.loadData);
    }

    async getRepositories(selector) {
        const repositories = await this.userService.getRepositories(selector);
        if (!repositories.items) {
            return repositories;
        }
        repositories.items = repositories.items.map((r) => ({
            ...r,
            credential: Boolean(r.credential),
        }));
        return repositories;
    }

    connectRepo(repo) {
        this.$buefy.modal.open({
            parent: this,
            component: ConnectModal,
            hasModalCard: true,
            customClass: "custom-class custom-class-2",
            trapFocus: true,
            props: { repo },
        });
    }

    removeRepo(repo) {
        this.$buefy.dialog.confirm({
            title: this.$t("Repositories.confirm.title"),
            message: this.$t("Repositories.confirm.message"),
            confirmText: this.$t("Repositories.confirm.confirmText"),
            type: "is-danger",
            hasIcon: true,
            onConfirm: async () => {
                try {
                    await this.userService.removeAuthRepo(repo);
                    this.$buefy.toast.open({
                        message: this.$t("Repositories.confirm.success"),
                        type: "is-success",
                    });
                } catch {
                    this.$buefy.toast.open({
                        message: this.$t("error.server"),
                        type: "is-danger",
                    });
                }
            },
        });
    }

    async onUpdateNotification(id, notification) {
        try {
            await this.userService.setNotificationRepository(id, notification);
        } catch {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
        }
    }

    render() {
        return (
            <PaginatedTable
                ref="table"
                pageSize={5}
                fetchData={this.getRepositories}
                defaultSort="name_asc"
            >
                <b-table-column
                    label={this.$t("Repositories.repository")}
                    field="name"
                    sortable
                    scopedSlots={{
                        default: ({ row: r }) => (
                            <span>
                                {r.name} [{r.host}]
                            </span>
                        ),
                    }}
                />

                <b-table-column
                    label={this.$t("Repositories.status")}
                    field="credential"
                    sortable
                    scopedSlots={{
                        default: ({ row: r }) => (
                            <span class={r.credential ? "tag is-dark" : "tag is-dark"}>
                                {r.credential
                                    ? this.$t("Repositories.status-connected")
                                    : this.$t("Repositories.status-notconnected")}
                            </span>
                        ),
                    }}
                />

                <b-table-column
                    label={this.$t("Repositories.activities")}
                    field="notification"
                    centered
                    sortable
                    scopedSlots={{
                        default: ({ row: r }) => (
                            <b-switch
                                value={r.notification}
                                disabled={!r.credential}
                                oninput={(value) => this.onUpdateNotification(r.id, value)}
                            />
                        ),
                    }}
                />

                <b-table-column
                    label={this.$t("Repositories.action")}
                    centered
                    scopedSlots={{
                        default: ({ row: r }) => (
                            <span>
                                <b-button
                                    class={`${r.credential ? "" : " is-hidden"}`}
                                    type="is-danger is-small"
                                    icon-right="trash"
                                    onclick={() => this.removeRepo(r)}
                                />
                                <b-button
                                    class={`${r.credential ? "is-hidden" : ""}`}
                                    type="is-primary is-small"
                                    icon-right="link"
                                    onclick={() => this.connectRepo(r)}
                                />
                            </span>
                        ),
                    }}
                />
            </PaginatedTable>
        );
    }
}
</script>
