import { Service } from "../base/Service";
import { inject } from "../base/Injection";

import { getDateWithLeft, addDateWidth, subDateWidth, getProgressInDay } from "../base/Utils";
import { startOfDay, isSameDay, endOfDay, set } from "date-fns";
import { Fetcher } from "../base/Fetcher";
import { UserService, UserStatusEvents } from "./UserService";
import { ProjectService } from "./ProjectService";

export const MIN_TIMELINE_SCALE_BOUND = 1;
export const MAX_TIMELINE_SCALE_BOUND = 60;

export const BASE_ZOOM = 1.25;

const BASE_TASK_MAGNETISM_DURATION = 10 * 60 * 1000; // 10 minutes when zoom = 1

const TIMELINE_REFRESH_MS = 15 * 60 * 1000;

export const MoveTypes = {
    MAGNETISED_BLOCK: "magnetisedBlock",
    LEFT_MAGNETISED_BLOCK: "leftMagnetisedBlock",
    RIGHT_MAGNETISED_BLOCK: "rightMagnetisedBlock",
    FREE_BLOCK: "freeBlock",
};

export class TimelineService extends Service {
    @inject(Fetcher) fetcher;
    @inject(UserService) userService;
    @inject(ProjectService) projectService;

    tasks = null;

    userPreferences = {};
    defaultColor = "1";

    constructor(context) {
        super(context);

        this.userService.on(UserStatusEvents.LOGGED_OUT, () => this.cleanCache());
        this.userService.on("change", () => this.cleanCache());
        this.userService.on("update", () => this.cleanCache());
        this.projectService.on("change", () => this.cleanCache());
    }

    cleanCache() {
        this.getTasksPromise = null;
        this.tasks = null;

        this.getActivitiesPromise = null;
        this.getEventsPromise = null;
    }

    get currentDate() {
        return new Date(+this.getUserPreferences().date || Date.now());
    }

    async setDefaultColor() {
        this.defaultColor = `${await this.userService.getDefaultColor("1")}`;
    }

    async convertPeriodToTasks(...periods) {
        await this.setDefaultColor();

        return periods.map((p) => this._convertPeriodToTask(p));
    }

    _convertPeriodToTask(period) {
        return {
            ...period.task,
            color: period.projectColor || this.defaultColor,
            project: period.projectName,
            repoId: period.projectRepoId,
            repoCategory: period.projectRepoCategory,
            repoProjectId: period.projectRepoProjectId,
            startDate: new Date(period.startDate),
            endDate: period.running ? new Date() : new Date(period.endDate),
            running: period.running,
        };
    }

    async getTasks() {
        if (this.getTasksPromise) {
            return this.getTasksPromise;
        }

        this.getTasksPromise = this._getTasks();
        return this.getTasksPromise;
    }

    async getHistory(selector) {
        const history = await this.fetcher.get("/periods/history", selector);
        if (!history.items) {
            history.items = [];
        }

        history.items = history.items.map((h) => ({
            ...h,
            project: h.projectName,
            color: h.projectColor || this.defaultColor,
        }));
        return history;
    }

    async _getTasks() {
        var offset = 0; //this.currentDate.getTimezoneOffset() * 60; // user's offset time

        const startDate = startOfDay(this.currentDate).getTime() / 1000 + offset;
        const endDate = endOfDay(this.currentDate).getTime() / 1000 + offset;

        try {
            const periods = await this.fetcher.get(`/periods/${~~startDate}/${~~endDate}`);
            if (!periods) {
                this.tasks = [];
                return [];
            }

            this.tasks = await this.convertPeriodToTasks(...periods);
        } catch (e) {
            this.tasks = [];
            throw e;
        }

        return this.tasks;
    }

    async getTask(id, forceServer = false) {
        if (!id) {
            return null;
        }

        if (forceServer) {
            const task = await this.fetcher.get(`/period/${id}`);
            const [converted] = await this.convertPeriodToTasks(task);
            return converted;
        } else {
            const tasks = await this.getTasks();
            return tasks.find((task) => task.id === id);
        }
    }

    async createTask(data) {
        const now = new Date();
        const defaultDate = set(this.currentDate, {
            hours: now.getHours(),
            minutes: now.getMinutes(),
            seconds: now.getSeconds(),
            milliseconds: now.getMilliseconds(),
        });

        data = {
            startDate: defaultDate,
            endDate: defaultDate,
            ...data,
        };

        const period = await this._saveTask(data);
        const [converted] = await this.convertPeriodToTasks(period);
        this._addTask(converted);
        return period.task.id;
    }

    async deleteTask(id) {
        this._removeTask({ id });
        await this.fetcher.delete(`/period/${id}`);
        this.fireChange();
    }

    _normalizeDates(
        taskToResize,
        { newStartDate, newEndDate },
        { canStickOnStart = true, canStickOnEnd = true } = {},
    ) {
        const duration = taskToResize.endDate.getTime() - taskToResize.startDate.getTime();
        const findCollision = (date1, date2) =>
            Math.abs(date1 - date2) < BASE_TASK_MAGNETISM_DURATION / this.userPreferences.zoom;

        let normalizedStartDate;
        let normalizedEndDate;

        // We first try to find if the edges of the task we're currently modifying are close to the edges of another task in the timeline.
        // If this is the case, we make them "stick" to create this magnetism effect.
        for (const task of this.tasks) {
            // Filter the current task from the list (we don't want to find a collision with the task itself)
            if (task.id === taskToResize.id) {
                continue;
            }

            if (canStickOnStart && newStartDate != null) {
                if (findCollision(newStartDate, task.startDate)) {
                    normalizedStartDate = task.startDate;
                } else if (findCollision(newStartDate, task.endDate)) {
                    normalizedStartDate = task.endDate;
                }

                if (normalizedStartDate != null && newEndDate != null) {
                    normalizedEndDate = new Date(normalizedStartDate.getTime() + duration);
                }
            }

            if (canStickOnEnd && newEndDate != null) {
                if (findCollision(newEndDate, task.startDate)) {
                    normalizedEndDate = task.startDate;
                } else if (findCollision(newEndDate, task.endDate)) {
                    normalizedEndDate = task.endDate;
                }

                if (normalizedEndDate != null && newStartDate != null) {
                    normalizedStartDate = new Date(normalizedEndDate.getTime() - duration);
                }
            }

            if (normalizedStartDate || normalizedEndDate) {
                break;
            }
        }

        normalizedStartDate = normalizedStartDate || newStartDate || taskToResize.startDate;
        normalizedEndDate = normalizedEndDate || newEndDate || taskToResize.endDate;

        return {
            startDate: normalizedStartDate,
            endDate: normalizedEndDate,
        };
    }

    normalizeFromTasks(date) {
        const findCollision = (date1, date2) =>
            Math.abs(date1 - date2) < BASE_TASK_MAGNETISM_DURATION / this.userPreferences.zoom;

        let normalizedStartDate;
        let normalizedEndDate;

        // We first try to find if the edges of the task we're currently modifying are close to the edges of another task in the timeline.
        // If this is the case, we make them "stick" to create this magnetism effect.
        for (const task of this.tasks) {
            if (findCollision(date, task.startDate)) {
                normalizedStartDate = task.startDate;
            } else if (findCollision(date, task.endDate)) {
                normalizedStartDate = task.endDate;
            }

            if (findCollision(date, task.startDate)) {
                normalizedEndDate = task.startDate;
            } else if (findCollision(date, task.endDate)) {
                normalizedEndDate = task.endDate;
            }

            if (normalizedStartDate || normalizedEndDate) {
                break;
            }
        }

        return normalizedStartDate || normalizedEndDate || date;
    }

    moveTasks(tasks, distance, mt = MoveTypes.MAGNETISED_BLOCK) {
        const first = tasks.reduce((t1, t2) => (t2.startDate < t1.startDate ? t2 : t1), tasks[0]);
        const last = tasks.reduce((t1, t2) => (t2.endDate > t1.endDate ? t2 : t1), tasks[0]);

        const normalizedDatesByTask = new Map();

        const addDistance = (date, distance) =>
            getDateWithLeft(date, getProgressInDay(date) + distance);

        let adjustedDistance;
        tasks.forEach((t) => {
            if (adjustedDistance != null) {
                // If a specificly adjusted distance has been found, it means that the normalization process has applied
                // for some reason (magnetic effect, timeline edge reached...).
                // In this case, as we're moving all tasks as a single block, we want to apply this adjusted distance to everybody
                return;
            }

            const newStartDate = addDistance(t.startDate, distance);
            const newEndDate = addDistance(t.endDate, distance);
            const newInterval = { newStartDate, newEndDate };

            // When moving a group of task alltogether, we can apply a "magnetic" effect on the edges of the block (ie, the group of tasks).
            // Depending on the move type (MEGNETIC, LEFT_MEGNETIC,...) :
            // - the task that starts first may have a left magnet
            // - the task that ends last may have a right magnet
            const options = {
                canStickOnStart:
                    (mt === MoveTypes.MAGNETISED_BLOCK || mt === MoveTypes.LEFT_MAGNETISED_BLOCK) &&
                    t === first,
                canStickOnEnd:
                    (mt === MoveTypes.MAGNETISED_BLOCK ||
                        mt === MoveTypes.RIGHT_MAGNETISED_BLOCK) &&
                    t === last,
            };

            const normalizedDates = this._normalizeDates(t, newInterval, options);
            normalizedDatesByTask.set(t.id, normalizedDates);

            // When moving all tasks as a single block, there can be only one distance applying for everybody.
            // Let's figure out what this distance is. To do so, we need to compare the requested dates with the normalized one.
            // If the magnetic effect applied for one of the task, it might have moved more than the expected distance. Or, on the contrary,
            // it might not have moved at all.
            const { startDate, endDate } = normalizedDates;
            if (startDate !== newStartDate) {
                adjustedDistance =
                    getProgressInDay(startDate, this.currentDate) -
                    getProgressInDay(t.startDate, this.currentDate);
            } else if (endDate !== newEndDate) {
                adjustedDistance =
                    getProgressInDay(endDate, this.currentDate) -
                    getProgressInDay(t.endDate, this.currentDate);
            }
        });

        const results = new Map();
        tasks.forEach((t) => {
            let toReplace;

            const isAdjusted = adjustedDistance != null;
            if (isAdjusted) {
                toReplace = {
                    id: t.id,
                    startDate: addDistance(t.startDate, adjustedDistance),
                    endDate: addDistance(t.endDate, adjustedDistance),
                };
            } else {
                toReplace = {
                    id: t.id,
                    ...normalizedDatesByTask.get(t.id),
                };
            }

            this._replaceTask(toReplace);
            results.set(t.id, toReplace);
        });

        results.move = adjustedDistance || distance;
        return results;
    }

    setTaskStartDate(data, position, mt = MoveTypes.MAGNETISED_BLOCK) {
        const duration = data.endDate.getTime() - data.startDate.getTime();
        const newStartDate = getDateWithLeft(data.startDate, position);
        const newEndDate = new Date(newStartDate.getTime() + duration);

        const { startDate, endDate } = this._normalizeDates(
            data,
            { newStartDate, newEndDate },
            {
                canStickOnEnd:
                    mt === MoveTypes.MAGNETISED_BLOCK || mt === MoveTypes.LEFT_MAGNETISED_BLOCK,
                canStickOnStart:
                    mt === MoveTypes.MAGNETISED_BLOCK || mt === MoveTypes.RIGHT_MAGNETISED_BLOCK,
            },
        );
        data.startDate = startDate;
        data.endDate = endDate;

        this._replaceTask(data);
    }

    setTaskEndDateDuration(data, width, mt = MoveTypes.MAGNETISED_BLOCK) {
        const newEndDate = addDateWidth(data.startDate, width);

        const { startDate, endDate } = this._normalizeDates(
            data,
            { newEndDate },
            {
                canStickOnEnd:
                    mt === MoveTypes.MAGNETISED_BLOCK || mt === MoveTypes.LEFT_MAGNETISED_BLOCK,
                canStickOnStart:
                    mt === MoveTypes.MAGNETISED_BLOCK || mt === MoveTypes.RIGHT_MAGNETISED_BLOCK,
            },
        );
        data.startDate = startDate;
        data.endDate = endDate;

        this._replaceTask(data);
    }

    setTaskStartDateDuration(data, width, mt = MoveTypes.MAGNETISED_BLOCK) {
        const newStartDate = subDateWidth(data.endDate, width);

        const { startDate, endDate } = this._normalizeDates(
            data,
            { newStartDate },
            {
                canStickOnEnd:
                    mt === MoveTypes.MAGNETISED_BLOCK || mt === MoveTypes.LEFT_MAGNETISED_BLOCK,
                canStickOnStart:
                    mt === MoveTypes.MAGNETISED_BLOCK || mt === MoveTypes.RIGHT_MAGNETISED_BLOCK,
            },
        );
        data.startDate = startDate;
        data.endDate = endDate;

        this._replaceTask(data);
    }

    async updateTask(data) {
        this._replaceTask(data);
        const period = await this._saveTask(data);
        return period;
    }

    async _saveTask(data) {
        const period = await this.fetcher.post("/period", {
            task: {
                id: data.id,
                title: data.title,
                tags: data.tags,
                projectId: data.projectId,
            },
            startDate: data.startDate,
            endDate: data.endDate,
            running: Boolean(data.running),
        });
        return period;
    }

    _replaceTask(data) {
        if (this.tasks) {
            const index = this.tasks.findIndex((task) => task.id === data.id);
            if (index !== -1) {
                this.tasks[index] = { ...this.tasks[index], ...data };
                this.tasks = [...this.tasks];

                this.getTasksPromise = Promise.resolve(this.tasks);
                this.fireChange();

                return this.tasks[index];
            }
        }
    }

    _addTask(data) {
        if (this.tasks) {
            if (!isSameDay(this.userPreferences.date, data.startDate)) {
                return;
            }

            this.tasks = [...this.tasks, data];

            this.getTasksPromise = Promise.resolve(this.tasks);
            this.fireChange();

            return data;
        }
    }

    _removeTask(data) {
        if (this.tasks) {
            const index = this.tasks.findIndex((task) => task.id === data.id);
            if (index !== -1) {
                this.tasks.splice(index, 1);
                this.tasks = [...this.tasks];

                this.getTasksPromise = Promise.resolve(this.tasks);
                this.fireChange();
            }
        }
    }

    setUserPreferences(preferences) {
        if (preferences.date != null && !isSameDay(this.userPreferences.date, preferences.date)) {
            this.getTasksPromise = null;
            this.getActivitiesPromise = null;
            this.getEventsPromise = null;
        }

        this.userPreferences = {
            ...this.userPreferences,
            ...preferences,
        };
    }

    getUserPreferences() {
        return this.userPreferences;
    }

    async getActivities() {
        /*
        return [
                {
                    "category": "gitlab",
                    "type": "COMMIT",
                    "title": "Merge branch 'master' into events",
                    "date": "2022-01-05T16:53:37.555Z",
                    "tags": null,
                    "projectId": "00000000-0000-0000-0000-000000000000",
                    "projectColor": "",
                    "projectName": "wid-cbd"
                }
            ];
*/
        if (this.getActivitiesPromise) {
            return this.getActivitiesPromise;
        }

        this.getActivitiesPromise = this._getActivities();
        this.getActivitiesPromise.then(() => this._refreshActivities());

        return this.getActivitiesPromise;
    }

    async _getActivities() {
        clearTimeout(this.activitiesTimer);
        await this.setDefaultColor();
        const { startDate, endDate } = this._getDayWindow();
        return this.fetcher.get(`/repos/activities?startDate=${~~startDate}&endDate=${~~endDate}`);
    }

    _refreshActivities() {
        this.activitiesTimer = setTimeout(async () => {
            this.getActivitiesPromise = null;
            await this.getActivities();
            this.fireChange();
        }, TIMELINE_REFRESH_MS);
    }

    async getEvents() {
        if (this.getEventsPromise) {
            return this.getEventsPromise;
        }

        this.getEventsPromise = this._getEvents();
        this.getEventsPromise.then(() => this._refreshEvents());

        return this.getEventsPromise;
    }

    async _getEvents() {
        /*
        return [
            {
                id: 'xxx',
                startDate: '2022-01-05T09:53:37.555Z',
                endDate: '2022-01-05T10:53:37.555Z',
                eventType: 'note',
                source: 'mysource',
                eventData: {}
            },
            {
                id: 'xxx2',
                startDate: '2022-01-05T09:30:37.555Z',
                endDate: '2022-01-05T10:30:37.555Z',
                eventType: 'heartbeat',
                source: 'mysource',
                eventData: {}
            }
        ];
*/
        clearTimeout(this.eventsTimer);
        await this.setDefaultColor();
        const { startDate, endDate } = this._getDayWindow();
        return this.fetcher.get(`/events?startDate=${~~startDate}&endDate=${~~endDate}`);
    }

    _refreshEvents() {
        this.eventsTimer = setTimeout(async () => {
            this.getEventsPromise = null;
            await this.getEvents();
            this.fireChange();
        }, TIMELINE_REFRESH_MS);
    }

    fireChange() {
        this.emit("change");
    }

    _getDayWindow() {
        var offset = 0; //this.currentDate.getTimezoneOffset() * 60; // user's offset time
        const startDate = startOfDay(this.currentDate).getTime() / 1000 + offset;
        const endDate = endOfDay(this.currentDate).getTime() / 1000 + offset;
        return { startDate, endDate };
    }
}
