<script>
import Component from "vue-class-component";
import Vue from "vue";

@Component
export default class LoginPage extends Vue {
    render() {
        return (
            <div class="page">
                <div class="fixed-top header">
                    <router-link to="/">
                        <img class="logo" src="./img/logo.svg" />
                    </router-link>
                </div>
                <div class="page-container">
                    <h1 class="title">Wid</h1>
                    <h2 class="subtitle is-5">{this.$t("LoginPage.description")}</h2>
                    <router-view></router-view>
                </div>

                <div class="page-footer">
                    Wid 2021 -<a href="/legals.html">{this.$t("LoginPage.legals")}</a>-
                    <a href="/cgu.html">{this.$t("LoginPage.terms")}</a>
                </div>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.page {
    justify-content: center;
    display: flex;
}

.header {
    display: flex;
    height: 40px;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f0f0f0;
}

.logo {
    width: 24px;
    height: 24px;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.page-container {
    margin-top: 40px;
    padding: 85px 15px;
    max-width: 960px;
}
</style>
