<script>
import Component from "vue-class-component";
import Vue from "vue";
import Color from "./Color";
import { UserService } from "../../services/UserService";
import { inject } from "../../base/Injection";

const Props = Vue.extend({
    props: {
        value: String,
        disabled: Boolean,
    },
});

@Component
export default class ColorsPicker extends Props {
    @inject(UserService) userService;

    selectedColor = "";

    async created() {
        this.selectedColor = this.value || `${await this.userService.getDefaultColor("1")}`;
        this.$watch("value", () => {
            this.selectedColor = this.value;
        });

        this.exit_bound = (e) => this.exit(e);
    }

    onSelectColor(color) {
        this.selectedColor = color;
        this.$emit("change", this.selectedColor);
        this.hide();
    }

    getValue() {
        return this.selectedColor ? this.selectedColor : null;
    }

    toggle() {
        if (this.disabled) {
            return;
        }

        if (this.$refs.panel.classList.contains("is-hidden")) {
            this.$refs.panel.classList.remove("is-hidden");
            document.addEventListener("mousedown", this.exit_bound, true);
        } else {
            this.hide();
        }
    }

    exit(e) {
        if (this.$el === e.target || this.$el.contains(e.target)) {
            return;
        }

        this.hide();
    }

    hide() {
        this.$refs.panel.classList.add("is-hidden");
        document.removeEventListener("mousedown", this.exit_bound, true);
    }

    render() {
        const colors = [];
        for (let index = 0; index < 15; index++) {
            colors.push(
                <Color index={`${index}`} onclick={() => this.onSelectColor(`${index}`)} />
            );
        }

        return (
            <div class={{ picker: true, disabled: this.disabled }}>
                <Color index={this.selectedColor} onclick={this.toggle} />
                <div ref="panel" class="control colors is-hidden">
                    {colors}
                </div>
            </div>
        );
    }
}
</script>

<style lang="scss" scoped>
.picker {
    position: relative;
}

.colors {
    display: flex;
    flex-wrap: wrap;
    border-radius: 3px;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    z-index: 20;

    background-color: white;
    width: 190px;
}

.picker:not(.disabled) {
    .color {
        &:hover {
            cursor: pointer;
            border: 4px solid rgba(0, 0, 0, 0.25);
        }
    }
}
</style>
