<script>
import Component from "vue-class-component";
import Vue from "vue";
import { inject } from "../../base/Injection";
import { ProjectService } from "../../services/ProjectService";
import EditableInput from "../common/EditableInput.vue";
import { TimelineService } from "../../services/TimelineService";
import PaginatedTable from "../common/PaginatedTable.vue";

const Props = Vue.extend({
    props: {
        project: Object,
    },
});

@Component
export default class TagsProject extends Props {
    @inject(ProjectService) projectService;
    @inject(TimelineService) timelineService;

    mounted() {
        this.projectService.on("change", this.$refs.table.loadData);
    }

    beforeDestroy() {
        this.projectService.off("change", this.$refs.table.loadData);
    }

    async getTags(selector) {
        const id = this.$route.params.id;
        const tags = await this.projectService.getTags(id, selector);
        return tags;
    }

    async addTag() {
        if (this.$refs.tag.newValue) {
            try {
                await this.projectService.saveTag(this.project.id, {
                    name: this.$refs.tag.newValue,
                    isDefault: this.$refs.default.newValue,
                });
                this.$refs.tag.newValue = "";
            } catch {
                this.$buefy.toast.open({
                    message: this.$t("error.server"),
                    type: "is-danger",
                });
            }
        }
    }

    async setDefault(name, isDefault) {
        try {
            await this.projectService.setDefaultTag(this.project.id, { name, isDefault });
        } catch {
            this.$buefy.toast.open({
                message: this.$t("error.server"),
                type: "is-danger",
            });
        }
    }

    async save(oldValue, newValue, target) {
        const isOverwrite = this.$refs.table.getData().some((t) => t.name === newValue);

        const callback = async () => {
            try {
                await this.projectService.renameTag(this.project.id, oldValue, newValue);
                await this.timelineService.cleanCache();
            } catch {
                this.$buefy.toast.open({
                    message: this.$t("error.server"),
                    type: "is-danger",
                });
                target.cancel();
            }
        };

        if (isOverwrite) {
            this.$buefy.dialog.confirm({
                title: this.$t("TagsProject.overwrite.title"),
                message: this.$t("TagsProject.overwrite.message"),
                confirmText: this.$t("TagsProject.overwrite.confirmText"),
                type: "is-danger",
                hasIcon: true,
                onConfirm: callback,
                onCancel: () => {
                    target.cancel();
                },
            });
        } else {
            callback();
        }
    }

    async remove(tag) {
        this.$buefy.dialog.confirm({
            title: this.$t("TagsProject.remove.title"),
            message: this.$t("TagsProject.remove.message"),
            confirmText: this.$t("TagsProject.remove.confirmText"),
            type: "is-danger",
            hasIcon: true,
            onConfirm: async () => {
                try {
                    await this.projectService.removeTag(this.project.id, tag);
                    await this.timelineService.cleanCache();
                    this.$buefy.toast.open({
                        message: this.$t("TagsProject.remove.success"),
                        type: "is-success",
                    });
                } catch {
                    this.$buefy.toast.open({
                        message: this.$t("error.server"),
                        type: "is-danger",
                    });
                }
            },
        });
    }

    render() {
        if (!this.project) {
            return;
        }

        const isMaintainer = this.project.permission === "maintainer";

        return (
            <div class="page-table">
                <div class={`field-inline${isMaintainer ? "" : " is-hidden"}`}>
                    <div class="fixed">
                        <b-input
                            ref="tag"
                            ellipsis
                            icon="tags"
                            placeholder={this.$t("TagsProject.input-tag")}
                            required
                        ></b-input>
                    </div>
                    <div>
                        <b-switch ref="default" value={false}>
                            {this.$t("TagsProject.input-default")}
                        </b-switch>
                    </div>
                    <button class="button is-primary is-rounded is-outlined" onclick={this.addTag}>
                        {this.$t("TagsProject.submit")}
                    </button>
                </div>
                <PaginatedTable ref="table" fetchData={this.getTags} defaultSort="tag_asc">
                    <b-table-column
                        label={this.$t("TagsProject.tags")}
                        cellClass="fix-half"
                        field="tag"
                        sortable
                        scopedSlots={{
                            default: ({ row: tag }) => {
                                return (
                                    <EditableInput
                                        type="row"
                                        disabled={!isMaintainer}
                                        value={tag.name}
                                        onChange={(newValue, target) =>
                                            this.save(tag.name, newValue, target)
                                        }
                                    />
                                );
                            },
                        }}
                    />
                    <b-table-column
                        label={this.$t("TagsProject.default")}
                        field="isDefault"
                        sortable
                        centered
                        scopedSlots={{
                            default: ({ row: tag }) => {
                                return (
                                    <b-switch
                                        value={tag.isDefault}
                                        oninput={(value) => this.setDefault(tag.name, value)}
                                        disabled={!isMaintainer}
                                    />
                                );
                            },
                        }}
                    />

                    <b-table-column
                        label={this.$t("TagsProject.action")}
                        centered
                        visible={isMaintainer}
                        scopedSlots={{
                            default: ({ row: tag }) => (
                                <b-button
                                    type="is-danger is-small"
                                    icon-right="trash"
                                    onclick={() => this.remove(tag.name)}
                                />
                            ),
                        }}
                    />
                </PaginatedTable>
            </div>
        );
    }
}
</script>
